/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const appInstance = createApp(App);
appInstance.use(store);

function removeExpiredToken() {
  const token = localStorage.getItem("token");
  if (!token) return;

  const jwtPayload = JSON.parse(atob(token.split(".")[1]));

  const expirationTime = jwtPayload.exp * 1000; // convert to milliseconds

  if (Date.now() > expirationTime) {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
  }
}

// Call this function when your application loads or before making API calls
removeExpiredToken();

appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.use(VueSweetalert2);

appInstance.mount("#app");
