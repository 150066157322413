import { createStore } from "vuex";
// import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import common from "./modules/common";
import modelsCategory from "./modules/modelsCategory";
import models from "./modules/models";
import newReleases from "./modules/newReleases";
import history from "./modules/history";
import modelColors from "./modules/modelColors";
import vision from "./modules/vision";
import multimedia from "./modules/multimedia";
import afterSalesService from "./modules/afterSalesService";
import contactus from "./modules/contactus";
import mediacontact from "./modules/mediacontact";
import subscribe from "./modules/subscribe";
import featureServices from "./modules/featureServices";
import offRoad from "./modules/offRoad";
import capitalBeauty from "./modules/capitalBeauty";
import researchDevelopment from "./modules/researchDevelopment";
import auth from "./modules/auth";
import termsConditions from "./modules/termsConditions";
import conceptCar from "./modules/conceptCar";
import cookiePolicy from "./modules/cookiePolicy";
import dataProtection from "./modules/dataProtection";
import overView from "./modules/overview";
import home from "./modules/home";
import testDrive from "./modules/testDrive";
import joinUs from "./modules/joinUs";
export default createStore({
  modules: {
    common,
    auth,
    home,
    modelsCategory,
    models,
    modelColors,
    newReleases,
    history,
    vision,
    multimedia,
    afterSalesService,
    contactus,
    mediacontact,
    subscribe,
    featureServices,
    offRoad,
    capitalBeauty,
    researchDevelopment,
    conceptCar,
    termsConditions,
    cookiePolicy,
    dataProtection,
    overView,
    testDrive,
    joinUs,
  },

  // state: {
  //   hideConfigButton: false,
  //   isPinned: true,
  //   showConfig: false,
  //   isTransparent: "",
  //   isRTL: false,
  //   color: "",
  //   isNavFixed: false,
  //   isAbsolute: false,
  //   showNavs: true,
  //   showSidenav: true,
  //   showNavbar: true,
  //   showFooter: true,
  //   showMain: true,
  //   navbarFixed:
  //     "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
  //   absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
  //   bootstrap,
  // },
  // mutations: {
  //   toggleConfigurator(state) {
  //     state.showConfig = !state.showConfig;
  //   },
  //   navbarMinimize(state) {
  //     const sidenav_show = document.querySelector(".g-sidenav-show");
  //     if (sidenav_show.classList.contains("g-sidenav-hidden")) {
  //       sidenav_show.classList.remove("g-sidenav-hidden");
  //       sidenav_show.classList.add("g-sidenav-pinned");
  //       state.isPinned = true;
  //     } else {
  //       sidenav_show.classList.add("g-sidenav-hidden");
  //       sidenav_show.classList.remove("g-sidenav-pinned");
  //       state.isPinned = false;
  //     }
  //   },
  //   sidebarType(state, payload) {
  //     state.isTransparent = payload;
  //   },
  //   cardBackground(state, payload) {
  //     state.color = payload;
  //   },
  //   navbarFixed(state) {
  //     if (state.isNavFixed === false) {
  //       state.isNavFixed = true;
  //     } else {
  //       state.isNavFixed = false;
  //     }
  //   },
  //   toggleEveryDisplay(state) {
  //     state.showNavbar = !state.showNavbar;
  //     state.showSidenav = !state.showSidenav;
  //     state.showFooter = !state.showFooter;
  //   },
  //   toggleHideConfig(state) {
  //     state.hideConfigButton = !state.hideConfigButton;
  //   },
  // },
  // actions: {
  //   toggleSidebarColor({ commit }, payload) {
  //     commit("sidebarType", payload);
  //   },
  //   setCardBackground({ commit }, payload) {
  //     commit("cardBackground", payload);
  //   },
  // },
  // getters: {},
});
