import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Tables from "@/views/Tables.vue";
import Billing from "@/views/Billing.vue";
import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import HomePage from "../views/HomePageCms.vue";
import OverviewPage from "../views/Overview/Overview.vue";
import History from "../views/History/History.vue";
import HistoryAdd from "../views/History/HistoryAdd.vue";
import HistoryEdit from "../views/History/HistoryEdit.vue";
import NewsRelease from "../views/NewRelease/NewsReleases.vue";
import NewsReleaseAdd from "../views/NewRelease/NewsReleaseAdd.vue";
import NewsReleaseEdit from "../views/NewRelease/NewsReleaseEdit.vue";
import Models from "../views/Models/Model.vue";
import ModelAdd from "../views/Models/ModelAdd.vue";
import ModelEdit from "../views/Models/ModelEdit.vue";
import ModelColors from "../views/ModelColors/ModelColors.vue";
import ModelColorAdd from "../views/ModelColors/ModelColorAdd.vue";
import ModelColorEdit from "../views/ModelColors/ModelColorEdit.vue";
import ModelsCategory from "../views/ModelsCategory/ModelCategory.vue";
import ModelsCategoryAdd from "../views/ModelsCategory/ModelCategoryAdd.vue";
import ModelsCategoryEdit from "../views/ModelsCategory/ModelCategoryEdit.vue";
import Vision from "../views/Vision/Vision.vue";
import VisionAdd from "../views/Vision/VisionAdd.vue";
import VisionEdit from "../views/Vision/VisionEdit.vue";
import Cms from "../views/Cms.vue";
import AfterSalesService from "../views/AfterSalesService/AfterSalesService.vue";
import Multimedia from "../views/Multimedia/Multimedia.vue";
import MultimediaAdd from "../views/Multimedia/MultimediaAdd.vue";
import MultimediaEdit from "../views/Multimedia/MultimediaEdit.vue";
import FeatureServices from "../views/FeatureServices/FeatureServices.vue";
import AddFeatureServices from "../views/FeatureServices/AddFeatureServices.vue";
import EditFeatureServices from "../views/FeatureServices/EditFeatureServices.vue";
import ContactUs from "../views/ContactUs/Contactus.vue";
import MediaContact from "../views/MediaContact/MediaContact.vue";
import SubscribeUsers from "../views/SubscribeUsers/SubscriveUsers.vue";
import OffRoad from "../views/OffRoad/OffRoad.vue";
import CapitalBeauty from "../views/CapitalBeauty/CapitalBeauty.vue";
import ResearchDevelopment from "../views/ResearchDevelopment/ResearchDevelopment.vue";
import TermsConditions from "../views/TermsConditions/TermsConditions.vue";
import CookiePolicy from "../views/CookiePolicy/CookiePolicy.vue";
import DataProtection from "../views/DataProtection/DataProtection.vue";
import ConceptCar from "../views/ConceptCar/ConceptCar.vue";
import Home from "../views/Home/Home.vue";
import TestDrive from "../views/TestDrive/TestDrive.vue";
import PageNotFound from "../views/PageNotFound.vue";
import JoinUs from "../views/JoinUs/JoinUs.vue";
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: "Dashboard", requiresAuth: true },
  },
  {
    path: "/cms",
    name: "CMS Pages",
    component: Cms,
    meta: { title: "CMS Pages", requiresAuth: true },
  },
  {
    path: "/home",
    name: "Home CMS",
    component: HomePage,
    meta: { title: "Home", requiresAuth: true },
  },
  {
    path: "/overview",
    name: "Overview CMS",
    component: OverviewPage,
    meta: { title: "Overview", requiresAuth: true },
  },
  {
    path: "/history",
    name: "History",
    component: History,
    meta: { title: "History", requiresAuth: true },
  },
  {
    path: "/join-us",
    name: "Join Us",
    component: JoinUs,
    meta: { title: "Join Us", requiresAuth: true },
  },
  {
    path: "/history/add",
    name: "History Add",
    component: HistoryAdd,
    meta: { title: "History Add", requiresAuth: true },
  },
  {
    path: "/history/edit/:id",
    name: "History Edit",
    component: HistoryEdit,
    meta: { title: "History Edit", requiresAuth: true },
  },
  {
    path: "/models",
    name: "Models",
    component: Models,
    meta: { title: "Models", requiresAuth: true },
  },
  {
    path: "/models/add",
    name: "Model Add",
    component: ModelAdd,
    meta: { title: "Model Add", requiresAuth: true },
  },
  {
    path: "/models/edit/:id",
    name: "Model Edit",
    component: ModelEdit,
    meta: { title: "Model Edit", requiresAuth: true },
  },
  {
    path: "/model-colors",
    name: "Model Colors",
    component: ModelColors,
    meta: { title: "Model Colors", requiresAuth: true },
  },
  {
    path: "/model-colors/add",
    name: "Model Colors Add",
    component: ModelColorAdd,
    meta: { title: "Model Colors Add", requiresAuth: true },
  },
  {
    path: "/model-colors/edit/:id",
    name: "Model Colors Edit",
    component: ModelColorEdit,
    meta: { title: "Model Colors Edit", requiresAuth: true },
  },
  {
    path: "/models-category",
    name: "Models Category",
    component: ModelsCategory,
    meta: { title: "Model Colors Category", requiresAuth: true },
  },
  {
    path: "/models-category/add",
    name: "Models Category Add",
    component: ModelsCategoryAdd,
    meta: { title: "Model Category Add", requiresAuth: true },
  },
  {
    path: "/models-category/edit/:id",
    name: "Models Category Edit",
    component: ModelsCategoryEdit,
    meta: { title: "Model Category Edit", requiresAuth: true },
  },
  {
    path: "/news-releases",
    name: "News Releases",
    component: NewsRelease,
    meta: { title: "News Releases", requiresAuth: true },
  },
  {
    path: "/news-releases/add",
    name: "News Releases Add",
    component: NewsReleaseAdd,
    meta: { title: "News Releases Add", requiresAuth: true },
  },
  {
    path: "/news-releases/edit/:id",
    name: "News Releases Edit",
    component: NewsReleaseEdit,
    meta: { title: "News Releases Edit", requiresAuth: true },
  },
  {
    path: "/vision",
    name: "Vision",
    component: Vision,
    meta: { title: "Vision", requiresAuth: true },
  },
  {
    path: "/vision/add",
    name: "Vision Add",
    component: VisionAdd,
    meta: { title: "Vision Add", requiresAuth: true },
  },
  {
    path: "/vision/edit/:id",
    name: "Vision Edit",
    component: VisionEdit,
    meta: { title: "Vision Edit", requiresAuth: true },
  },
  {
    path: "/multimedia",
    name: "Multimedia",
    component: Multimedia,
    meta: { title: "Multimedia", requiresAuth: true },
  },
  {
    path: "/multimedia/add",
    name: "Multimedia Add",
    component: MultimediaAdd,
    meta: { title: "Multimedia Add", requiresAuth: true },
  },
  {
    path: "/multimedia/edit/:id",
    name: "Multimedia Edit",
    component: MultimediaEdit,
    meta: { title: "Multimedia Edit", requiresAuth: true },
  },
  {
    path: "/feature-services",
    name: "Feature Services",
    component: FeatureServices,
    meta: { title: "Feature Services", requiresAuth: true },
  },
  {
    path: "/feature-services/add",
    name: "Feature Service Add",
    component: AddFeatureServices,
    meta: { title: "Feature Services Add", requiresAuth: true },
  },
  {
    path: "/feature-services/edit/:id",
    name: "Feature Services Edit",
    component: EditFeatureServices,
    meta: { title: "Feature Services Edit", requiresAuth: true },
  },
  {
    path: "/after-sales-service",
    name: "After Sales Service",
    component: AfterSalesService,
    meta: { title: "After Sales Service", requiresAuth: true },
  },
  {
    path: "/contact-us-inquiries",
    name: "Contact Us",
    component: ContactUs,
    meta: { title: "Contact Us", requiresAuth: true },
  },
  {
    path: "/media-contact-inquiries",
    name: "Media Contact",
    component: MediaContact,
    meta: { title: "Media Contact", requiresAuth: true },
  },
  {
    path: "/subscribe-users",
    name: "Subscribe Users",
    component: SubscribeUsers,
    meta: { title: "Subscribe Users", requiresAuth: true },
  },
  {
    path: "/off-road",
    name: "Off Road",
    component: OffRoad,
    meta: { title: "Off Road", requiresAuth: true },
  },
  {
    path: "/capital-beauty",
    name: "Capital Beauty",
    component: CapitalBeauty,
    meta: { title: "Capital Beauty", requiresAuth: true },
  },
  {
    path: "/research-development",
    name: "Research Development",
    component: ResearchDevelopment,
    meta: { title: "Research Development", requiresAuth: true },
  },
  {
    path: "/concept-car",
    name: "Concept Car",
    component: ConceptCar,
    meta: { title: "Concept Car", requiresAuth: true },
  },
  {
    path: "/terms-and-conditions",
    name: "Terms & Conditions",
    component: TermsConditions,
    meta: { title: "Terms & Conditions", requiresAuth: true },
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: CookiePolicy,
    meta: { title: "Cookie Policy", requiresAuth: true },
  },
  {
    path: "/data-protection",
    name: "Data Protection",
    component: DataProtection,
    meta: { title: "Data Protection", requiresAuth: true },
  },
  {
    path: "/home-cms",
    name: "Home",
    component: Home,
    meta: { title: "Home", requiresAuth: true },
  },
  {
    path: "/test-drive",
    name: "Test Drive",
    component: TestDrive,
    meta: { title: "Test Drive", requiresAuth: true },
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page Not Found",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token"); // Or your authentication logic
  const { title } = to.meta;
  const defaultTitle = "BAIC";
  document.title = title || defaultTitle;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: "Sign In" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
