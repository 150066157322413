import API from "../../api/api";
import { authHeader } from "../../helpers/authHeader";
const state = () => ({
  cookiePolicyObj: null,
  loading: false,
});

const mutations = {
  getCookiePolicyDetail(state, payload) {
    state.cookiePolicyObj = payload;
  },
  addCookiePolicy(state, payload) {
    state.cookiePolicyObj = payload;
  },
  editCookiePolicy(state, payload) {
    // const index = state.models.findIndex((m) => m.id === payload.id);
    // if (index) {
    state.cookiePolicyObj = payload;
    // }
  },
  setDetailInput: (state, e) => {
    let cookiePolicyObj = state.cookiePolicyObj;
    cookiePolicyObj[e.target.name] = e.target.value;
    state.cookiePolicyObj = cookiePolicyObj;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchCookiePolicyDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`cookie-policy/${payload}`)
      .then(res => {
        commit("getCookiePolicyDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("cookie-policy", payload, {
      headers: authHeader(),
    })
      .then(res => {
        commit("addCookiePolicy", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    await API.put(`cookie-policy/1`, payload, {
      headers: authHeader(),
    })
      .then(res => {
        if (res) {
          commit("editCookiePolicy", res.data.data);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  cookiePolicyObj: state => state.cookiePolicyObj,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
