import API from "../../api/api";
import { authHeader } from "../../helpers/authHeader";
const state = () => ({
  modelsCategories: [],
  modelCategory: null,
  loading: false,
});

const mutations = {
  getModelsCategories(state, payload) {
    state.modelsCategories = payload;
  },
  getModelsCategory(state, payload) {
    state.modelCategory = payload;
  },
  addModelsCatgory(state, payload) {
    state.modelsCategories.push(payload);
  },
  editModelsCatgory(state, payload) {
    const index = state.modelsCategories.findIndex(m => m.id === payload.id);
    if (index) {
      state.modelCategory = payload;
    }
  },
  deleteModelsCatgory(state, payload) {
    const filterArry = state.modelsCategories.filter(
      modelCategory => modelCategory.id != payload
    );
    state.modelsCategories = filterArry;
  },
  setDetailInput: (state, e) => {
    let modelCategory = state.modelCategory;
    modelCategory[e.target.name] = e.target.value;
    state.modelCategory = modelCategory;
  },
};

const actions = {
  async fetchModelsCategories({ commit }) {
    await API.get("model-category")
      .then(res => {
        commit("getModelsCategories", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async fetchModelCategory({ commit }, payload) {
    await API.get(`model-category/${payload}`)
      .then(res => {
        commit("getModelsCategory", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async post({ commit }, payload) {
    await API.post("model-category", payload, { headers: authHeader() })
      .then(res => {
        commit("addModelsCatgory", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    const id = payload.id;
    const data = payload;
    let modelCategoryData;
    await API.put(`model-category/${id}`, data, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("editModelsCatgory", payload);
          modelCategoryData = res.status;
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return modelCategoryData;
  },
  async delete({ commit }, payload) {
    await API.delete(`model-category/${payload}`, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("deleteModelsCatgory", payload);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  modelCategory: state => state.modelCategory,
  modelsCategories: state => state.modelsCategories,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
