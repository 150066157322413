<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>News Release Add</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors, setErrors }"
          enctype="multipart/form-data"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Title</label>
              <Field
                name="title"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.title }"
              />
              <div class="invalid-feedback">{{ errors.title }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Title</label>
              <Field
                name="title_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.title_ar }"
              />
              <div class="invalid-feedback">{{ errors.title_ar }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Sub Title</label>
              <Field
                name="subTitle"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.subTitle }"
              />
              <div class="invalid-feedback">{{ errors.subTitle }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Sub Title</label>
              <Field
                name="subTitle_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.subTitle_ar }"
              />
              <div class="invalid-feedback">{{ errors.subTitle_ar }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <label for="otherNews">Other News</label>
              <Field
                name="otherNews"
                type="checkbox"
                id="otherNews"
                class="mx-3"
                :value="true"
              />
            </div>
          </div>
          <!-- <div class="form-group">
            <label for="exampleInputEmail1">Description</label>
            <Field
              name="description"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.description }"
            />
            <div class="invalid-feedback">{{ errors.description }}</div>
          </div> -->
          <div class="form-group my-2">
            <label for="exampleInputEmail1">Description</label>

            <quill-editor
              v-model="description"
              class="mb-3"
              id="rich-text"
              rows="20"
              :options="editorOption"
              ref="myQuillEditor"
              @change="onChange($event)"
            >
            </quill-editor>
          </div>

          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.fileInput.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
              @change="onFileChange($event, setErrors)"
              accept="image/*"
              style="display: none"
            />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length" class="img-invalid">{{ errors[0] }}</div>
          </Field>
          <div v-if="imageData">
            <h3>Preview:</h3>
            <img
              :src="imageData"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],

  // [{'header': 1}, {'header': 2}],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],

  // [{'size': ['small', false, 'large', 'huge']}],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }],
  // [{'font': []}],
  [{ align: [] }],
  ["link"],
  ["clean"],
];

export default {
  name: "News Releases Add",
  components: {
    Form,
    Field,

    quillEditor,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      description: "",
      fileMain: null,
      imageData: null,
      schema: yup.object().shape({
        title: yup.string().trim().required("Title is required"),
        subTitle: yup.string().trim().required("Sub Title is required"),
        title_ar: yup.string().trim().required("Arabic Title is required"),
        subTitle_ar: yup
          .string()
          .trim()
          .required("Arabic Sub Title is required"),
        // description: yup.string().required("Description is required"),
      }),

      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,
          },
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
        },
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {},
  watch: {},
  methods: {
    onChange(event) {
      this.description = event.html;
    },
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image: this.fileMain,
        description: this.description,
      };
      console.log("VALUES", values);
      if (!this.fileMain) {
        actions.setFieldError("image", "Image is required");
        return;
      }
      let formData = new FormData();
      formData.append("image", this.fileMain);
      formData.append("title", obj.title);
      formData.append("subTitle", obj.subTitle);
      formData.append("description", obj.description);
      formData.append("title_ar", obj.title_ar);
      formData.append("subTitle_ar", obj.subTitle_ar);
      formData.append("otherNews", obj.otherNews);

      this.$store.dispatch("newReleases/post", formData);
      this.$swal({
        position: "center",
        icon: "success",
        title: "News Release has been Added",
        showConfirmButton: false,
        timer: 2000,
      });
      actions.resetForm();
      this.$router.push("/news-releases");
    },
    handleCancel() {
      this.$router.push("/news-releases");
    },
    onFileChange(event,setErrors) {
      const file = event.target.files[0];
      if (!file) {
        // If no file was selected, simply return without setting any errors
        return;
      }
      this.fileMain = file
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
        setErrors({ image: null }); // Clear the image error
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
