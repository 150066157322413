<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Model Category Edit</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="form-group row" v-if="modelCategory">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Category Name</label>
              <Field
                name="name"
                type="text"
                class="form-control"
                v-model="modelCategory.name"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Category Name</label>
              <Field
                name="name_ar"
                type="text"
                class="form-control"
                v-model="modelCategory.name_ar"
                :class="{ 'is-invalid': errors.name_ar }"
              />
              <div class="invalid-feedback">{{ errors.name_ar }}</div>
            </div>
          </div>
          <button type="submit" class="btn btn-danger">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
export default {
  name: "Model Catgory Add",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      schema: yup.object().shape({
        name: yup.string().trim().required("Category Name is required"),
        name_ar: yup
          .string()
          .trim()
          .required("Arabic Category Name is required"),
      }),
    };
  },
  computed: {
    ...mapGetters("modelsCategory", ["modelCategory", "isLoading"]),
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.id) {
      this.fetchDetail();
    }
  },

  mounted() {},
  methods: {
    updateInput() {
      this.$store.dispatch("modelsCategory/updateInput");
    },
    onFormSubmit(values, actions) {
      values = {
        ...values,
        name: values.name,
        name_ar: values.name_ar,
        id: this.id,
      };
      this.$store.dispatch("modelsCategory/put", values).then(res => {
        if (res == 200) {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Model Category has been Updated",
            showConfirmButton: false,
            timer: 2000,
          });
          actions.resetForm();
          this.$router.push("/models-category");
        }
        console.log("res", res);
      });
    },
    handleCancel() {
      this.$router.push("/models-category");
    },
    async fetchDetail() {
      try {
        await this.$store.dispatch(
          "modelsCategory/fetchModelCategory",
          this.id
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
