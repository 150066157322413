<template>
    <div class="card my-4 mx-4">
      <div
        class="card-header pb-0 d-flex justify-content-between align-content-center"
      >
        <h6>Test Drive</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <data-table
          :data-list="$store.state.testDrive.testdrive"
          :headerList="headerList"
        >
        </data-table>
      </div>
    </div>
  </template>
  
  <script>
  import DataTable from "../../components/DataTable.vue";
  // import { RouterLink } from "vue-router";
  const headerList = [
    { name: "Sr.no", id: "id" },
    { name: "Country", id: "country" },
    { name: "Gender", id: "gender" },
    { name: "Name", id: "name" },
    { name: "Phone Number", id: "phoneNumber" },
    { name: "Email", id: "email" },
    { name: "Created Date", id: "createdAt" },
  ];
  
  export default {
    name: "Test Drive",
    components: {
      DataTable,
      // RouterLink,
    },
    data() {
      return {
        headerList: headerList,
      };
    },
    created() {
      this.$store.dispatch("testDrive/fetchtestDrive", null, {
        root: true,
      });
    },
    mounted() {},
  };
  </script>
  