import API from "../../api/api";
import { authHeader } from "../../helpers/authHeader";
const state = () => ({
  termsConditionObj: null,
  loading: false,
});

const mutations = {
  getTermsConditionsDetail(state, payload) {
    state.termsConditionObj = payload;
  },
  addTermsConditions(state, payload) {
    state.termsConditionObj = payload;
  },
  editTermsConditions(state, payload) {
    // const index = state.models.findIndex((m) => m.id === payload.id);
    // if (index) {
    state.termsConditionObj = payload;
    // }
  },
  setDetailInput: (state, e) => {
    let termsConditionObj = state.termsConditionObj;
    termsConditionObj[e.target.name] = e.target.value;
    state.termsConditionObj = termsConditionObj;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchTermsConditionsDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`terms-and-condition/${payload}`)
      .then(res => {
        commit("getTermsConditionsDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("terms-and-condition", payload, {
      headers: authHeader(),
    })
      .then(res => {
        commit("addTermsConditions", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    await API.put(`terms-and-condition/1`, payload, {
      headers: authHeader(),
    })
      .then(res => {
        if (res) {
          commit("editTermsConditions", res.data.data);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  termsConditionObj: state => state.termsConditionObj,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
