<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>History Create</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors, setErrors }"
          enctype="multipart/form-data"
        >
          <div class="form-group">
            <label for="exampleInputEmail1">HistoryYear</label>
            <Field
              name="year"
              type="number"
              class="form-control"
              :class="{ 'is-invalid': errors.year }"
            />
            <div class="invalid-feedback">{{ errors.year }}</div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">History Description</label>
              <Field
                name="description"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.description }"
              />
              <div class="invalid-feedback">{{ errors.description }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic History Description</label>
              <Field
                name="description_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.description_ar }"
              />
              <div class="invalid-feedback">{{ errors.description_ar }}</div>
            </div>
          </div>
          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.fileInput.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
               @change="onFileChange($event, setErrors)"
              accept="image/*"
              style="display: none"
            />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length" class="img-invalid">{{ errors[0] }}</div>
          </Field>
          <div v-if="imageData">
            <h3>Preview:</h3>
            <img
              :src="imageData"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
export default {
  name: "History Add",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      fileMain: null,
      imageData: null,
      schema: yup.object().shape({
        year: yup.number().required("Year is required"),
        description: yup.string().trim().required("Description is required"),
        description_ar: yup
          .string()
          .trim()
          .required("Arabic Description is required"),
      }),
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image: this.fileMain,
      };
      let formData = new FormData();
      if (!this.fileMain) {
        actions.setFieldError("image", "Image is required");
        return;
      }
      formData.append("image", this.fileMain);
      formData.append("year", obj.year);
      formData.append("description", obj.description);
      formData.append("description_ar", obj.description_ar);
      this.$store.dispatch("history/post", formData);

      this.$swal({
        position: "center",
        icon: "success",
        title: "History has been Added",
        showConfirmButton: false,
        timer: 2000,
      });
      actions.resetForm();
      this.$router.push("/history");
    },
    handleCancel() {
      this.$router.push("/history");
    },
    onFileChange(event, setErrors) {
      const file = event.target.files[0];
      if (!file) {
        // If no file was selected, simply return without setting any errors
        return;
      }
      this.fileMain = file;
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
        setErrors({ image: null }); // Clear the image error
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
