import API from "../../api/api";
import { authHeader, authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  newsReleases: [],
  newsRelease: null,
  loading: false,
});

const mutations = {
  getNewsRelease(state, payload) {
    state.newsReleases = payload;
  },
  getNewsReleaseDetail(state, payload) {
    state.newsRelease = payload;
  },
  addNewsRelease(state, payload) {
    state.newsReleases.push(payload);
  },
  editNewsRelease(state, payload) {
    const index = state.newsReleases.findIndex(m => m.id === payload.id);
    if (index) {
      state.newsRelease = payload;
    }
  },
  deleteNewsRelease(state, payload) {
    const filterArry = state.newsReleases.filter(
      newsRelease => newsRelease.id != payload
    );
    state.newsReleases = filterArry;
  },

  setDetailInput: (state, e) => {
    let newsRelease = state.newsRelease;
    newsRelease[e.target.name] = e.target.value;
    state.newsRelease = newsRelease;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchNewReleases({ commit }) {
    await API.get("news-release")
      .then(res => {
        commit("getNewsRelease", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async fetchNewReleasesDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`news-release/${payload}`)
      .then(res => {
        commit("getNewsReleaseDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("news-release", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addNewsRelease", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    const id = payload.id;
    const data = payload.data;
    let newsReleaseData;
    await API.put(`news-release/${id}`, data, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editNewsRelease", payload);
          newsReleaseData = res.status
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return newsReleaseData;
  },
  async delete({ commit }, payload) {
    await API.delete(`news-release/${payload}`, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("deleteNewsRelease", payload);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  newsRelease: state => state.newsRelease,
  newsReleases: state => state.newsReleases,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
