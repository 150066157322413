<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit After Sales CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          class="form"
          @submit="onSubmit"
          :initial-values="initialData"
          :validation-schema="schema"
          enctype="multipart/form-data"
          v-slot="{ errors }"
          v-if="afterSalesService"
        >
          <div class="form-group row">
            <div class="col-md-5">
              <label for="title">Title</label>
              <Field
                name="title"
                type="text"
                class="form-control"
                v-model="afterSalesService.title"
                :class="{ 'is-invalid': errors.title }"
              />

              <div class="invalid-feedback">{{ errors.title }}</div>
            </div>
            <div class="col-md-5">
              <label for="title">Arabic Title</label>
              <Field
                name="title_ar"
                type="text"
                class="form-control"
                v-model="afterSalesService.title_ar"
                :class="{ 'is-invalid': errors.title_ar }"
              />

              <div class="invalid-feedback">{{ errors.title_ar }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="title">Sub Title Description</label>
            <Field
              name="subTitle"
              type="text"
              as="textarea"
              class="form-control"
              v-model="afterSalesService.subTitle"
              :class="{ 'is-invalid': errors.subTitle }"
            />

            <div class="invalid-feedback">{{ errors.subTitle }}</div>

            <label for="title">Arabic Sub Title Description</label>
            <Field
              name="subTitle_ar"
              type="text"
              as="textarea"
              class="form-control"
              v-model="afterSalesService.subTitle_ar"
              :class="{ 'is-invalid': errors.subTitle_ar }"
            />

            <div class="invalid-feedback">{{ errors.subTitle_ar }}</div>
          </div>

          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.fileInput.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
              @change="onFileChange"
              accept="image/*"
              style="display: none"
            />
            <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field>
            <div v-if="imageData">
              <h5>Preview Banner Image:</h5>
              <img
                :src="imageData"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
            <div v-if="afterSalesService && !imageData">
              <h5>Preview Banner Image:</h5>
              <img
                :src="imageApi + afterSalesService.bannerImage"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
          </div>
          <!-- <div class="my-4">
            <h4>Sales Services List Section</h4>
            <FieldArray name="imageJson">
              <fieldset
                class="form-group row"
                v-for="(field, idx) in afterSalesService?.imageJson"
                :key="idx"
              >
                <div class="col-md-3">
                  <label :for="`title_${idx}`">Title : </label>
                  <Field
                    class="form-control"
                    :id="`title_${idx}`"
                    :name="`imageJson[${idx}].title`"
                  />
                </div>
                <div class="col-md-3">
                  <label :for="`subTitle_${idx}`">Sub-Title : </label>
                  <Field
                    class="form-control"
                    :id="`subTitle_${idx}`"
                    :name="`imageJson[${idx}].subtitle`"
                  />
                </div>
                <div class="col-md-3">
                  <label :for="`image_${idx}`">Image : </label>
                  <Field
                    class="form-control"
                    :id="`image_${idx}`"
                    :name="`imageJson[${idx}].image`"
                    type="file"
                    @change="event => handleFileChange(event, idx)"
                  />
                  <div v-if="imageArray && imageArray.length > 0">
                    <h3>Preview:</h3>
                    <img
                      :src="imageArray[idx]"
                      alt="Image Preview"
                      height="200"
                      width="230"
                    />
                  </div>
                  <div v-if="afterSalesService?.imageJson[idx].image">
                    <h3>Preview:</h3>
                    <img
                      :src="imageApi + afterSalesService?.imageJson[idx].image"
                      alt="Image Preview"
                      height="200"
                      width="230"
                    />
                  </div>
                </div>
                <div class="col-md-1">
                  <button
                    type="button"
                    @click="removeIndexOfImageJson(field)"
                    class="btn btn-outline-danger rounded-circle p-3"
                  >
                    X
                  </button>
                </div>
              </fieldset>

              <button
                type="button"
                class="btn btn-outline-info"
                @click="
                  () =>
                    afterSalesService?.imageJson.push({
                      title: '',
                      subtitle: '',
                      image: '',
                    })
                "
              >
                Add
              </button>
            </FieldArray>
          </div> -->

          <div class="my-4">
            <h4>World Section</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="worldTitle">World Title</label>
                <Field
                  name="worldTitle"
                  type="text"
                  class="form-control"
                  v-model="afterSalesService.worldTitle"
                  :class="{ 'is-invalid': errors.worldTitle }"
                />

                <div class="invalid-feedback">{{ errors.worldTitle }}</div>
              </div>
              <div class="col-md-6">
                <label for="worldTitle">Arabic World Title</label>
                <Field
                  name="worldTitle_ar"
                  type="text"
                  class="form-control"
                  v-model="afterSalesService.worldTitle_ar"
                  :class="{ 'is-invalid': errors.worldTitle_ar }"
                />

                <div class="invalid-feedback">{{ errors.worldTitle_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="worldSubTitle"
                  >Multiple After Sales Sub Title</label
                >
                <Field
                  name="worldSubTitle"
                  type="text"
                  class="form-control"
                  v-model="afterSalesService.worldSubTitle"
                  :class="{ 'is-invalid': errors.worldSubTitle }"
                />

                <div class="invalid-feedback">{{ errors.worldSubTitle }}</div>
              </div>

              <div class="col-md-6">
                <label for="worldSubTitle_ar"
                  >Arabic Multiple After Sales Sub Title</label
                >
                <Field
                  name="worldSubTitle_ar"
                  type="text"
                  class="form-control"
                  v-model="afterSalesService.worldSubTitle_ar"
                  :class="{ 'is-invalid': errors.worldSubTitle_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.worldSubTitle_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.worldFileInput.click()"
                class="btn btn-outline-danger"
              >
                World Image Upload
              </button>
              <input
                type="file"
                ref="worldFileInput"
                @change="onFileChangeWorld"
                accept="image/*"
                style="display: none"
              />
              <div v-if="worldImage">
                <h5>Preview World Image:</h5>
                <img
                  :src="worldImage"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="afterSalesService && !worldImage">
                <h5>Preview World Image:</h5>
                <img
                  :src="imageApi + afterSalesService.worldimage"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div class="my-4">
            <h4>Feature Services Section</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="featureTitle">Feature Services Title</label>
                <Field
                  name="featureTitle"
                  type="text"
                  class="form-control"
                  v-model="afterSalesService.featureTitle"
                  :class="{ 'is-invalid': errors.featureTitle }"
                />

                <div class="invalid-feedback">{{ errors.featureTitle }}</div>
              </div>
              <div class="col-md-6">
                <label for="featureTitle_ar"
                  >Arabic Feature Services Title</label
                >
                <Field
                  name="featureTitle_ar"
                  type="text"
                  class="form-control"
                  v-model="afterSalesService.featureTitle_ar"
                  :class="{ 'is-invalid': errors.featureTitle_ar }"
                />

                <div class="invalid-feedback">{{ errors.featureTitle_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="featureSubTitle">Feature Services Sub Title</label>
                <Field
                  name="featureSubTitle"
                  type="text"
                  class="form-control"
                  v-model="afterSalesService.featureSubTitle"
                  :class="{ 'is-invalid': errors.featureSubTitle }"
                />

                <div class="invalid-feedback">{{ errors.featureSubTitle }}</div>
              </div>
              <div class="col-md-6">
                <label for="featureSubTitle_ar"
                  >Arabic Feature Services Sub Title</label
                >
                <Field
                  name="featureSubTitle_ar"
                  type="text"
                  class="form-control"
                  v-model="afterSalesService.featureSubTitle_ar"
                  :class="{ 'is-invalid': errors.featureSubTitle_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.featureSubTitle_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.featureFileInput.click()"
                class="btn btn-outline-danger"
              >
                Feature Image Upload
              </button>
              <input
                type="file"
                ref="featureFileInput"
                @change="onFileChangeFeature"
                accept="image/*"
                style="display: none"
              />
              <div v-if="featureImage">
                <h5>Preview Feature Image:</h5>
                <img
                  :src="featureImage"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="afterSalesService && !featureImage">
                <h5>Preview Feature Image:</h5>
                <img
                  :src="imageApi + afterSalesService.featureimage"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-success">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
import _ from "lodash";
export default {
  name: "After Sales Service",
  components: {
    Field,
    Form,
    // ErrorMessage,
    // FieldArray,
  },
  data: () => {
    const { setFieldValue } = useForm();
    const schema = yup.object().shape({
      title: yup.string().trim().required("Title is Required"),
      subTitle: yup.string().trim().required("Sub Title is Required"),
      worldTitle: yup.string().trim().required("World Title is Required"),
      worldSubTitle: yup
        .string()
        .trim()
        .required("World Sub Title is Required"),
      featureTitle: yup.string().trim().required("Feature Title is Required"),
      featureSubTitle: yup
        .string()
        .trim()
        .required("Feature Sub Title is Required"),
      title_ar: yup.string().trim().required("Arabic Title is Required"),
      subTitle_ar: yup.string().trim().required("Arabic Sub Title is Required"),
      worldTitle_ar: yup
        .string()
        .trim()
        .required("Arabic World Title is Required"),
      worldSubTitle_ar: yup
        .string()
        .trim()
        .required("Arabic World Sub Title is Required"),
      featureTitle_ar: yup
        .string()
        .trim()
        .required("Arabic Feature Title is Required"),
      featureSubTitle_ar: yup
        .string()
        .trim()
        .required("Arabic Feature Sub Title is Required"),
    });

    const initialData = {
      title: "",
      subTitle: "",
      worldTitle: "",
      worldSubTitle: "",
      featureTitle: "",
      featureSubTitle: "",
      title_ar: "",
      subTitle_ar: "",
      worldTitle_ar: "",
      worldSubTitle_ar: "",
      featureTitle_ar: "",
      featureSubTitle_ar: "",
      // imageJson: [{ title: "", subtitle: "", image: null }],
      bannerImage: null,
      featureImage: null,
      worldImage: null,
    };

    return {
      imageApi: imageApi,
      fileMain: null,
      worldFileMain: null,
      featureFileMain: null,
      imageData: null,
      worldImage: null,
      featureImage: null,
      schema,
      // imageArray: [],
      initialData,
      setFieldValue,
    };
  },
  computed: {
    ...mapGetters("afterSalesService", ["afterSalesService", "isLoading"]),
  },
  mounted() {
    this.fetchDetail();

    let initData = this.afterSalesService
      ? _.clone(this.afterSalesService)
      : {};

    this.initialData = this.afterSalesService
      ? {
          ...initData,
        }
      : this.initialData;
  },
  watch: {},
  methods: {
    // removeIndexOfImageJson(id) {
    //   const index = this.afterSalesService.imageJson.indexOf(id);
    //   if (index > -1) {
    //     // only splice array when item is found
    //     this.afterSalesService.imageJson.splice(index, 1); // 2nd parameter means remove one item only
    //   }
    // },
    fetchDetail() {
      try {
        this.$store.dispatch(
          "afterSalesService/fetchAfterSalesServiceDetail",
          1
        );
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      const obj = {
        ...values,
        bannerImage: this.fileMain,
        worldimage: this.worldFileMain,
        featureImage: this.featureFileMain,
      };

      let formData = new FormData();

      formData.append("title", obj.title);
      formData.append("subTitle", obj.subTitle);
      formData.append("worldTitle", obj.worldTitle);
      formData.append("worldSubTitle", obj.worldSubTitle);
      formData.append("featureTitle", obj.featureTitle);
      formData.append("featureSubTitle", obj.featureSubTitle);
      formData.append("title_ar", obj.title_ar);
      formData.append("subTitle_ar", obj.subTitle_ar);
      formData.append("worldTitle_ar", obj.worldTitle_ar);
      formData.append("worldSubTitle_ar", obj.worldSubTitle_ar);
      formData.append("featureTitle_ar", obj.featureTitle_ar);
      formData.append("featureSubTitle_ar", obj.featureSubTitle_ar);
      formData.append("bannerImage", obj.bannerImage);
      formData.append("worldimage", obj.worldimage);
      formData.append("featureImage", obj.featureImage);

      // obj?.imageJson?.forEach((arr, idx) => {
      //   console.log("arr image", arr.image);
      //   formData.append(`imageJson.title[${idx}]`, arr.title);
      //   formData.append(`imageJson.subtitle[${idx}]`, arr.subtitle);
      //   formData.append(`imageJson.image[${idx}]`, arr.image[0]);
      // });

      this.$store.dispatch("afterSalesService/put", formData);

      this.$swal({
        position: "center",
        icon: "success",
        title: "After Sales Service Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });

      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },
    // handleFileChange(event, index) {
    //   const file = event.target.files[0];

    //   // Simulate file upload (optional)
    //   if (file) {
    //     const reader = new FileReader();
    //     reader.onload = e => {
    //       this.imageArray[index] = e.target.result;
    //       // this.setFieldValue(`initialData.image[${index}]`, file); // Set file for validation (optional)
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // },
    onFileChange(event) {
      const file = event.target.files[0];

      this.fileMain = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("bannerImage", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    onFileChangeWorld(event) {
      const file = event.target.files[0];

      this.worldFileMain = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.worldImage = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("worldImage", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    onFileChangeFeature(event) {
      const file = event.target.files[0];

      this.featureFileMain = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.featureImage = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("featureImage", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
