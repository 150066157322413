<template>
  <div>
    <main class="mt-0 main-content main-content-bg">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row">
              <div
                class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column"
              >
                <div class="mt-8 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h3 class="font-weight-bolder text-success text-gradient">
                      Welcome back
                    </h3>
                    <p class="mb-0">Enter your email and password to sign in</p>
                  </div>
                  <div class="card-body">
                    <Form
                      as="form"
                      @submit="onFormSubmit"
                      class="form"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                    >
                      <div class="mb-3">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Email</label>
                          <Field
                            name="email"
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.email }"
                          />
                          <div class="invalid-feedback">{{ errors.email }}</div>
                        </div>
                      </div>
                      <div class="mb-3">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Password</label>
                          <Field
                            name="password"
                            type="password"
                            class="form-control"
                            :class="{ 'is-invalid': errors.password }"
                          />
                          <div class="invalid-feedback">
                            {{ errors.password }}
                          </div>
                        </div>
                      </div>
                      <div v-if="isError && error" class="my-3 text-danger">
                        <p>{{ error }}</p>
                      </div>
                      <div class="text-center">
                        <button type="submit" class="btn btn-danger my-2">
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
                >
                  <div
                    class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/curved-images/curved9.jpg') +
                        ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <!-- <app-footer /> -->
  </div>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import AppFooter from "@/examples/PageLayout/Footer.vue";
// import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftButton from "@/components/SoftButton.vue";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
const body = document.getElementsByTagName("body")[0];
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "SignIn",
  components: {
    // Navbar,
    // AppFooter,
    // SoftInput,
    // SoftSwitch,
    // SoftButton,
    Form,
    Field,
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },

  data() {
    return {
      schema: yup.object().shape({
        email: yup.string().email().required(" Email is required"),
        password: yup
          .string()
          .required(" Password is required")
          .min(8, "Minimum 8 Characters Required"),
      }),
    };
  },
  computed: {
    ...mapGetters("auth", ["error", "isError"]),
  },
  methods: {
    ...mapMutations("common", ["toggleEveryDisplay", "toggleHideConfig"]),
    onFormSubmit(values) {
      this.$store.dispatch("auth/login", values);
      // this.$swal({
      //   position: "center",
      //   icon: "success",
      //   title: "Vision has been Added",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });

      // actions.resetForm();
      // if (this.error) {
      // this.$router.push("/sign-in");
      // } else {
      // this.$router.push("/dashboard");
      // }
    },
  },
};
</script>
