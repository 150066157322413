<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit Overview CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onSubmit"
          class="form"
          :initial-values="initialData"
          :validation-schema="schema"
          v-slot="{ errors }"
          enctype="multipart/form-data"
          v-if="overviewObj"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="banner_title">Overview Banner Title</label>
              <Field
                name="banner_title"
                type="text"
                class="form-control"
                v-model="overviewObj.banner_title"
                :class="{ 'is-invalid': errors.banner_title }"
              />
              <div class="invalid-feedback">{{ errors.banner_title }}</div>
            </div>
            <div class="col-md-6">
              <label for="banner_title_ar">Overview Arabic Banner Title</label>
              <Field
                name="banner_title_ar"
                type="text"
                class="form-control"
                v-model="overviewObj.banner_title_ar"
                :class="{ 'is-invalid': errors.banner_title_ar }"
              />
              <div class="invalid-feedback">{{ errors.banner_title_ar }}</div>
            </div>
          </div>
          <div class="form-group my-2">
            <label for="banner_description">Overview Banner Description</label>
            <quill-editor
              v-model:content="overviewObj.banner_description"
              class="mb-3"
              id="rich-text"
              rows="20"
              ref="quill"
              @change="onChange($event)"
            >
            </quill-editor>
          </div>
          <div class="form-group my-2">
            <label for="banner_description_ar"
              >Overview Arabic Banner Description</label
            >
            <quill-editor
              v-model:content="overviewObj.banner_description_ar"
              class="mb-3"
              id="rich-text-ar"
              rows="20"
              ref="quill"
              @change="onChangeAR($event)"
            >
            </quill-editor>
          </div>
          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.bannerImageRef.click()"
              class="btn btn-outline-danger"
            >
              OverView Banner Image Upload
            </button>
            <input
              type="file"
              ref="bannerImageRef"
              @change="event => onFileChange(event, 'banner_image')"
              accept="image/*"
              style="display: none"
            />
            <div v-if="banner_imageData">
              <h5>Preview Overview Banner Image:</h5>
              <img
                :src="banner_imageData"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
            <div v-if="overviewObj && !banner_imageData">
              <h5>Preview Overview Banner Image:</h5>
              <img
                :src="imageApi + overviewObj.banner_image"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
          </div>
          <div class="my-4">
            <h4>Overview Section 1</h4>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image1Ref.click()"
                class="btn btn-outline-danger"
              >
                OverView Section 1 Image Upload
              </button>
              <input
                type="file"
                ref="image1Ref"
                @change="event => onFileChange(event, 'section1_image')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="section1_imageData">
                <h5>Preview Section 1 Image:</h5>
                <img
                  :src="section1_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="overviewObj && !section1_imageData">
                <h5>Preview Section 1 Image:</h5>
                <img
                  :src="imageApi + overviewObj.section1_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group my-2">
              <label for="section1_description"
                >Overview Section 1 Description</label
              >
              <quill-editor
                v-model:content="overviewObj.section1_description"
                class="mb-3"
                id="rich-text"
                rows="20"
                ref="quill"
                @change="onChangeSection1($event)"
              >
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section1_description_ar"
                >Overview Arabic Section 1 Description</label
              >
              <quill-editor
                v-model:content="overviewObj.section1_description_ar"
                class="mb-3"
                id="rich-text-ar"
                rows="20"
                ref="quill"
                @change="onChangeARSection1($event)"
              >
              </quill-editor>
            </div>
          </div>
          <!-- <div class="my-4">
            <h4>Overview Section 2</h4>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image2Ref.click()"
                class="btn btn-outline-danger"
              >
                Overview Section 2 Image Upload
              </button>
              <input
                type="file"
                ref="image2Ref"
                @change="event => onFileChange(event, 'section2_image')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="section2_imageData">
                <h5>Preview Section 2 Image:</h5>
                <img
                  :src="section2_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="overviewObj && !section2_imageData">
                <h5>Preview Section 2 Image:</h5>
                <img
                  :src="imageApi + overviewObj.section2_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group my-2">
              <label for="section2_description"
                >Overview Section 2 Description</label
              >
              <quill-editor
                v-model:content="overviewObj.section2_description"
                class="mb-3"
                id="rich-text"
                rows="20"
                ref="quill"
                @change="onChangeSection2($event)"
              >
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section2_description_ar"
                >Overview Arabic Section 2 Description</label
              >
              <quill-editor
                v-model:content="overviewObj.section2_description_ar"
                class="mb-3"
                id="rich-text-ar"
                rows="20"
                ref="quill"
                @change="onChangeARSection2($event)"
              >
              </quill-editor>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image3Ref.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image3Ref"
                @change="event => onFileChange(event, 'section2_bg_image')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="section2_bgimageData">
                <h5>Section 2 Background Preview Image:</h5>
                <img
                  :src="section2_bgimageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="overviewObj && !section2_bgimageData">
                <h5>Section 2 Background Preview Image:</h5>
                <img
                  :src="imageApi + overviewObj.section2_bg_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div class="my-4">
            <h4>Overview Section 3</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section3_main_title"
                  >Overview Section 3 Title</label
                >
                <Field
                  name="section3_main_title"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_main_title"
                  :class="{ 'is-invalid': errors.section3_main_title }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_main_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="section3_main_title_ar"
                  >Overview Arabic Section 3 Title</label
                >
                <Field
                  name="section3_main_title_ar"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_main_title_ar"
                  :class="{ 'is-invalid': errors.section3_main_title_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_main_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section3_subtitle"
                  >Overview Section 3 Sub Title</label
                >
                <Field
                  name="section3_subtitle"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_subtitle"
                  :class="{ 'is-invalid': errors.section3_subtitle }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_subtitle }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="section3_subtitle_ar"
                  >Overview Arabic Section 3 Sub Title</label
                >
                <Field
                  name="section3_subtitle_ar"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_subtitle_ar"
                  :class="{ 'is-invalid': errors.section3_subtitle_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_subtitle_ar }}
                </div>
              </div>
            </div>
            <div class="form-group my-2">
              <label for="section3_description"
                >Overview Section 3 Description</label
              >
              <quill-editor
                v-model:content="overviewObj.section3_description"
                class="mb-3"
                id="rich-text"
                rows="20"
                ref="quill"
                @change="onChangeSection3($event)"
              >
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section3_description_ar"
                >Overview Arabic Section 3 Description</label
              >
              <quill-editor
                v-model:content="overviewObj.section3_description_ar"
                class="mb-3"
                id="rich-text-ar"
                rows="20"
                ref="quill"
                @change="onChangeARSection3($event)"
              >
              </quill-editor>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image4Ref.click()"
                class="btn btn-outline-danger"
              >
                Section 3 Image 1 Upload
              </button>
              <input
                type="file"
                ref="image4Ref"
                @change="event => onFileChange(event, 'section3_image1')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="section3_imageData1">
                <h5>Section 3 Preview Image 1</h5>
                <img
                  :src="section3_imageData1"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="overviewObj && !section3_imageData1">
                <h5>Section 3 Preview Image 1</h5>
                <img
                  :src="imageApi + overviewObj.section3_image1"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section3_image1_title"
                  >Overview Section 3 Image 1 Title</label
                >
                <Field
                  name="section3_image1_title"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_image1_title"
                  :class="{ 'is-invalid': errors.section3_image1_title }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_image1_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="section3_image1_title_ar"
                  >Overview Arabic Section 3 Image 1 Title</label
                >
                <Field
                  name="section3_image1_title_ar"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_image1_title_ar"
                  :class="{ 'is-invalid': errors.section3_image1_title_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_image1_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image5Ref.click()"
                class="btn btn-outline-danger"
              >
                Section 3 Image 2 Upload
              </button>
              <input
                type="file"
                ref="image5Ref"
                @change="event => onFileChange(event, 'section3_image2')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="section3_imageData2">
                <h5>Section 3 Preview Image 2</h5>
                <img
                  :src="section3_imageData2"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="overviewObj && !section3_imageData2">
                <h5>Section 3 Preview Image 2</h5>
                <img
                  :src="imageApi + overviewObj.section3_image2"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section3_image2_title"
                  >Overview Section 3 Image 2 Title</label
                >
                <Field
                  name="section3_image2_title"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_image2_title"
                  :class="{ 'is-invalid': errors.section3_image2_title }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_image2_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="section3_image3_title_ar"
                  >Overview Arabic Section 3 Image 2 Title</label
                >
                <Field
                  name="section3_image2_title_ar"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_image2_title_ar"
                  :class="{ 'is-invalid': errors.section3_image2_title_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_image2_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image6Ref.click()"
                class="btn btn-outline-danger"
              >
                Section 3 Image 3 Upload
              </button>
              <input
                type="file"
                ref="image6Ref"
                @change="event => onFileChange(event, 'section3_image3')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="section3_imageData3">
                <h5>Section 3 Preview Image 3</h5>
                <img
                  :src="section3_imageData3"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="overviewObj && !section3_imageData3">
                <h5>Section 3 Preview Image 3</h5>
                <img
                  :src="imageApi + overviewObj.section3_image3"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section3_image3_title"
                  >Overview Section 3 Image 1 Title</label
                >
                <Field
                  name="section3_image3_title"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_image3_title"
                  :class="{ 'is-invalid': errors.section3_image3_title }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_image3_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="section3_image3_title_ar"
                  >Overview Arabic Section 3 Image 1 Title</label
                >
                <Field
                  name="section3_image3_title_ar"
                  type="text"
                  class="form-control"
                  v-model="overviewObj.section3_image3_title_ar"
                  :class="{ 'is-invalid': errors.section3_image3_title_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.section3_image3_title_ar }}
                </div>
              </div>
            </div>
          </div> -->
          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import imageApi from "../../api/imageapi";
import _ from "lodash";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "Overview",
  components: {
    Form,
    Field,
    quillEditor,
  },
  data() {
    const { setFieldValue } = useForm();

    const schema = yup.object().shape({
      // banner
      banner_title: yup.string().required("Overview Banner Title is Required"),
      banner_title_ar: yup
        .string()
        .required("Overview Banner Arabic Title is Required"),
      // banner_description: yup.string().required("Overview Banner Description is Required"),
      // banner_description_ar: yup.string().required("Overview Banner Arabic Description is Required"),

      // section1

      // section1_description: yup.string().required("Overview Section 1 Description is Required"),
      // section1_description_ar: yup.string().required("Overview Section 1 Arabic Description is Required"),

      // section2

      // section2_description: yup.string().required("Overview Section 2 Description is Required"),
      // section2_description_ar: yup.string().required("Overview Section 2 Arabic Description is Required"),

      // section3
      // section3_main_title: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Main Title is Required"),
      // section3_main_title_ar: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Arabic Main Title is Required"),
      // section3_subtitle: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Sub Title is Required"),
      // section3_subtitle_ar: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Arabic Sub Title is Required"),
      // section3_description: yup.string().required("Overview Section 3 Description is Required"),
      // section3_description_ar: yup.string().required("Overview Section 3 Arabic Description is Required"),
      // section3_image1_title: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Image 1 Title is Required"),
      // section3_image1_title_ar: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Arabic Image 1 Title is Required"),
      // section3_image2_title: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Image 2 Title is Required"),
      // section3_image2_title_ar: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Arabic Image 2 Title is Required"),
      // section3_image3_title: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Image 3 Title is Required"),
      // section3_image3_title_ar: yup
      //   .string()
      //   .trim()
      //   .required("Overview Section 3 Arabic Image 3 Title is Required"),
    });

    const initialData = {
      banner_title: "",
      banner_title_ar: "",
      // section3_main_title: "",
      // section3_main_title_ar: "",
      // section3_subtitle: "",
      // section3_subtitle_ar: "",
      // section3_image1_title: "",
      // section3_image1_title_ar: "",
      // section3_image2_title: "",
      // section3_image2_title_ar: "",
      // section3_image3_title: "",
      // section3_image3_title_ar: "",
    };

    return {
      imageApi: imageApi,
      banner_description: "",
      banner_description_ar: "",
      section1_description: "",
      section1_description_ar: "",
      banner_image: null,
      // section2_bg_image: null,
      // section2_image: null,
      // section2_description: "",
      // section2_description_ar: "",
      // section3_description: "",
      // section3_description_ar: "",
      // section3_image1: null,
      // section3_image2: null,
      // section3_image3: null,
      schema,
      initialData,
      setFieldValue,
    };
  },
  computed: {
    ...mapGetters("overView", ["overviewObj", "isLoading"]),
  },
  async mounted() {
    await this.fetchDetail();
    let initData = this.overviewObj ? _.clone(this.overviewObj) : {};
    this.initialData = this.overviewObj
      ? {
          ...initData,
        }
      : this.initialData;
    this.banner_description = this.overviewObj.banner_description;
    this.banner_description_ar = this.overviewObj.banner_description_ar;
    this.section1_description = this.overviewObj.section1_description;
    this.section1_description_ar = this.overviewObj.section1_description_ar;
    // this.section2_description = this.overviewObj.section2_description;
    // this.section2_description_ar = this.overviewObj.section2_description_ar;
    // this.section3_description = this.overviewObj.section3_description;
    // this.section3_description_ar = this.overviewObj.section3_description_ar;
  },
  methods: {
    onChange(event) {
      this.banner_description = event.html;
    },
    onChangeAR(event1) {
      this.banner_description_ar = event1.html;
    },
    onChangeSection1(event2) {
      this.section1_description = event2.html;
    },
    onChangeARSection1(event3) {
      this.section1_description_ar = event3.html;
    },
    // onChangeSection2(event4) {
    //   this.section2_description = event4.html;
    // },
    // onChangeARSection2(event5) {
    //   this.section2_description_ar = event5.html;
    // },
    // onChangeSection3(event6) {
    //   this.section3_description = event6.html;
    // },
    // onChangeARSection3(event7) {
    //   this.section3_description_ar = event7.html;
    // },
    async fetchDetail() {
      try {
        await this.$store.dispatch("overView/fetchOverviewDetail", 1);
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      const obj = {
        ...values,
        banner_description: this.banner_description,
        banner_description_ar: this.banner_description_ar,
        section1_description: this.section1_description,
        section1_description_ar: this.section1_description_ar,
        // section2_description: this.section2_description,
        // section2_description_ar: this.section2_description_ar,
        // section3_description: this.section3_description,
        // section3_description_ar: this.section3_description_ar,
        banner_image: this.banner_image,
        section1_image: this.section1_image,
        // section2_image: this.section2_image,
        // section2_bg_image: this.section2_bg_image,
        // section3_image1: this.section3_image1,
        // section3_image2: this.section3_image2,
        // section3_image3: this.section3_image3,
      };
      let formData = new FormData();

      // banner
      formData.append("banner_image", obj.banner_image);
      formData.append("banner_title", obj.banner_title);
      formData.append("banner_title_ar", obj.banner_title_ar);
      formData.append("banner_description", obj.banner_description);
      formData.append("banner_description_ar", obj.banner_description_ar);

      // section 1
      formData.append("section1_image", obj.section1_image);
      formData.append("section1_description", obj.section1_description);
      formData.append("section1_description_ar", obj.section1_description_ar);

      //section2
      // formData.append("section2_image", obj.section2_image);
      // formData.append("section2_bg_image", obj.section2_bg_image);
      // formData.append("section2_description", obj.section2_description);
      // formData.append("section2_description_ar", obj.section2_description_ar);

      //section3
      // formData.append("section3_image1", obj.section3_image1);
      // formData.append("section3_image2", obj.section3_image2);
      // formData.append("section3_image3", obj.section3_image3);
      // formData.append("section3_main_title", obj.section3_main_title);
      // formData.append("section3_main_title_ar", obj.section3_main_title_ar);
      // formData.append("section3_subtitle", obj.section3_subtitle);
      // formData.append("section3_subtitle_ar", obj.section3_subtitle_ar);
      // formData.append("section3_description", obj.section3_description);
      // formData.append("section3_description_ar", obj.section3_description_ar);
      // formData.append("section3_image1_title", obj.section3_image1_title);
      // formData.append("section3_image1_title_ar", obj.section3_image1_title_ar);
      // formData.append("section3_image2_title", obj.section3_image2_title);
      // formData.append("section3_image2_title_ar", obj.section3_image2_title_ar);
      // formData.append("section3_image3_title", obj.section3_image3_title);
      // formData.append("section3_image3_title_ar", obj.section3_image3_title_ar);
      this.$store.dispatch("overView/put", formData);
      this.$swal({
        position: "center",
        icon: "success",
        title: "Overview Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });
      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },
    onFileChange(event, title) {
      const file = event.target.files[0];
      if (title == "banner_image") {
        this.banner_image = event.target.files[0];
      } else if (title == "section1_image") {
        this.section1_image = event.target.files[0];
      } 
      // else if (title == "section2_image") {
      //   this.section2_image = event.target.files[0];
      // } else if (title == "section2_bg_image") {
      //   this.section2_bg_image = event.target.files[0];
      // } else if (title == "section3_image1") {
      //   this.section3_image1 = event.target.files[0];
      // } else if (title == "section3_image2") {
      //   this.section3_image2 = event.target.files[0];
      // } else if (title == "section3_image3") {
      //   this.section3_image3 = event.target.files[0];
      // }

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          if (title == "banner_image") {
            this.banner_imageData = e.target.result;
          } else if (title == "section1_image") {
            this.section1_imageData = e.target.result;
          } 
          // else if (title == "section2_image") {
          //   this.section2_imageData = e.target.result;
          // } else if (title == "section2_bg_image") {
          //   this.section2_bgimageData = e.target.result;
          // } else if (title == "section3_image1") {
          //   this.section3_imageData1 = e.target.result;
          // } else if (title == "section3_image2") {
          //   this.section3_imageData2 = e.target.result;
          // } else if (title == "section3_image3") {
          //   this.section3_imageData3 = e.target.result;
          // }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
