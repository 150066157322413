<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>CMS PAGES</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-3">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Page</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Home CMS</td>
              <td>
                <RouterLink to="/home-cms" class="link text-danger"
                  >Home CMS</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Off-Road</td>
              <td>
                <RouterLink to="/off-road" class="link text-danger"
                  >Off-Road CMS</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>After Sales Service</td>
              <td>
                <RouterLink to="/after-sales-service" class="link text-danger"
                  >After Sales Service CMS</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Capital Beauty</td>
              <td>
                <RouterLink to="/capital-beauty" class="link text-danger"
                  >Capital Beauty CMS</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Research & Development</td>
              <td>
                <RouterLink to="/research-development" class="link text-danger"
                  >Research & Development CMS</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>Concept Car</td>
              <td>
                <RouterLink to="/concept-car" class="link text-danger"
                  >Concept Car</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>OverView</td>
              <td>
                <RouterLink to="/overview" class="link text-danger"
                  >OverView</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>Terms & Conditions</td>
              <td>
                <RouterLink to="/terms-and-conditions" class="link text-danger"
                  >Terms & Conditions</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>Cookie Policy</td>
              <td>
                <RouterLink to="/cookie-policy" class="link text-danger"
                  >Cookie Policy</RouterLink
                >
              </td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>Data Protection</td>
              <td>
                <RouterLink to="/data-protection" class="link text-danger"
                  >Data Protection</RouterLink
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
export default {
  name: "CMS Pages",
  components: {
    RouterLink,
  },
};
</script>
