<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Model Category Create</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Category Name</label>
              <Field
                name="categoryName"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.categoryName }"
              />
              <div class="invalid-feedback">{{ errors.categoryName }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Category Name</label>
              <Field
                name="categoryName_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.categoryName_ar }"
              />
              <div class="invalid-feedback">{{ errors.categoryName_ar }}</div>
            </div>
          </div>
          <button type="submit" class="btn btn-danger">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Model Catgory Add",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      schema: yup.object().shape({
        categoryName: yup.string().trim().required("Category Name is required"),
        categoryName_ar: yup
          .string()
          .trim()
          .required("Arabic Category Name is required"),
      }),
    };
  },
  mounted() {},
  methods: {
    onFormSubmit(values, actions) {
      values = {
        ...values,
        name: values.categoryName,
        name_ar: values.categoryName_ar,
      };
      this.$store.dispatch("modelsCategory/post", values);
      this.$swal({
        position: "center",
        icon: "success",
        title: "Model Category has been Added",
        showConfirmButton: false,
        timer: 2000,
      });
      actions.resetForm();
      this.$router.push("/models-category");
    },
    handleCancel() {
      this.$router.push("/models-category");
    },
  },
};
</script>
