<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Model Create</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors, setErrors }"
          enctype="multipart/form-data"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Model Name</label>
              <Field
                name="modelName"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.modelName }"
              />
              <div class="invalid-feedback">{{ errors.modelName }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Model Name</label>
              <Field
                name="modelName_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.modelName_ar }"
              />
              <div class="invalid-feedback">{{ errors.modelName_ar }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Model Description</label>
              <Field
                name="description"
                type="text"
                as="textarea"
                class="form-control"
                :class="{ 'is-invalid': errors.description }"
              />
              <div class="invalid-feedback">{{ errors.description }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Model Description</label>
              <Field
                name="description_ar"
                type="text"
                as="textarea"
                class="form-control"
                :class="{ 'is-invalid': errors.description_ar }"
              />
              <div class="invalid-feedback">{{ errors.description_ar }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Model Category</label>
            <Field
              name="modelCategory"
              as="select"
              class="form-control"
              :class="{ 'is-invalid': errors.modelCategory }"
            >
              <option value="" disabled>Select value</option>
              <option
                v-for="(category, index) in modelsCategories"
                :key="index"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </Field>

            <div class="invalid-feedback">{{ errors.modelCategory }}</div>
          </div>

          <div class="form-group row">
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Power</label>
              <Field
                name="power"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.power }"
              />
              <div class="invalid-feedback">{{ errors.power }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Torque</label>
              <Field
                name="torque"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.torque }"
              />
              <div class="invalid-feedback">{{ errors.torque }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Wheelbase</label>
              <Field
                name="wheelbase"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.wheelbase }"
              />
              <div class="invalid-feedback">{{ errors.wheelbase }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model VR Link</label>
              <Field
                name="vr_link"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.vr_link }"
              />
              <div class="invalid-feedback">{{ errors.vr_link }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <button
                class="btn btn-outline-danger"
                @click.prevent="() => this.$refs.image1.click()"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image1"
                @change="event => onFileChange(event, 'image1', setErrors)"
                accept="image/*"
                style="display: none"
              />
              <div v-if="errors.image1" class="img-invalid">
                {{ errors.image1 }}
              </div>
              <div v-if="image1Data">
                <h6>Preview:</h6>
                <img
                  :src="image1Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="col-md-6">
              <button
                class="btn btn-outline-danger"
                @click.prevent="() => this.$refs.image1_ar.click()"
              >
                Image Upload Arabic
              </button>
              <input
                type="file"
                ref="image1_ar"
                @change="event => onFileChange(event, 'image1_ar', setErrors)"
                accept="image/*"
                style="display: none"
              />
              <div v-if="errors.image1_ar" class="img-invalid">
                {{ errors.image1_ar }}
              </div>
              <div v-if="image1Data_ar">
                <h6>Preview:</h6>
                <img
                  :src="image1Data_ar"
                  alt="Image Preview Arabic"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Section 2</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Section 2 Title </label>
                <Field
                  name="image2_title"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image2_title }"
                />
                <div class="invalid-feedback">{{ errors.image2_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Section 2 Title</label>
                <Field
                  name="image2_title_ar"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image2_title_ar }"
                />
                <div class="invalid-feedback">{{ errors.image2_title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="exampleInputEmail1">Section 2 Description</label>
                <Field
                  name="image2_description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image2_description }"
                />
                <div class="invalid-feedback">
                  {{ errors.image2_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="exampleInputEmail1"
                  >Arabic Section 2 Description</label
                >
                <Field
                  name="image2_description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image2_description_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.image2_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="() => this.$refs.image2.click()"
                >
                  Section 2 Image Upload
                </button>
                <input
                  type="file"
                  ref="image2"
                  @change="event => onFileChange(event, 'image2', setErrors)"
                  accept="image/*"
                  style="display: none"
                />
                <div v-if="errors.image2" class="img-invalid">
                  {{ errors.image2 }}
                </div>
                <div v-if="image2Data">
                  <h6>Preview:</h6>
                  <img
                    :src="image2Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="() => this.$refs.image2_ar.click()"
                >
                  Section 2 Image Upload Arabic
                </button>
                <input
                  type="file"
                  ref="image2_ar"
                  @change="event => onFileChange(event, 'image2_ar', setErrors)"
                  accept="image/*"
                  style="display: none"
                />
                <div v-if="errors.image2_ar" class="img-invalid">
                  {{ errors.image2_ar }}
                </div>
                <div v-if="image2Data_ar">
                  <h6>Preview Arabic:</h6>
                  <img
                    :src="image2Data_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4>Section 3</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Section 3 Title </label>
                <Field
                  name="image3_title"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image3_title }"
                />
                <div class="invalid-feedback">{{ errors.image3_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Section 3 Title</label>
                <Field
                  name="image3_title_ar"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image3_title_ar }"
                />
                <div class="invalid-feedback">{{ errors.image3_title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="exampleInputEmail1">Section 3 Description</label>
                <Field
                  name="image3_description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image3_description }"
                />
                <div class="invalid-feedback">
                  {{ errors.image3_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="exampleInputEmail1"
                  >Arabic Section 3 Description</label
                >
                <Field
                  name="image3_description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image3_description_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.image3_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="() => this.$refs.image3.click()"
                >
                  Section 3 Image Upload
                </button>
                <input
                  type="file"
                  ref="image3"
                  @change="event => onFileChange(event, 'image3', setErrors)"
                  accept="image/*"
                  style="display: none"
                />
                <div v-if="errors.image3" class="img-invalid">
                  {{ errors.image3 }}
                </div>
                <div v-if="image3Data">
                  <h6>Preview:</h6>
                  <img
                    :src="image3Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="() => this.$refs.image3_ar.click()"
                >
                  Section 3 Image Upload Arabic
                </button>
                <input
                  type="file"
                  ref="image3_ar"
                  @change="event => onFileChange(event, 'image3_ar', setErrors)"
                  accept="image/*"
                  style="display: none"
                />
                <div v-if="errors.image3_ar" class="img-invalid">
                  {{ errors.image3_ar }}
                </div>
                <div v-if="image3Data_ar">
                  <h6>Preview Arabic:</h6>
                  <img
                    :src="image3Data_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4>Section 4</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Section 4 Title </label>
                <Field
                  name="image4_title"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image4_title }"
                />
                <div class="invalid-feedback">{{ errors.image4_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Section 4 Title</label>
                <Field
                  name="image4_title_ar"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image4_title_ar }"
                />
                <div class="invalid-feedback">{{ errors.image4_title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="exampleInputEmail1">Section 4 Description</label>
                <Field
                  name="image4_description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image4_description }"
                />
                <div class="invalid-feedback">
                  {{ errors.image4_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="exampleInputEmail1"
                  >Arabic Section 4 Description</label
                >
                <Field
                  name="image4_description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image4_description_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.image4_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="() => this.$refs.image4.click()"
                >
                  Section 4 Image Upload
                </button>
                <input
                  type="file"
                  ref="image4"
                  @change="event => onFileChange(event, 'image4', setErrors)"
                  accept="image/*"
                  style="display: none"
                />
                <div v-if="errors.image4" class="img-invalid">
                  {{ errors.image4 }}
                </div>
                <div v-if="image4Data">
                  <h6>Preview:</h6>
                  <img
                    :src="image4Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="() => this.$refs.image4_ar.click()"
                >
                  Section 4 Image Upload Arabic
                </button>
                <input
                  type="file"
                  ref="image4_ar"
                  @change="event => onFileChange(event, 'image4_ar', setErrors)"
                  accept="image/*"
                  style="display: none"
                />
                <div v-if="errors.image4_ar" class="img-invalid">
                  {{ errors.image4_ar }}
                </div>
                <div v-if="image4Data_ar">
                  <h6>Preview Arabic:</h6>
                  <img
                    :src="image4Data_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4>Section 5</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Section 5 Title </label>
                <Field
                  name="image5_title"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image5_title }"
                />
                <div class="invalid-feedback">{{ errors.image5_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Section 5 Title</label>
                <Field
                  name="image5_title_ar"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image5_title_ar }"
                />
                <div class="invalid-feedback">{{ errors.image5_title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="exampleInputEmail1">Section 5 Description</label>
                <Field
                  name="image5_description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image5_description }"
                />
                <div class="invalid-feedback">
                  {{ errors.image5_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="exampleInputEmail1"
                  >Arabic Section 5 Description</label
                >
                <Field
                  name="image5_description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image5_description_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.image5_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="() => this.$refs.image5.click()"
                >
                  Section 5 Image Upload
                </button>
                <input
                  type="file"
                  ref="image5"
                  @change="event => onFileChange(event, 'image5', setErrors)"
                  accept="image/*"
                  style="display: none"
                />
                <div v-if="errors.image5" class="img-invalid">
                  {{ errors.image5 }}
                </div>
                <div v-if="image5Data">
                  <h6>Preview:</h6>
                  <img
                    :src="image5Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-outline-danger"
                  @click.prevent="() => this.$refs.image5_ar.click()"
                >
                  Section 5 Image Upload Arabic
                </button>
                <input
                  type="file"
                  ref="image5_ar"
                  @change="event => onFileChange(event, 'image5_ar', setErrors)"
                  accept="image/*"
                  style="display: none"
                />
                <div v-if="errors.image5_ar" class="img-invalid">
                  {{ errors.image5_ar }}
                </div>
                <div v-if="image5Data_ar">
                  <h6>Preview Arabic :</h6>
                  <img
                    :src="image5Data_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group my-3">
            <h3>More Sections Add</h3>
            <FieldArray name="moreSections" v-slot="{ fields, push, remove }">
              <div
                v-for="(entry, idx) in fields"
                :key="entry.key"
                class="card shadow my-2"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-5">
                      <label for="exampleInputEmail1"
                        >Section {{ idx + 1 }} Title</label
                      >

                      <Field
                        :name="`moreSections[${idx}].title`"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-5">
                      <label for="exampleInputEmail1"
                        >Section {{ idx + 1 }} Title Arabic</label
                      >

                      <Field
                        :name="`moreSections[${idx}].title_ar`"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-5">
                      <label for="exampleInputEmail1"
                        >Section {{ idx + 1 }} Description</label
                      >

                      <Field
                        :name="`moreSections[${idx}].description`"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-5">
                      <label for="exampleInputEmail1"
                        >Section {{ idx + 1 }} Description Arabic</label
                      >

                      <Field
                        :name="`moreSections[${idx}].description_ar`"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-5">
                      <label for="exampleInputEmail1"
                        >Section {{ idx + 1 }} Image</label
                      >

                      <Field
                        :name="`moreSections[${idx}].image`"
                        type="file"
                        @change="onFileChangeSectionImage($event, idx)"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-5">
                      <label for="exampleInputEmail1"
                        >Section {{ idx + 1 }} Image Arabic</label
                      >

                      <Field
                        :name="`moreSections[${idx}].image_ar`"
                        type="file"
                        class="form-control"
                        @change="onFileChangeSectionImageAr($event, idx)"
                      />
                    </div>
                    <div class="col-md-3 my-3">
                      <button
                        type="button"
                        class="btn btn-warning"
                        @click="remove(idx)"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-info my-2"
                @click="
                  push({
                    title: '',
                    description: '',
                    title_ar: '',
                    description_ar: '',
                    image: '',
                    image_ar: '',
                  }),
                    onItemAdd()
                "
              >
                Add
              </button>
            </FieldArray>
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm, FieldArray } from "vee-validate";
import * as yup from "yup";
import { useDropzone } from "vue3-dropzone";
import { mapGetters } from "vuex";
export default {
  name: "Model Add",
  components: {
    Form,
    Field,
    FieldArray,
  },
  setup() {
    let files = [];
    const saveFiles = afiles => {
      // const formData = new FormData(); // pass data as a form
      for (var x = 0; x < afiles.length; x++) {
        // formData.append("images[]", files[x]);
        files.push(afiles[x]);
      }
    };

    function onDrop(acceptFiles) {
      saveFiles(acceptFiles); // saveFiles as callback
      console.log("file", acceptFiles);
    }

    const { getRootProps, getInputProps, isDragActive, ...rest } = useDropzone({
      onDrop,
    });
    const { setFieldValue } = useForm();
    return {
      setFieldValue,
      getRootProps,
      getInputProps,
      isDragActive,
      rest,
      files,
    };
  },
  data() {
    return {
      moreSectionImages: [],
      image1: null,
      image1Data: null,
      image2: null,
      image2Data: null,
      image3: null,
      image3Data: null,
      image4: null,
      image4Data: null,
      image5: null,
      image5Data: null,
      image1_ar: null,
      image1Data_ar: null,
      image2_ar: null,
      image2Data_ar: null,
      image3_ar: null,
      image3Data_ar: null,
      image4_ar: null,
      image4Data_ar: null,
      image5_ar: null,
      image5Data_ar: null,
      schema: yup.object().shape({
        power: yup.string().trim().required("Model Power is required"),
        torque: yup.string().trim().required("Model Torque is required"),
        wheelbase: yup.string().trim().required("Model Wheelbase is required"),
        vr_link: yup.string().trim().required("Model VR Link is required"),
        modelName: yup.string().trim().required("Model Name is required"),
        modelName_ar: yup
          .string()
          .trim()
          .required("Arabic Model Name is required"),
        description: yup.string().trim().required("Description is required"),
        description_ar: yup
          .string()
          .trim()
          .required("Arabic Description is required"),
        modelCategory: yup
          .string()
          .required("Model Category is required")
          .min(1)
          .max(15),
      }),
    };
  },
  computed: {
    ...mapGetters("modelsCategory", ["modelsCategories"]),
  },
  mounted() {
    this.$store.dispatch("modelsCategory/fetchModelsCategories", null, {
      root: true,
    });
  },
  methods: {
    onItemAdd() {
      this.moreSectionImages.push({ image: null, image_ar: null });
    },
    onFormSubmit(values, actions) {
      console.log("VALUES", values);

      const obj = {
        ...values,
        image1: this.fileMain,
      };

      let formData = new FormData();
      let hasError = false;

      if (!this.$refs.image1.files[0]) {
        actions.setFieldError("image1", "Image is required");
        hasError = true;
      } else {
        formData.append("image1", this.$refs.image1.files[0]);
      }

      if (!this.$refs.image2.files[0]) {
        actions.setFieldError("image2", "Section 2 image is required");
        hasError = true;
      } else {
        formData.append("image2", this.$refs.image2.files[0]);
      }

      if (!this.$refs.image3.files[0]) {
        actions.setFieldError("image3", "Section 3 image is required");
        hasError = true;
      } else {
        formData.append("image3", this.$refs.image3.files[0]);
      }

      if (!this.$refs.image4.files[0]) {
        actions.setFieldError("image4", "Section 4 image is required");
        hasError = true;
      } else {
        formData.append("image4", this.$refs.image4.files[0]);
      }

      if (!this.$refs.image5.files[0]) {
        actions.setFieldError("image5", "Section 5 image is required");
        hasError = true;
      } else {
        formData.append("image5", this.$refs.image5.files[0]);
      }

      if (!this.$refs.image1_ar.files[0]) {
        actions.setFieldError("image1_ar", "Image Arabic is required");
        hasError = true;
      } else {
        formData.append("image1_ar", this.$refs.image1_ar.files[0]);
      }

      if (!this.$refs.image2_ar.files[0]) {
        actions.setFieldError(
          "image2_ar",
          "Section 2 image Arabic is required"
        );
        hasError = true;
      } else {
        formData.append("image2_ar", this.$refs.image2_ar.files[0]);
      }

      if (!this.$refs.image3_ar.files[0]) {
        actions.setFieldError(
          "image3_ar",
          "Section 3 image Arabic is required"
        );
        hasError = true;
      } else {
        formData.append("image3_ar", this.$refs.image3_ar.files[0]);
      }

      if (!this.$refs.image4_ar.files[0]) {
        actions.setFieldError(
          "image4_ar",
          "Section 4 image Arabic is required"
        );
        hasError = true;
      } else {
        formData.append("image4_ar", this.$refs.image4_ar.files[0]);
      }

      if (!this.$refs.image5_ar.files[0]) {
        actions.setFieldError(
          "image5_ar",
          "Section 5 image Arabic is required"
        );
        hasError = true;
      } else {
        formData.append("image5_ar", this.$refs.image5_ar.files[0]);
      }

      if (hasError) {
        return;
      }

      formData.append("name", obj.modelName);
      formData.append("power", obj.power);
      formData.append("torque", obj.torque);
      formData.append("wheelbase", obj.wheelbase);
      formData.append("vr_link", obj.vr_link);
      formData.append("description", obj.description);
      formData.append("name_ar", obj.modelName_ar);
      formData.append("description_ar", obj.description_ar);
      formData.append("categoryId", obj.modelCategory);
      formData.append("image2_description", obj.image2_description);
      formData.append("image2_description_ar", obj.image2_description_ar);
      formData.append("image2_title", obj.image2_title);
      formData.append("image2_title_ar", obj.image2_title_ar);
      formData.append("image3_description", obj.image3_description);
      formData.append("image3_description_ar", obj.image3_description_ar);
      formData.append("image3_title", obj.image3_title);
      formData.append("image3_title_ar", obj.image3_title_ar);
      formData.append("image4_description", obj.image4_description);
      formData.append("image4_description_ar", obj.image4_description_ar);
      formData.append("image4_title", obj.image4_title);
      formData.append("image4_title_ar", obj.image4_title_ar);
      formData.append("image5_description", obj.image5_description);
      formData.append("image5_description_ar", obj.image5_description_ar);
      formData.append("image5_title", obj.image5_title);
      formData.append("image5_title_ar", obj.image5_title_ar);
      if (values.moreSections) {
        for (let f = 0; f < values.moreSections.length; f++) {
          let value = values.moreSections[f];
          Object.keys(value).reduce((acc, key) => {
            if (key == "image") {
              formData.append(
                `moreSections[${f}].${key}`,
                this.moreSectionImages[f].image
              );
              formData.append(
                `moreSections[${f}].${key}`,
                this.moreSectionImages[f].image.name
              );
            } else if (key == "image_ar") {
              formData.append(
                `moreSections[${f}].${key}`,
                this.moreSectionImages[f].image_ar
              );
              formData.append(
                `moreSections[${f}].${key}`,
                this.moreSectionImages[f].image_ar.name
              );
            } else {
              formData.append(`moreSections[${f}].${key}`, value[key]);
            }
          }, {});
        }
      }

      this.$store
        .dispatch("models/post", formData)
        .then(res => {
          if (res == 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Model has been Added",
              showConfirmButton: false,
              timer: 2000,
            });

            actions.resetForm();
            this.$router.push("/models");
          } else {
            this.$swal(
              "Error!",
              "There was an issue adding the model.",
              "error"
            );
          }
        })
        .catch(error => {
          console.error("Error adding model:", error);
          this.$swal("Error!", "There was an issue adding the model.", "error");
        });
    },
    handleCancel() {
      this.$router.push("/models");
    },
    onFileChangeSectionImageAr(event, index) {
      const file = event.target.files[0];

      this.moreSectionImages[index].image_ar = file;
    },
    onFileChangeSectionImage(event, index) {
      const file = event.target.files[0];
      this.moreSectionImages[index].image = file;
    },
    onFileChange(event, title, setErrors) {
      const file = event.target.files[0];

      if (title == "image1") {
        this.image1 = event.target.files[0];
      } else if (title == "image2") {
        this.image2 = event.target.files[0];
      } else if (title == "image3") {
        this.image3 = event.target.files[0];
      } else if (title == "image4") {
        this.image4 = event.target.files[0];
      } else if (title == "image5") {
        this.image5 = event.target.files[0];
      } else if (title == "image1_ar") {
        this.image1_ar = event.target.files[0];
      } else if (title == "image2_ar") {
        this.image2 = event.target.files[0];
      } else if (title == "image3_ar") {
        this.image3_ar = event.target.files[0];
      } else if (title == "image4_ar") {
        this.image4_ar = event.target.files[0];
      } else if (title == "image5_ar") {
        this.image5_ar = event.target.files[0];
      }

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          if (title == "image1") {
            this.image1Data = e.target.result;
          } else if (title == "image2") {
            this.image2Data = e.target.result;
          } else if (title == "image3") {
            this.image3Data = e.target.result;
          } else if (title == "image4") {
            this.image4Data = e.target.result;
          } else if (title == "image5") {
            this.image5Data = e.target.result;
          } else if (title == "image1_ar") {
            this.image1Data_ar = e.target.result;
          } else if (title == "image2_ar") {
            this.image2Data_ar = e.target.result;
          } else if (title == "image3_ar") {
            this.image3Data_ar = e.target.result;
          } else if (title == "image4_ar") {
            this.image4Data_ar = e.target.result;
          } else if (title == "image5_ar") {
            this.image5Data_ar = e.target.result;
          }
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
        setErrors({ image1: null }); // Clear the image error
        setErrors({ image2: null }); // Clear the image error
        setErrors({ image3: null }); // Clear the image error
        setErrors({ image4: null }); // Clear the image error
        setErrors({ image5: null }); // Clear the image error
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
