import API from "../../api/api";

const state = () => ({
  subscribes: [],
  loading: false,
});

const mutations = {
  getSubscribes(state, payload) {
    state.subscribes = payload;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchSubscribe({ commit }) {
    await API.get("subscribe")
      .then(res => {
        commit("getSubscribes", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
