import API from "../../api/api";
import { authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  afterSalesService: null,
  loading: false,
});

const mutations = {
  getAfterSalesServiceDetail(state, payload) {
    state.afterSalesService = payload;
  },
  addAfterSalesService(state, payload) {
    state.afterSalesService = payload;
  },
  editAfterSalesService(state, payload) {
    // const index = state.models.findIndex((m) => m.id === payload.id);
    // if (index) {
    state.afterSalesService = payload;
    // }
  },
  setDetailInput: (state, e) => {
    let afterSalesService = state.afterSalesService;
    afterSalesService[e.target.name] = e.target.value;
    state.afterSalesService = afterSalesService;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchAfterSalesServiceDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`after-sales-service/${payload}`)
      .then(res => {
        commit("getAfterSalesServiceDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("after-sales-service", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addAfterSalesService", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    await API.put(`after-sales-service/1`, payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editAfterSalesService", res.data.data);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  afterSalesService: state => state.afterSalesService,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
