<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Feature Service Edit</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
          enctype="multipart/form-data"
          v-if="service"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Feature Service Title</label>
              <Field
                name="title"
                type="text"
                class="form-control"
                v-model="service.title"
                :class="{ 'is-invalid': errors.title }"
              />
              <div class="invalid-feedback">{{ errors.title }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1"
                >Arabic Feature Service Title</label
              >
              <Field
                name="title_ar"
                type="text"
                class="form-control"
                v-model="service.title_ar"
                :class="{ 'is-invalid': errors.title_ar }"
              />
              <div class="invalid-feedback">{{ errors.title_ar }}</div>
            </div>
          </div>
          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.fileInput.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
              @change="onFileChange"
              accept="image/*"
              style="display: none"
            />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length">{{ errors[0] }}</div>
          </Field>
          <div v-if="service && !imageData">
            <h3>Preview:</h3>
            <img
              :src="imageApi + service.image"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>
          <div v-if="imageData">
            <h3>Preview:</h3>
            <img
              :src="imageData"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
export default {
  name: "History Edit ",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      imageApi: imageApi,
      fileMain: null,
      imageData: null,
      schema: yup.object().shape({
        title: yup.string().trim().required("Title is required"),
        title_ar: yup.string().trim().required("Arabic Title is required"),
      }),
    };
  },
  computed: {
    ...mapGetters("featureServices", ["service", "isLoading"]),
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.id) {
      this.fetchDetail();
    }
  },
  mounted() {},
  methods: {
    updateInput() {
      this.$store.dispatch("featureServices/updateInput");
    },
    onFileChange(event) {
      const file = event.target.files[0];

      this.fileMain = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image: this.fileMain,
      };
      const id = this.id;

      let formData = new FormData();
      formData.append("image", this.$refs.fileInput.files[0]);
      formData.append("title", obj.title);
      formData.append("title_ar", obj.title_ar);
      this.$store
        .dispatch("featureServices/put", { id, data: formData })
        .then(res => {
          if (res == 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Feature Service has been Updated",
              showConfirmButton: false,
              timer: 2000,
            });
            actions.resetForm();
            this.$router.push("/feature-services");
          }
        })
        .catch(error => {
          // Handle any errors
          console.error("Error updating model:", error);
          this.$swal(
            "Error!",
            "There was an issue updating the model.",
            "error"
          );
        });
    },
    handleCancel() {
      this.$router.push("/feature-services");
    },
    fetchDetail() {
      try {
        this.$store.dispatch(
          "featureServices/fetchFeatureServiceDetail",
          this.id
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
