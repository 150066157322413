<template>
  <div class="card my-4 mx-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-content-center"
    >
      <h6>Multimedia</h6>
      <RouterLink to="/multimedia/add" class="btn btn-success"
        >Create</RouterLink
      >
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <data-table :header-list="headerList" :data-list="multimedia">
        <template v-slot:action="slotProps">
          <router-link
            :to="{ path: `/multimedia/edit/${slotProps.row.id}` }"
            class="btn btn-outline-dark px-2 py-1"
          >
            <i className="fa fa-pen"></i>
          </router-link>
          <button
            class="btn btn-outline-danger px-2 py-1 mx-1"
            @click="onDelete(slotProps.row.id)"
          >
            <i className="fa fa-trash"></i>
          </button>
        </template>
      </data-table>
    </div>
  </div>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import { RouterLink } from "vue-router";
import { mapGetters } from "vuex";
const headerList = [
  { name: "Sr.no", id: "id" },
  { name: "Title", id: "model.name" },
  { name: "Action", id: "action" },
];

export default {
  name: "Multimedia",
  components: {
    DataTable,
    RouterLink,
  },
  data() {
    return {
      headerList: headerList,
    };
  },
  computed: {
    ...mapGetters("multimedia", ["multimedia", "isLoading"]),
  },
  created() {
    this.$store.dispatch("multimedia/fetchMultimedia", null, {
      root: true,
    });
  },
  methods: {
    onDelete(id) {
      this.$swal({
        title: `Are you sure you want to delete Media ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("multimedia/delete", id);
          this.$swal("Deleted!", `Media has been deleted.`, "success");
        }
      });
    },
  },
};
</script>
