import API from "../../api/api";
import { authHeader, authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  multimedia: [],
  media: null,
  loading: false,
});

const mutations = {
  getMultimedia(state, payload) {
    state.multimedia = payload;
  },
  getMediaDetail(state, payload) {
    state.media = payload;
  },
  addMedia(state, payload) {
    state.multimedia.push(payload);
  },
  editMedia(state, payload) {
    const index = state.multimedia.findIndex(m => m.id === payload.id);
    if (index) {
      state.media = payload;
    }
  },
  deleteMedia(state, payload) {
    const filterArry = state.multimedia.filter(media => media.id != payload);
    state.multimedia = filterArry;
  },
  setDetailInput: (state, e) => {
    let media = state.media;
    media[e.target.name] = e.target.value;
    state.media = media;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchMultimedia({ commit }) {
    await API.get("multi-media")
      .then(res => {
        commit("getMultimedia", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async fetchMediaDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`multi-media/${payload}`)
      .then(res => {
        commit("getMediaDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    let multiMediaData;
    await API.post("multi-media", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addMedia", res.data.data);
        multiMediaData = res.status;
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return multiMediaData;
  },
  async put({ commit }, payload) {
    const data = payload.data;
    let multiMediaData;
    await API.put(`multi-media/${payload.id}`, data, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editMedia", payload);
          multiMediaData = res.status;
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return multiMediaData;
  },
  async delete({ commit }, payload) {
    await API.delete(`multi-media/${payload}`, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("deleteMedia", payload);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  media: state => state.media,
  multimedia: state => state.multimedia,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
