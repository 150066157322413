import API from "../../api/api";
import { authHeader, authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  histories: [],
  history: null,
  loading: false,
});

const mutations = {
  getHistories(state, payload) {
    state.histories = payload;
  },
  getHistoryDetail(state, payload) {
    state.history = payload;
  },
  addHistory(state, payload) {
    state.histories.push(payload);
  },
  editHistory(state, payload) {
    const index = state.histories.findIndex(m => m.id === payload.id);
    if (index) {
      state.history = payload;
    }
  },
  deleteHistory(state, payload) {
    const filterArry = state.histories.filter(history => history.id != payload);
    state.histories = filterArry;
  },

  setDetailInput: (state, e) => {
    let history = state.history;
    history[e.target.name] = e.target.value;
    state.history = history;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchHistories({ commit }) {
    await API.get("history")
      .then(res => {
        commit("getHistories", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async fetchHistoryDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`history/${payload}`)
      .then(res => {
        commit("getHistoryDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("history", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addHistory", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    const data = payload.data;
    let historyData;
    await API.put(`history/${payload.id}`, data, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editHistory", payload);
          historyData = res.status;
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return historyData;
  },

  async delete({ commit }, payload) {
    await API.delete(`history/${payload}`, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("deleteHistory", payload);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },

  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  history: state => state.history,
  histories: state => state.histories,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
