<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Vision Edit</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
          enctype="multipart/form-data"
          v-if="vision"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Title</label>
              <Field
                name="title"
                type="text"
                class="form-control"
                v-model="vision.title"
                :class="{ 'is-invalid': errors.title }"
              />
              <div class="invalid-feedback">{{ errors.title }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Title</label>
              <Field
                name="title_ar"
                type="text"
                class="form-control"
                v-model="vision.title_ar"
                :class="{ 'is-invalid': errors.title_ar }"
              />
              <div class="invalid-feedback">{{ errors.title_ar }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Sub Title</label>
              <Field
                name="subTitle"
                type="text"
                class="form-control"
                v-model="vision.subTitle"
                :class="{ 'is-invalid': errors.subTitle }"
              />
              <div class="invalid-feedback">{{ errors.subTitle }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Sub Title</label>
              <Field
                name="subTitle_ar"
                type="text"
                class="form-control"
                v-model="vision.subTitle_ar"
                :class="{ 'is-invalid': errors.subTitle_ar }"
              />
              <div class="invalid-feedback">{{ errors.subTitle_ar }}</div>
            </div>
          </div>

          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.fileInput.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
              @change="onFileChange"
              accept="image/*"
              style="display: none"
            />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length">{{ errors[0] }}</div>
          </Field>
          <div v-if="vision && !imageData">
            <h3>Preview:</h3>
            <img
              :src="imageApi + vision.image"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>
          <div v-if="imageData">
            <h3>Preview:</h3>
            <img
              :src="imageData"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";

export default {
  name: "News Releases Add",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();

    return { setFieldValue };
  },
  data() {
    return {
      imageApi: imageApi,
      fileMain: null,
      imageData: null,
      schema: yup.object().shape({
        title: yup.string().trim().required("Title is required"),
        subTitle: yup.string().trim().required("Sub Title is required"),
        title_ar: yup.string().trim().required("Arabic Title is required"),
        subTitle_ar: yup
          .string()
          .trim()
          .required("Arabic Sub Title is required"),
      }),
    };
  },

  computed: {
    ...mapGetters("vision", ["vision", "isLoading"]),
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.id) {
      this.fetchDetail();
    }
  },
  mounted() {},
  watch: {},

  methods: {
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image: this.fileMain,
      };

      let formData = new FormData();
      formData.append("image", this.$refs.fileInput.files[0]);
      formData.append("title", obj.title);
      formData.append("subTitle", obj.subTitle);
      formData.append("title_ar", obj.title_ar);
      formData.append("subTitle_ar", obj.subTitle_ar);

      this.$store
        .dispatch("vision/put", { id: this.id, data: formData })
        .then(res => {
          if (res == 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Vision has been Updated",
              showConfirmButton: false,
              timer: 2000,
            });
            actions.resetForm();
            this.$router.push("/vision");
          }
        })
        .catch(error => {
          // Handle any errors
          console.error("Error updating model:", error);
          this.$swal(
            "Error!",
            "There was an issue updating the model.",
            "error"
          );
        });
    },
    handleCancel() {
      this.$router.push("/vision");
    },
    onFileChange(event) {
      const file = event.target.files[0];

      this.fileMain = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    fetchDetail() {
      try {
        this.$store.dispatch("vision/fetchVisionDetail", this.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
