import API from "../../api/api";
import { authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  overviewObj: null,
  loading: false,
});

const mutations = {
  getOverview(state, payload) {
    state.overviewObj = payload;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
  addOverview(state, payload) {
    state.overviewObj = payload;
  },
  editOverview(state, payload) {
    state.overviewObj = payload;
  },
  setDetailInput: (state, e) => {
    let overviewObj = state.overviewObj;
    overviewObj[e.target.name] = e.target.value;
    state.overviewObj = overviewObj;
  },
};

const actions = {
  async fetchOverviewDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`overview/${payload}`)
      .then(res => {
        commit("getOverview", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("overview", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addOverview", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    // const id = payload.id;
    await API.put(`overview/1`, payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editOverview", res.data.data);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  overviewObj: state => state.overviewObj,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
