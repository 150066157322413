<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit Concept Car CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          class="form"
          @submit="onSubmit"
          :initial-values="initialData"
          :validation-schema="schema"
          v-slot="{ errors }"
          v-if="conceptCarObj"
        >
          <div>
            <h4>Concept Car Banner Image</h4>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.banner_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Banner Image Upload
              </button>
              <input
                type="file"
                ref="banner_imageRef"
                @change="event => onFileChange(event, 'bannerImage')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="bannerImageData">
                <h6>Preview Concept Car Banner Image:</h6>
                <img
                  :src="bannerImageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !bannerImageData">
                <h6>Preview Concept Car Banner Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.bannerImage"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 1</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_1_title">Concept Car Section 1 Title</label>
                <Field
                  name="section_1_title"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_1_title"
                  :class="{ 'is-invalid': errors.section_1_title }"
                />

                <div class="invalid-feedback">{{ errors.section_1_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 1 Title</label>
                <Field
                  name="section_1_title_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_1_title_ar"
                  :class="{ 'is-invalid': errors.section_1_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_1_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 1 Description</label>
                <Field
                  as="textarea"
                  name="section_1_description"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_1_description"
                  :class="{ 'is-invalid': errors.section_1_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_1_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 1 Description 1</label>
                <Field
                  as="textarea"
                  name="section_1_description_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_1_description_ar"
                  :class="{
                    'is-invalid': errors.section_1_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_1_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_1_image.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 1 Image Upload
              </button>
              <input
                type="file"
                ref="section_1_image"
                @change="event => onFileChange(event, 'section_1_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_1_imageData">
                <h6>Preview Concept Car Section 1 Image:</h6>
                <img
                  :src="section_1_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_1_imageData">
                <h6>Preview Concept Car Section 1 Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_1_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 2</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 2 Description</label>
                <Field
                  as="textarea"
                  name="section_2_description"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_2_description"
                  :class="{ 'is-invalid': errors.section_2_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_2_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 2 Description 1</label>
                <Field
                  as="textarea"
                  name="section_2_description_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_2_description_ar"
                  :class="{
                    'is-invalid': errors.section_2_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_2_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_2_image.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 2 Image Upload
              </button>
              <input
                type="file"
                ref="section_2_image"
                @change="event => onFileChange(event, 'section_2_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_2_imageData">
                <h6>Preview Concept Car Section 1 Image:</h6>
                <img
                  :src="section_2_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_2_imageData">
                <h6>Preview Concept Car Section 2 Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_2_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 3</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_3_title">Concept Car Section 3 Title</label>
                <Field
                  name="section_3_title"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_3_title"
                  :class="{ 'is-invalid': errors.section_3_title }"
                />

                <div class="invalid-feedback">{{ errors.section_3_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 3 Title</label>
                <Field
                  name="section_3_title_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_3_title_ar"
                  :class="{ 'is-invalid': errors.section_3_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_3_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_3_image.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 3 Image Upload
              </button>
              <input
                type="file"
                ref="section_3_image"
                @change="event => onFileChange(event, 'section_3_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_3_imageData">
                <h6>Preview Concept Car Section 3 Image:</h6>
                <img
                  :src="section_3_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_3_imageData">
                <h6>Preview Concept Car Section 3 Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_3_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 4</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_4_title1"
                  >Concept Car Section 4 Title 1</label
                >
                <Field
                  name="section_4_title1"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_title1"
                  :class="{ 'is-invalid': errors.section_4_title1 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_title1 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 4 Title 1</label>
                <Field
                  name="section_4_title1_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_title1_ar"
                  :class="{ 'is-invalid': errors.section_4_title1_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_title1_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 4 Description 1</label>
                <Field
                  as="textarea"
                  name="section_4_description1"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_description1"
                  :class="{ 'is-invalid': errors.section_4_description1 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_description1 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 4 Description 1</label>
                <Field
                  as="textarea"
                  name="section_4_description1_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_description1_ar"
                  :class="{
                    'is-invalid': errors.section_4_description1_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_description1_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_4_image1.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 4 Image 1 Upload
              </button>
              <input
                type="file"
                ref="section_4_image1"
                @change="event => onFileChange(event, 'section_4_image1')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_4_image1Data">
                <h6>Preview Concept Car Section 4 Image 1:</h6>
                <img
                  :src="section_4_image1Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_4_image1Data">
                <h6>Preview Concept Car Section 4 Image 1:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_4_image1"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_4_title2"
                  >Concept Car Section 4 Title 2</label
                >
                <Field
                  name="section_4_title2"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_title2"
                  :class="{ 'is-invalid': errors.section_4_title2 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_title2 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 4 Title 2</label>
                <Field
                  name="section_4_title2_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_title2_ar"
                  :class="{ 'is-invalid': errors.section_4_title2_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_title2_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 4 Description 2</label>
                <Field
                  as="textarea"
                  name="section_4_description2"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_description2"
                  :class="{ 'is-invalid': errors.section_4_description2 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_description2 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 4 Description 2</label>
                <Field
                  as="textarea"
                  name="section_4_description2_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_description2_ar"
                  :class="{
                    'is-invalid': errors.section_4_description2_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_description2_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_4_image2.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 4 Image 2 Upload
              </button>
              <input
                type="file"
                ref="section_4_image2"
                @change="event => onFileChange(event, 'section_4_image2')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_4_image2Data">
                <h6>Preview Concept Car Section 4 Image 2:</h6>
                <img
                  :src="section_4_image2Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_4_image2Data">
                <h6>Preview Concept Car Section 4 Image 2:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_4_image2"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_4_title3"
                  >Concept Car Section 4 Title 3</label
                >
                <Field
                  name="section_4_title3"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_title3"
                  :class="{ 'is-invalid': errors.section_4_title3 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_title3 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 4 Title 3</label>
                <Field
                  name="section_4_title3_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_title3_ar"
                  :class="{ 'is-invalid': errors.section_4_title3_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_title3_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 4 Description 3</label>
                <Field
                  as="textarea"
                  name="section_4_description3"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_description3"
                  :class="{ 'is-invalid': errors.section_4_description3 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_description3 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 4 Description 3</label>
                <Field
                  as="textarea"
                  name="section_4_description3_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_description3_ar"
                  :class="{
                    'is-invalid': errors.section_4_description3_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_description3_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_4_image3.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 4 Image 3 Upload
              </button>
              <input
                type="file"
                ref="section_4_image3"
                @change="event => onFileChange(event, 'section_4_image3')"
                accept="image/*"
                style="display: none"
              />

              <div v-if="section_4_image3Data">
                <h6>Preview Concept Car Section 4 Image 3:</h6>
                <img
                  :src="section_4_image3Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_4_image3Data">
                <h6>Preview Concept Car Section 4 Image 3:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_4_image3"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 4 Main Description</label>
                <Field
                  as="textarea"
                  name="section_4_main_description"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_main_description"
                  :class="{ 'is-invalid': errors.section_4_main_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_main_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Concept Car Arabic Section 4 Main Description
                </label>
                <Field
                  as="textarea"
                  name="section_4_main_description_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_4_main_description_ar"
                  :class="{
                    'is-invalid': errors.section_4_main_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_4_main_description_ar }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 5</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 5 Description</label>
                <Field
                  as="textarea"
                  name="section_5_description"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_5_description"
                  :class="{ 'is-invalid': errors.section_5_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_5_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 5 Description </label>
                <Field
                  as="textarea"
                  name="section_5_description_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_5_description_ar"
                  :class="{
                    'is-invalid': errors.section_5_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_5_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_5_image.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 5 Image Upload
              </button>
              <input
                type="file"
                ref="section_5_image"
                @change="event => onFileChange(event, 'section_5_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_5_imageData">
                <h6>Preview Concept Car Section 1 Image:</h6>
                <img
                  :src="section_5_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_5_imageData">
                <h6>Preview Concept Car Section 5 Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_5_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 6</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 6 Description</label>
                <Field
                  as="textarea"
                  name="section_6_description"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_6_description"
                  :class="{ 'is-invalid': errors.section_6_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_6_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 6 Description </label>
                <Field
                  as="textarea"
                  name="section_6_description_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_6_description_ar"
                  :class="{
                    'is-invalid': errors.section_6_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_6_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_6_image.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 6 Image Upload
              </button>
              <input
                type="file"
                ref="section_6_image"
                @change="event => onFileChange(event, 'section_6_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_6_imageData">
                <h6>Preview Concept Car Section 6 Image:</h6>
                <img
                  :src="section_6_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_6_imageData">
                <h6>Preview Concept Car Section 6 Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_6_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 7</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_7_title">Concept Car Section 7 Title</label>
                <Field
                  name="section_7_title"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_7_title"
                  :class="{ 'is-invalid': errors.section_7_title }"
                />

                <div class="invalid-feedback">{{ errors.section_7_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 7 Title</label>
                <Field
                  name="section_7_title_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_7_title_ar"
                  :class="{ 'is-invalid': errors.section_7_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_7_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 7 Description</label>
                <Field
                  as="textarea"
                  name="section_7_description"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_7_description"
                  :class="{ 'is-invalid': errors.section_7_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_7_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 7 Description </label>
                <Field
                  as="textarea"
                  name="section_7_description_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_7_description_ar"
                  :class="{
                    'is-invalid': errors.section_7_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_7_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_7_image.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 7 Image Upload
              </button>
              <input
                type="file"
                ref="section_7_image"
                @change="event => onFileChange(event, 'section_7_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_7_imageData">
                <h6>Preview Concept Car Section 7 Image:</h6>
                <img
                  :src="section_7_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_7_imageData">
                <h6>Preview Concept Car Section 7 Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_7_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 8</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Concept Car Section 8 Description</label>
                <Field
                  as="textarea"
                  name="section_8_description"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_8_description"
                  :class="{ 'is-invalid': errors.section_8_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_8_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Concept Car Arabic Section 8 Description </label>
                <Field
                  as="textarea"
                  name="section_8_description_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_8_description_ar"
                  :class="{
                    'is-invalid': errors.section_8_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_8_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_8_image.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 8 Image Upload
              </button>
              <input
                type="file"
                ref="section_8_image"
                @change="event => onFileChange(event, 'section_8_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_8_imageData">
                <h6>Preview Concept Car Section 8 Image:</h6>
                <img
                  :src="section_8_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_8_imageData">
                <h6>Preview Concept Car Section 8 Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_8_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 9</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_9_title">Concept Car Section 9 Title</label>
                <Field
                  name="section_9_title"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_9_title"
                  :class="{ 'is-invalid': errors.section_9_title }"
                />

                <div class="invalid-feedback">{{ errors.section_9_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 9 Title</label>
                <Field
                  name="section_9_title_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_9_title_ar"
                  :class="{ 'is-invalid': errors.section_9_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_9_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_9_image.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 9 Image Upload
              </button>
              <input
                type="file"
                ref="section_9_image"
                @change="event => onFileChange(event, 'section_9_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_9_imageData">
                <h6>Preview Concept Car Section 9 Image:</h6>
                <img
                  :src="section_9_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_9_imageData">
                <h6>Preview Concept Car Section 9 Image:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_9_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Concept Car Section 10</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_10_title1"
                  >Concept Car Section 10 Title 1</label
                >
                <Field
                  name="section_10_title1"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_10_title1"
                  :class="{ 'is-invalid': errors.section_10_title1 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_10_title1 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 10 Title 1</label>
                <Field
                  name="section_10_title1_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_10_title1_ar"
                  :class="{ 'is-invalid': errors.section_10_title1_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_10_title1_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_10_image1.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 10 Image 1 Upload
              </button>
              <input
                type="file"
                ref="section_10_image1"
                @change="event => onFileChange(event, 'section_10_image1')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_10_image1Data">
                <h6>Preview Concept Car Section 10 Image 1:</h6>
                <img
                  :src="section_10_image1Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_10_image1Data">
                <h6>Preview Concept Car Section 10 Image 1:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_10_image1"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_10_title2"
                  >Concept Car Section 10 Title 2</label
                >
                <Field
                  name="section_10_title2"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_10_title2"
                  :class="{ 'is-invalid': errors.section_10_title2 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_10_title2 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 10 Title 2</label>
                <Field
                  name="section_10_title2_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_10_title2_ar"
                  :class="{ 'is-invalid': errors.section_10_title2_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_10_title2_ar }}
                </div>
              </div>
            </div>

            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_10_image2.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 10 Image 2 Upload
              </button>
              <input
                type="file"
                ref="section_10_image2"
                @change="event => onFileChange(event, 'section_10_image2')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_10_image2Data">
                <h6>Preview Concept Car Section 10 Image 2:</h6>
                <img
                  :src="section_10_image2Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_10_image2Data">
                <h6>Preview Concept Car Section 10 Image 2:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_10_image2"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_10_title3"
                  >Concept Car Section 10 Title 3</label
                >
                <Field
                  name="section_10_title3"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_10_title3"
                  :class="{ 'is-invalid': errors.section_10_title3 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_10_title3 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 10 Title 3</label>
                <Field
                  name="section_10_title3_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_10_title3_ar"
                  :class="{ 'is-invalid': errors.section_10_title3_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_10_title3_ar }}
                </div>
              </div>
            </div>

            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_10_image3.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 10 Image 3 Upload
              </button>
              <input
                type="file"
                ref="section_10_image3"
                @change="event => onFileChange(event, 'section_10_image3')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_10_image3Data">
                <h6>Preview Concept Car Section 10 Image 3:</h6>
                <img
                  :src="section_10_image3Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_10_image3Data">
                <h6>Preview Concept Car Section 10 Image 3:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_10_image3"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="section_10_title4"
                  >Concept Car Section 10 Title 4</label
                >
                <Field
                  name="section_10_title4"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_10_title4"
                  :class="{ 'is-invalid': errors.section_10_title4 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_10_title4 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Concept Car Section 10 Title 4</label>
                <Field
                  name="section_10_title4_ar"
                  type="text"
                  class="form-control"
                  v-model="conceptCarObj.section_10_title4_ar"
                  :class="{ 'is-invalid': errors.section_10_title4_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section_10_title4_ar }}
                </div>
              </div>
            </div>

            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section_10_image4.click()"
                class="btn btn-outline-danger"
              >
                Concept Car Section 10 Image 4 Upload
              </button>
              <input
                type="file"
                ref="section_10_image4"
                @change="event => onFileChange(event, 'section_10_image4')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section_10_image4Data">
                <h6>Preview Concept Car Section 10 Image 4:</h6>
                <img
                  :src="section_10_image4Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="conceptCarObj && !section_10_image4Data">
                <h6>Preview Concept Car Section 10 Image 4:</h6>
                <img
                  :src="imageApi + conceptCarObj.section_10_image4"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-success">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
import _ from "lodash";
export default {
  name: "Concept Car",
  components: {
    Field,
    Form,
    // quillEditor,
  },
  data() {
    const { setFieldValue } = useForm();
    const schema = yup.object().shape({
      section_1_title: yup.string().required("Section Title 1 is Required"),
      section_1_title_ar: yup
        .string()
        .trim()
        .required("Arabic Section 1 Title is Required"),
      section_1_description: yup
        .string()
        .trim()
        .required("Section 1 Description is Required"),
      section_1_description_ar: yup
        .string()
        .trim()
        .required("Arabic Section 1 Description is Required"),
      section_2_description: yup
        .string()
        .trim()
        .required("Section 2 Description is Required"),
      section_2_description_ar: yup
        .string()
        .trim()
        .required("Arabic Section 2 Description is Required"),
      section_3_title: yup.string().required("Section 3 Title is Required"),
      section_3_title_ar: yup
        .string()
        .trim()
        .required("Arabic Section 3 Title is Required"),
      section_4_title1: yup.string().required("Section 4 Title is Required"),
      section_4_title1_ar: yup
        .string()
        .trim()
        .required("Arabic Section 4 Title is Required"),
      section_4_description1: yup
        .string()
        .trim()
        .required("Section 4 Description is Required"),
      section_4_description1_ar: yup
        .string()
        .trim()
        .required("Arabic Section 4 Description is Required"),
      section_4_title2: yup.string().required("Section 4 Title 2 is Required"),
      section_4_title2_ar: yup
        .string()
        .trim()
        .required("Arabic Section 4 Title 2 is Required"),
      section_4_description2: yup
        .string()
        .trim()
        .required("Section 4 Description 2 is Required"),
      section_4_description2_ar: yup
        .string()
        .trim()
        .required("Arabic Section 4 Description 2  is Required"),
      section_4_title3: yup.string().required("Section 4 Title 3 is Required"),
      section_4_title3_ar: yup
        .string()
        .trim()
        .required("Arabic Section 4 Title 3 is Required"),
      section_4_description3: yup
        .string()
        .trim()
        .required("Section 4 Description 3 is Required"),
      section_4_description3_ar: yup
        .string()
        .trim()
        .required("Arabic Section 4 Description 3 is Required"),
      section_4_main_description: yup
        .string()
        .trim()
        .required("Section 4 Main Description is Required"),
      section_4_main_description_ar: yup
        .string()
        .trim()
        .required("Arabic Section 4 Main Description is Required"),
      section_5_description: yup
        .string()
        .trim()
        .required("Section 5 Description is Required"),
      section_5_description_ar: yup
        .string()
        .trim()
        .required("Arabic Section 5 Description is Required"),
      section_6_description: yup
        .string()
        .trim()
        .required("Section 6 Description is Required"),
      section_6_description_ar: yup
        .string()
        .trim()
        .required("Arabic Section 6 Description is Required"),
      section_7_title: yup.string().required("Section 7 Title is Required"),
      section_7_title_ar: yup
        .string()
        .trim()
        .required("Arabic Section 7 Title is Required"),
      section_7_description: yup
        .string()
        .trim()
        .required("Section 7 Description is Required"),
      section_7_description_ar: yup
        .string()
        .trim()
        .required("Arabic Section 7 Description is Required"),
      section_8_description: yup
        .string()
        .trim()
        .required("Section 8 Description is Required"),
      section_8_description_ar: yup
        .string()
        .trim()
        .required("Arabic Section 8 Description is Required"),
      section_9_title: yup.string().required("Section 9 Title is Required"),
      section_9_title_ar: yup
        .string()
        .trim()
        .required("Arabic Section 9 Title is Required"),
      section_10_title1: yup.string().required("Section 10 Title is Required"),
      section_10_title1_ar: yup
        .string()
        .trim()
        .required("Arabic Section 10 Title is Required"),
      section_10_title2: yup
        .string()
        .trim()
        .required("Section 10 Title 2 is Required"),
      section_10_title2_ar: yup
        .string()
        .trim()
        .required("Arabic Section 10 Title 2 is Required"),
      section_10_title3: yup
        .string()
        .trim()
        .required("Section 10 Title 3 is Required"),
      section_10_title3_ar: yup
        .string()
        .trim()
        .required("Arabic Section 10 Title 3 is Required"),
      section_10_title4: yup
        .string()
        .trim()
        .required("Section 10 Title 4 is Required"),
      section_10_title4_ar: yup
        .string()
        .trim()
        .required("Arabic Section 10 Title 4 is Required"),
    });

    const initialData = {
      section_1_title: "",
      section_1_title_ar: "",
      section_1_description: "",
      section_1_description_ar: "",
      section_2_description: "",
      section_2_description_ar: "",
      section_3_title: "",
      section_3_title_ar: "",
      section_4_title1: "",
      section_4_title1_ar: "",
      section_4_description1: "",
      section_4_description1_ar: "",
      section_4_title2: "",
      section_4_title2_ar: "",
      section_4_description2: "",
      section_4_description2_ar: "",
      section_4_title3: "",
      section_4_title3_ar: "",
      section_4_description3: "",
      section_4_description3_ar: "",
      section_4_main_description: "",
      section_4_main_description_ar: "",
      section_5_description: "",
      section_5_description_ar: "",
      section_6_description: "",
      section_6_description_ar: "",
      section_7_title: "",
      section_7_title_ar: "",
      section_7_description: "",
      section_7_description_ar: "",
      section_8_description: "",
      section_8_description_ar: "",
      section_9_title: "",
      section_9_title_ar: "",
      section_10_title1: "",
      section_10_title1_ar: "",
      section_10_title2: "",
      section_10_title2_ar: "",
      section_10_title3: "",
      section_10_title3_ar: "",
      section_10_title4: "",
      section_10_title4_ar: "",
    };

    return {
      imageApi: imageApi,
      schema,
      initialData,
      setFieldValue,
      bannerImage: null,
      section_1_image: null,
      section_2_image: null,
      section_3_image: null,
      section_4_image1: null,
      section_4_image2: null,
      section_4_image3: null,
      section_5_image: null,
      section_6_image: null,
      section_7_image: null,
      section_8_image: null,
      section_9_image: null,
      section_10_image1: null,
      section_10_image2: null,
      section_10_image3: null,
      section_10_image4: null,
      bannerImageData: null,
      section_1_imageData: null,
      section_2_imageData: null,
      section_3_imageData: null,
      section_4_image1Data: null,
      section_4_image2Data: null,
      section_4_image3Data: null,
      section_5_imageData: null,
      section_6_imageData: null,
      section_7_imageData: null,
      section_8_imageData: null,
      section_9_imageData: null,
      section_10_image1Data: null,
      section_10_image2Data: null,
      section_10_image3Data: null,
      section_10_image4Data: null,
    };
  },
  computed: {
    ...mapGetters("conceptCar", ["conceptCarObj", "isLoading"]),
  },
  mounted() {
    this.fetchDetail();

    let initData = this.conceptCarObj ? _.clone(this.conceptCarObj) : {};

    this.initialData = this.conceptCarObj
      ? {
          ...initData,
        }
      : this.initialData;
  },
  methods: {
    onChange(event) {
      console.log("EVENT", event);

      this.description = event.html;
    },

    fetchDetail() {
      try {
        this.$store.dispatch("conceptCar/fetchConceptCarDetail", 1);
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      const obj = {
        ...values,
        bannerImage: this.bannerImage,
        section_1_image: this.section_1_image,
        section_2_image: this.section_2_image,
        section_3_image: this.section_3_image,
        section_4_image1: this.section_4_image1,
        section_4_image2: this.section_4_image2,
        section_4_image3: this.section_4_image3,
        section_5_image: this.section_5_image,
        section_6_image: this.section_6_image,
        section_7_image: this.section_7_image,
        section_8_image: this.section_8_image,
        section_9_image: this.section_9_image,
        section_10_image1: this.section_10_image1,
        section_10_image2: this.section_10_image2,
        section_10_image3: this.section_10_image3,
        section_10_image4: this.section_10_image4,
      };
      console.log("VALUES", values);

      let formData = new FormData();

      formData.append("section_1_title", obj.section_1_title);
      formData.append("section_1_title_ar", obj.section_1_title_ar);
      formData.append("section_1_description", obj.section_1_description);
      formData.append("section_1_description_ar", obj.section_1_description_ar);
      formData.append("section_2_description", obj.section_2_description);
      formData.append("section_2_description_ar", obj.section_2_description_ar);
      formData.append("section_3_title", obj.section_3_title);
      formData.append("section_3_title_ar", obj.section_3_title_ar);
      formData.append("section_4_title1", obj.section_4_title1);
      formData.append("section_4_title1_ar", obj.section_4_title1_ar);
      formData.append("section_4_description1", obj.section_4_description1);
      formData.append(
        "section_4_description1_ar",
        obj.section_4_description1_ar
      );
      formData.append("section_4_title2", obj.section_4_title2);
      formData.append("section_4_title2_ar", obj.section_4_title2_ar);
      formData.append("section_4_description2", obj.section_4_description2);
      formData.append(
        "section_4_description2_ar",
        obj.section_4_description2_ar
      );
      formData.append("section_4_title3", obj.section_4_title3);
      formData.append("section_4_title3_ar", obj.section_4_title3_ar);
      formData.append("section_4_description3", obj.section_4_description3);
      formData.append(
        "section_4_description3_ar",
        obj.section_4_description3_ar
      );
      formData.append(
        "section_4_main_description",
        obj.section_4_main_description
      );
      formData.append(
        "section_4_main_description_ar",
        obj.section_4_main_description_ar
      );
      formData.append("section_5_description", obj.section_5_description);
      formData.append("section_5_description_ar", obj.section_5_description_ar);
      formData.append("section_6_description", obj.section_6_description);
      formData.append("section_6_description_ar", obj.section_6_description_ar);
      formData.append("section_7_title", obj.section_7_title);
      formData.append("section_7_title_ar", obj.section_7_title_ar);
      formData.append("section_7_description", obj.section_7_description);
      formData.append("section_7_description_ar", obj.section_7_description_ar);
      formData.append("section_8_description", obj.section_8_description);
      formData.append("section_8_description_ar", obj.section_8_description_ar);
      formData.append("section_9_title", obj.section_9_title);
      formData.append("section_9_title_ar", obj.section_9_title_ar);
      formData.append("section_10_title1", obj.section_10_title1);
      formData.append("section_10_title1_ar", obj.section_10_title1_ar);
      formData.append("section_10_title2", obj.section_10_title2);
      formData.append("section_10_title2_ar", obj.section_10_title2_ar);
      formData.append("section_10_title3", obj.section_10_title3);
      formData.append("section_10_title3_ar", obj.section_10_title3_ar);
      formData.append("section_10_title4", obj.section_10_title4);
      formData.append("section_10_title4_ar", obj.section_10_title4_ar);
      formData.append("bannerImage", obj.bannerImage);
      formData.append("section_1_image", obj.section_1_image);
      formData.append("section_2_image", obj.section_2_image);
      formData.append("section_3_image", obj.section_3_image);
      formData.append("section_4_image1", obj.section_4_image1);
      formData.append("section_4_image2", obj.section_4_image2);
      formData.append("section_4_image3", obj.section_4_image3);
      formData.append("section_5_image", obj.section_5_image);
      formData.append("section_6_image", obj.section_6_image);
      formData.append("section_7_image", obj.section_7_image);
      formData.append("section_8_image", obj.section_8_image);
      formData.append("section_9_image", obj.section_9_image);
      formData.append("section_10_image1", obj.section_10_image1);
      formData.append("section_10_image2", obj.section_10_image2);
      formData.append("section_10_image3", obj.section_10_image3);
      formData.append("section_10_image4", obj.section_10_image4);

      this.$store.dispatch("conceptCar/put", formData);

      this.$swal({
        position: "center",
        icon: "success",
        title: "Concept Car Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });

      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },
    onFileChange(event, title) {
      const file = event.target.files[0];

      if (title == "section_1_image") {
        this.section_1_image = event.target.files[0];
      } else if (title == "bannerImage") {
        this.bannerImage = event.target.files[0];
      } else if (title == "section_2_image") {
        this.section_2_image = event.target.files[0];
      } else if (title == "section_3_image") {
        this.section_3_image = event.target.files[0];
      } else if (title == "section_4_image1") {
        this.section_4_image1 = event.target.files[0];
      } else if (title == "section_4_image2") {
        this.section_4_image2 = event.target.files[0];
      } else if (title == "section_4_image3") {
        this.section_4_image3 = event.target.files[0];
      } else if (title == "section_5_image") {
        this.section_5_image = event.target.files[0];
      } else if (title == "section_6_image") {
        this.section_6_image = event.target.files[0];
      } else if (title == "section_7_image") {
        this.section_7_image = event.target.files[0];
      } else if (title == "section_8_image") {
        this.section_8_image = event.target.files[0];
      } else if (title == "section_9_image") {
        this.section_9_image = event.target.files[0];
      } else if (title == "section_10_image1") {
        this.section_10_image1 = event.target.files[0];
      } else if (title == "section_10_image2") {
        this.section_10_image2 = event.target.files[0];
      } else if (title == "section_10_image3") {
        this.section_10_image3 = event.target.files[0];
      } else if (title == "section_10_image4") {
        this.section_10_image4 = event.target.files[0];
      }

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          //   this.bannerImageData = e.target.result;
          if (title == "section_1_image") {
            this.section_1_imageData = e.target.result;
          } else if (title == "bannerImage") {
            this.bannerImageData = e.target.result;
          } else if (title == "section_2_image") {
            this.section_2_imageData = e.target.result;
          } else if (title == "section_3_image") {
            this.section_3_imageData = e.target.result;
          } else if (title == "section_4_image1") {
            this.section_4_image1Data = e.target.result;
          } else if (title == "section_4_image2") {
            this.section_4_image2Data = e.target.result;
          } else if (title == "section_4_image3") {
            this.section_4_image3Data = e.target.result;
          } else if (title == "section_5_image") {
            this.section_5_imageData = e.target.result;
          } else if (title == "section_6_image") {
            this.section_6_imageData = e.target.result;
          } else if (title == "section_7_image") {
            this.section_7_imageData = e.target.result;
          } else if (title == "section_8_image") {
            this.section_8_imageData = e.target.result;
          } else if (title == "section_9_image") {
            this.section_9_imageData = e.target.result;
          } else if (title == "section_10_image1") {
            this.section_10_image1Data = e.target.result;
          } else if (title == "section_10_image2") {
            this.section_10_image2Data = e.target.result;
          } else if (title == "section_10_image3") {
            this.section_10_image3Data = e.target.result;
          } else if (title == "section_10_image4") {
            this.section_10_image4Data = e.target.result;
          }
        };

        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
