<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit Research & Development CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          class="form"
          @submit="onSubmit"
          :initial-values="initialData"
          :validation-schema="schema"
          enctype="multipart/form-data"
          v-if="researchDevelopemntObj"
          v-slot="{ errors }"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="">Research & Development Title</label>
              <Field
                name="banner_title"
                type="text"
                class="form-control"
                v-model="researchDevelopemntObj.banner_title"
                :class="{ 'is-invalid': errors.banner_title }"
              />

              <div class="invalid-feedback">{{ errors.banner_title }}</div>
            </div>
            <div class="col-md-6">
              <label for="">Research & Development Arabic Title</label>
              <Field
                name="banner_title_ar"
                type="text"
                class="form-control"
                v-model="researchDevelopemntObj.banner_title_ar"
                :class="{ 'is-invalid': errors.banner_title_ar }"
              />

              <div class="invalid-feedback">{{ errors.banner_title_ar }}</div>
            </div>
          </div>
          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.banner_imageRef.click()"
              class="btn btn-outline-danger"
            >
              Research & Development Banner Image Upload
            </button>
            <input
              type="file"
              ref="banner_imageRef"
              @change="event => onFileChange(event, 'banner_image')"
              accept="image/*"
              style="display: none"
            />
            <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
            <div v-if="banner_imageData">
              <h6>Preview Research & Development Banner Image:</h6>
              <img
                :src="banner_imageData"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
            <div v-if="researchDevelopemntObj && !banner_imageData">
              <h6>Preview Banner Image:</h6>
              <img
                :src="imageApi + researchDevelopemntObj.banner_image"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
          </div>

          <div>
            <h4>Research & Development Section 1</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 1 Title</label>
                <Field
                  name="section1_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section1_title"
                  :class="{ 'is-invalid': errors.section1_title }"
                />

                <div class="invalid-feedback">{{ errors.section1_title }}</div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 1 Title</label
                >
                <Field
                  name="section1_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section1_title_ar"
                  :class="{ 'is-invalid': errors.section1_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section1_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 1 Short Description 1</label
                >
                <Field
                  as="textarea"
                  name="section1_short_description_1"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section1_short_description_1"
                  :class="{ 'is-invalid': errors.section1_short_description_1 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section1_short_description_1 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 1 Short Description
                  1</label
                >
                <Field
                  as="textarea"
                  name="section1_short_description_1_ar"
                  type="text"
                  class="form-control"
                  v-model="
                    researchDevelopemntObj.section1_short_description_1_ar
                  "
                  :class="{
                    'is-invalid': errors.section1_short_description_1_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section1_short_description_1_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 1 Short Description 2</label
                >
                <Field
                  as="textarea"
                  name="section1_short_description_2"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section1_short_description_2"
                  :class="{ 'is-invalid': errors.section1_short_description_2 }"
                />

                <div class="invalid-feedback">
                  {{ errors.section1_short_description_2 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 1 Short Description
                  2</label
                >
                <Field
                  as="textarea"
                  name="section1_short_description_2_ar"
                  type="text"
                  class="form-control"
                  v-model="
                    researchDevelopemntObj.section1_short_description_2_ar
                  "
                  :class="{
                    'is-invalid': errors.section1_short_description_2_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section1_short_description_2_ar }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 2</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 2 Title</label>
                <Field
                  name="section2_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section2_title"
                  :class="{ 'is-invalid': errors.section2_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section2_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 2 Title</label
                >
                <Field
                  name="section2_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section2_title_ar"
                  :class="{ 'is-invalid': errors.section2_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section2_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 2 Description</label
                >
                <Field
                  as="textarea"
                  name="section2_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section2_description"
                  :class="{ 'is-invalid': errors.section2_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section2_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 2 Description</label
                >
                <Field
                  as="textarea"
                  name="section2_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section2_description_ar"
                  :class="{ 'is-invalid': errors.section2_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section2_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section2_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 2 Image Upload
              </button>
              <input
                type="file"
                ref="section2_imageRef"
                @change="event => onFileChange(event, 'section2_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section2_imageData">
                <h6>Preview Research & Development Section 2 Image:</h6>
                <img
                  :src="section2_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section2_imageData">
                <h6>Preview Section 2 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section2_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 3</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 3 Title</label>
                <Field
                  name="section3_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section3_title"
                  :class="{ 'is-invalid': errors.section3_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section3_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 3 Title</label
                >
                <Field
                  name="section3_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section3_title_ar"
                  :class="{ 'is-invalid': errors.section3_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section3_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 3 Description</label
                >
                <Field
                  as="textarea"
                  name="section3_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section3_description"
                  :class="{ 'is-invalid': errors.section3_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section3_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 3 Description</label
                >
                <Field
                  as="textarea"
                  name="section3_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section3_description_ar"
                  :class="{ 'is-invalid': errors.section3_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section3_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section3_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 3 Image Upload
              </button>
              <input
                type="file"
                ref="section3_imageRef"
                @change="event => onFileChange(event, 'section3_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section3_imageData">
                <h6>Preview Research & Development Section 3 Image:</h6>
                <img
                  :src="section3_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section3_imageData">
                <h6>Preview Section 3 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section3_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 4</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 4 Left Title</label
                >
                <Field
                  name="section4_left_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section4_left_title"
                  :class="{ 'is-invalid': errors.section4_left_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section4_left_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 4 Left Title</label
                >
                <Field
                  name="section4_left_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section4_left_title_ar"
                  :class="{ 'is-invalid': errors.section4_left_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section4_left_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 4 Left Description</label
                >
                <Field
                  as="textarea"
                  name="section4_left_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section4_left_description"
                  :class="{ 'is-invalid': errors.section4_left_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section4_left_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 4 Left
                  Description</label
                >
                <Field
                  as="textarea"
                  name="section4_left_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section4_left_description_ar"
                  :class="{ 'is-invalid': errors.section4_left_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section4_left_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section4_left_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 4 Left Image Upload
              </button>
              <input
                type="file"
                ref="section4_left_imageRef"
                @change="event => onFileChange(event, 'section4_left_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section4_left_imageData">
                <h6>Preview Research & Development Section 4 Left Image:</h6>
                <img
                  :src="section4_left_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section4_left_imageData">
                <h6>Preview Section 4 Left Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section4_left_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 4 Right Title</label
                >
                <Field
                  name="section4_right_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section4_right_title"
                  :class="{ 'is-invalid': errors.section4_right_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section4_right_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 4 Right Title</label
                >
                <Field
                  name="section4_right_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section4_right_title_ar"
                  :class="{ 'is-invalid': errors.section4_right_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section4_right_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 4 Right Description</label
                >
                <Field
                  as="textarea"
                  name="section4_right_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section4_right_description"
                  :class="{ 'is-invalid': errors.section4_right_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section4_right_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 4 Right
                  Description</label
                >
                <Field
                  as="textarea"
                  name="section4_right_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section4_right_description_ar"
                  :class="{
                    'is-invalid': errors.section4_right_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section4_right_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="
                  () => this.$refs.section4_right_imageRef.click()
                "
                class="btn btn-outline-danger"
              >
                Research & Development Section 4 Right Image Upload
              </button>
              <input
                type="file"
                ref="section4_right_imageRef"
                @change="event => onFileChange(event, 'section4_right_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section4_right_imageData">
                <h6>Preview Research & Development Section 4 Right Image:</h6>
                <img
                  :src="section4_right_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section4_right_imageData">
                <h6>Preview Section 4 Right Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section4_right_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 5</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 5 Title</label>
                <Field
                  name="section5_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section5_title"
                  :class="{ 'is-invalid': errors.section5_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section5_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 5 Title</label
                >
                <Field
                  name="section5_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section5_title_ar"
                  :class="{ 'is-invalid': errors.section5_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section5_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 5 Description</label
                >
                <Field
                  as="textarea"
                  name="section5_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section5_description"
                  :class="{ 'is-invalid': errors.section5_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section5_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 5 Description</label
                >
                <Field
                  as="textarea"
                  name="section5_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section5_description_ar"
                  :class="{ 'is-invalid': errors.section5_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section5_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section5_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 5 Upload
              </button>
              <input
                type="file"
                ref="section5_imageRef"
                @change="event => onFileChange(event, 'section5_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section5_imageData">
                <h6>Preview Research & Development Section 5 Image:</h6>
                <img
                  :src="section5_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section5_imageData">
                <h6>Preview Section 5 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section5_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 6</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 6 Title</label>
                <Field
                  name="section6_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section6_title"
                  :class="{ 'is-invalid': errors.section6_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section6_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 6 Title</label
                >
                <Field
                  name="section6_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section6_title_ar"
                  :class="{ 'is-invalid': errors.section6_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section6_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 6 Description</label
                >
                <Field
                  as="textarea"
                  name="section6_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section6_description"
                  :class="{ 'is-invalid': errors.section6_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section6_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 6 Description</label
                >
                <Field
                  as="textarea"
                  name="section6_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section6_description_ar"
                  :class="{ 'is-invalid': errors.section6_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section6_description_ar }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 7</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 7 Title</label>
                <Field
                  name="section7_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section7_title"
                  :class="{ 'is-invalid': errors.section7_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section7_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 7 Title</label
                >
                <Field
                  name="section7_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section7_title_ar"
                  :class="{ 'is-invalid': errors.section7_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section7_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 7 Description</label
                >
                <Field
                  as="textarea"
                  name="section7_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section7_description"
                  :class="{ 'is-invalid': errors.section7_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section7_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 7 Description</label
                >
                <Field
                  as="textarea"
                  name="section7_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section7_description_ar"
                  :class="{ 'is-invalid': errors.section7_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section7_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section7_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 7 Upload
              </button>
              <input
                type="file"
                ref="section7_imageRef"
                @change="event => onFileChange(event, 'section7_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section7_imageData">
                <h6>Preview Research & Development Section 7 Image:</h6>
                <img
                  :src="section7_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section7_imageData">
                <h6>Preview Section 7 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section7_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 8</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 8 Main Title</label
                >
                <Field
                  name="section8_main_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_main_title"
                  :class="{ 'is-invalid': errors.section8_main_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_main_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 8 Main Title</label
                >
                <Field
                  name="section8_main_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_main_title_ar"
                  :class="{ 'is-invalid': errors.section8_main_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_main_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 8 Left Title</label
                >
                <Field
                  name="section8_left_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_left_title"
                  :class="{ 'is-invalid': errors.section8_left_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_left_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 8 Left Title</label
                >
                <Field
                  name="section8_left_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_left_title_ar"
                  :class="{ 'is-invalid': errors.section8_left_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_left_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 8 Left Description</label
                >
                <Field
                  as="textarea"
                  name="section8_left_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_left_description"
                  :class="{ 'is-invalid': errors.section8_left_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_left_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 8 Left
                  Description</label
                >
                <Field
                  as="textarea"
                  name="section8_left_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_left_description_ar"
                  :class="{ 'is-invalid': errors.section8_left_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_left_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  @click.prevent="
                    () => this.$refs.section8_left_imageRef.click()
                  "
                  class="btn btn-outline-danger"
                >
                  Research & Development Section 8 Left Image Upload
                </button>
                <input
                  type="file"
                  ref="section8_left_imageRef"
                  @change="event => onFileChange(event, 'section8_left_image')"
                  accept="image/*"
                  style="display: none"
                />

                <div v-if="section8_left_imageData">
                  <h6>Preview Research & Development Section 8 Left Image:</h6>
                  <img
                    :src="section8_left_imageData"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="researchDevelopemntObj && !section8_left_imageData">
                  <h6>Preview Section 8 Left Image:</h6>
                  <img
                    :src="imageApi + researchDevelopemntObj.section8_left_image"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  @click.prevent="
                    () => this.$refs.section8_left_image1Ref.click()
                  "
                  class="btn btn-outline-danger"
                >
                  Research & Development Section 8 Left Image 1 Upload
                </button>
                <input
                  type="file"
                  ref="section8_left_image1Ref"
                  @change="event => onFileChange(event, 'section8_left_image1')"
                  accept="image/*"
                  style="display: none"
                />

                <div v-if="section8_left_image1Data">
                  <h6>
                    Preview Research & Development Section 8 Left Image 1:
                  </h6>
                  <img
                    :src="section8_left_image1Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="researchDevelopemntObj && !section8_left_image1Data">
                  <h6>Preview Section 8 Left Image 1:</h6>
                  <img
                    :src="
                      imageApi + researchDevelopemntObj.section8_left_image1
                    "
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 8 Right Title</label
                >
                <Field
                  name="section8_right_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_right_title"
                  :class="{ 'is-invalid': errors.section8_right_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_right_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 8 Right Title</label
                >
                <Field
                  name="section8_right_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_right_title_ar"
                  :class="{ 'is-invalid': errors.section8_right_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_right_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 8 Right Description</label
                >
                <Field
                  as="textarea"
                  name="section8_right_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_right_description"
                  :class="{ 'is-invalid': errors.section8_right_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_right_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 8 Right
                  Description</label
                >
                <Field
                  as="textarea"
                  name="section8_right_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section8_right_description_ar"
                  :class="{
                    'is-invalid': errors.section8_right_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section8_right_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="
                  () => this.$refs.section8_right_imageRef.click()
                "
                class="btn btn-outline-danger"
              >
                Research & Development Section 8 Right Image Upload
              </button>
              <input
                type="file"
                ref="section8_right_imageRef"
                @change="event => onFileChange(event, 'section8_right_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section8_right_imageData">
                <h6>Preview Research & Development Section 8 Right Image:</h6>
                <img
                  :src="section8_right_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section8_right_imageData">
                <h6>Preview Section 8 Left Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section8_right_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Research & Development Section 9</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 9 Title</label>
                <Field
                  name="section9_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section9_title"
                  :class="{ 'is-invalid': errors.section9_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section9_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 9 Title</label
                >
                <Field
                  name="section9_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section9_title_ar"
                  :class="{ 'is-invalid': errors.section9_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section9_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 9 Description</label
                >
                <Field
                  as="textarea"
                  name="section9_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section9_description"
                  :class="{ 'is-invalid': errors.section9_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section9_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 9 Description</label
                >
                <Field
                  as="textarea"
                  name="section9_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section9_description_ar"
                  :class="{ 'is-invalid': errors.section9_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section9_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section9_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 9 Image Upload
              </button>
              <input
                type="file"
                ref="section9_imageRef"
                @change="event => onFileChange(event, 'section9_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section9_imageData">
                <h6>Preview Research & Development Section 9 Image:</h6>
                <img
                  :src="section9_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section9_imageData">
                <h6>Preview Section 9 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section9_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 10</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 10 Title</label>
                <Field
                  name="section10_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section10_title"
                  :class="{ 'is-invalid': errors.section10_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section10_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 10 Title</label
                >
                <Field
                  name="section10_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section10_title_ar"
                  :class="{ 'is-invalid': errors.section10_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section10_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 10 Short Description</label
                >
                <Field
                  as="textarea"
                  name="section10_short_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section10_short_description"
                  :class="{ 'is-invalid': errors.section10_short_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section10_short_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 10 Short
                  Description</label
                >
                <Field
                  as="textarea"
                  name="section10_short_description_ar"
                  type="text"
                  class="form-control"
                  v-model="
                    researchDevelopemntObj.section10_short_description_ar
                  "
                  :class="{
                    'is-invalid': errors.section10_short_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section10_short_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 10 Right Description</label
                >
                <Field
                  as="textarea"
                  name="section10_right_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section10_right_description"
                  :class="{ 'is-invalid': errors.section10_right_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section10_right_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 10 Right
                  Description</label
                >
                <Field
                  as="textarea"
                  name="section10_right_description_ar"
                  type="text"
                  class="form-control"
                  v-model="
                    researchDevelopemntObj.section10_right_description_ar
                  "
                  :class="{
                    'is-invalid': errors.section10_right_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section10_right_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section10_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 10 Image Upload
              </button>
              <input
                type="file"
                ref="section10_imageRef"
                @change="event => onFileChange(event, 'section10_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section10_imageData">
                <h6>Preview Research & Development Section 10 Image:</h6>
                <img
                  :src="section10_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section10_imageData">
                <h6>Preview Section 10 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section10_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 11</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 11 Title</label>
                <Field
                  name="section11_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section11_title"
                  :class="{ 'is-invalid': errors.section11_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section11_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 11 Title</label
                >
                <Field
                  name="section11_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section11_title_ar"
                  :class="{ 'is-invalid': errors.section11_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section11_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section11_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 11 Image Upload
              </button>
              <input
                type="file"
                ref="section11_imageRef"
                @change="event => onFileChange(event, 'section11_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section11_imageData">
                <h6>Preview Research & Development Section 11 Image:</h6>
                <img
                  :src="section11_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section11_imageData">
                <h6>Preview Section 11 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section11_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 12</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 12 Main Title</label
                >
                <Field
                  name="section12_main_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section12_main_title"
                  :class="{ 'is-invalid': errors.section12_main_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section12_main_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 12 Main Title</label
                >
                <Field
                  name="section12_main_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section12_main_title_ar"
                  :class="{ 'is-invalid': errors.section12_main_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section12_main_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 12 Title</label>
                <Field
                  name="section12_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section12_title"
                  :class="{ 'is-invalid': errors.section12_title }"
                />
                <div class="invalid-feedback">
                  {{ errors.section12_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 12 Title</label
                >
                <Field
                  name="section12_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section12_title_ar"
                  :class="{ 'is-invalid': errors.section12_title_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.section12_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 12 Description</label
                >
                <Field
                  as="textarea"
                  name="section12_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section12_description"
                  :class="{ 'is-invalid': errors.section12_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section12_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 12 Description</label
                >
                <Field
                  as="textarea"
                  name="section12_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section12_description_ar"
                  :class="{ 'is-invalid': errors.section12_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section12_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section12_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 12 Image Upload
              </button>
              <input
                type="file"
                ref="section12_imageRef"
                @change="event => onFileChange(event, 'section12_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section12_imageData">
                <h6>Preview Research & Development Section 12 Image:</h6>
                <img
                  :src="section12_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section12_imageData">
                <h6>Preview Section 12 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section12_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 13</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 13 Main Title</label
                >
                <Field
                  name="section13_main_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section13_main_title"
                  :class="{ 'is-invalid': errors.section13_main_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section13_main_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 13 Main Title</label
                >
                <Field
                  name="section13_main_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section13_main_title_ar"
                  :class="{ 'is-invalid': errors.section13_main_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section13_main_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 13 Main Description</label
                >
                <Field
                  as="textarea"
                  name="section13_main_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section13_main_description"
                  :class="{ 'is-invalid': errors.section13_main_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section13_main_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 13 Main
                  Description</label
                >
                <Field
                  as="textarea"
                  name="section13_main_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section13_main_description_ar"
                  :class="{
                    'is-invalid': errors.section13_main_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section13_main_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 13 Right Description</label
                >
                <Field
                  as="textarea"
                  name="section13_right_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section13_right_description"
                  :class="{ 'is-invalid': errors.section13_right_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section13_right_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 13 Right
                  Description</label
                >
                <Field
                  as="textarea"
                  name="section13_right_description_ar"
                  type="text"
                  class="form-control"
                  v-model="
                    researchDevelopemntObj.section13_right_description_ar
                  "
                  :class="{
                    'is-invalid': errors.section13_right_description_ar,
                  }"
                />

                <div class="invalid-feedback">
                  {{ errors.section13_right_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="
                  () => this.$refs.section13_left_imageRef.click()
                "
                class="btn btn-outline-danger"
              >
                Research & Development Section 13 Left Image Upload
              </button>
              <input
                type="file"
                ref="section13_left_imageRef"
                @change="event => onFileChange(event, 'section13_left_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section13_left_imageData">
                <h6>Preview Research & Development Section 13 Left Image:</h6>
                <img
                  :src="section13_left_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section13_left_imageData">
                <h6>Preview Section 13 Left Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section13_left_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Research & Development Section 14</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Research & Development Section 14 Title</label>
                <Field
                  name="section14_title"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section14_title"
                  :class="{ 'is-invalid': errors.section14_title }"
                />

                <div class="invalid-feedback">
                  {{ errors.section14_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 14 Title</label
                >
                <Field
                  name="section14_title_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section14_title_ar"
                  :class="{ 'is-invalid': errors.section14_title_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section14_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for=""
                  >Research & Development Section 14 Description</label
                >
                <Field
                  as="textarea"
                  name="section14_description"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section14_description"
                  :class="{ 'is-invalid': errors.section14_description }"
                />

                <div class="invalid-feedback">
                  {{ errors.section14_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Research & Development Arabic Section 14 Description</label
                >
                <Field
                  as="textarea"
                  name="section14_description_ar"
                  type="text"
                  class="form-control"
                  v-model="researchDevelopemntObj.section14_description_ar"
                  :class="{ 'is-invalid': errors.section14_description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.section14_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.section14_imageRef.click()"
                class="btn btn-outline-danger"
              >
                Research & Development Section 14 Image Upload
              </button>
              <input
                type="file"
                ref="section14_imageRef"
                @change="event => onFileChange(event, 'section14_image')"
                accept="image/*"
                style="display: none"
              />
              <!-- <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field> -->
              <div v-if="section14_imageData">
                <h6>Preview Research & Development Section 14 Image:</h6>
                <img
                  :src="section14_imageData"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="researchDevelopemntObj && !section14_imageData">
                <h6>Preview Section 14 Image:</h6>
                <img
                  :src="imageApi + researchDevelopemntObj.section14_image"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-success">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
import _ from "lodash";

export default {
  name: "Reseach And Development",
  components: {
    Field,
    Form,
  },
  data() {
    const { setFieldValue } = useForm();

    const schema = yup.object().shape({
      banner_title: yup
        .string()
        .trim()
        .required("Reseach And Development Banner Title is Required"),
      section1_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 1 Title is Required"),
      section1_short_description_1: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 1 Short Description 1 is Required"
        ),
      section1_short_description_2: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 1 Short Description 1 is Required"
        ),
      section2_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 2 Title is Required"),
      section2_description: yup
        .string()
        .trim()
        .required("Reseach And Development Section 2 Description is Required"),
      section3_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 3 Title is Required"),
      section3_description: yup
        .string()
        .trim()
        .required("Reseach And Development Section 3 Description is Required"),
      section4_left_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 4 Left Title is Required"),
      section4_left_description: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 4 Left Description is Required"
        ),
      section4_right_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 4 Right Title is Required"),
      section4_right_description: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 4 Left Description is Required"
        ),
      section5_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 5 Title is Required"),
      section5_description: yup
        .string()
        .trim()
        .required("Reseach And Development Section 5 Description is Required"),
      section6_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 6 Title is Required"),
      section6_description: yup
        .string()
        .trim()
        .required("Reseach And Development Section 6 Description is Required"),
      section7_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 7 Title is Required"),
      section7_description: yup
        .string()
        .trim()
        .required("Reseach And Development Section 7 Description is Required"),
      section8_main_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 8 Main Title is Required"),
      section8_left_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 8 Left Title is Required"),
      section8_left_description: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 8 Left Description is Required"
        ),
      section8_right_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 8 Right Title is Required"),
      section8_right_description: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 8 Right Description is Required"
        ),
      section9_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 9 Title is Required"),
      section9_description: yup
        .string()
        .trim()
        .required("Reseach And Development Section 9 Description is Required"),
      section10_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 10 Title is Required"),
      section10_short_description: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 10 Short Description is Required"
        ),
      section10_right_description: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 10 Right Description is Required"
        ),
      section11_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 11 Title is Required"),
      section12_main_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 12 Main Title is Required"),
      section12_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 12 Title is Required"),
      section12_description: yup
        .string()
        .trim()
        .required("Reseach And Development Section 12 Description is Required"),
      section13_main_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 13 Main Title is Required"),
      section13_main_description: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 13 Main Description is Required"
        ),
      section13_right_description: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Section 13 Ridght Description is Required"
        ),
      section14_title: yup
        .string()
        .trim()
        .required("Reseach And Development Section 14 Title is Required"),
      section14_description: yup
        .string()
        .required("Reseach And Development Section 14 Description is Required"),

      banner_title_ar: yup
        .string()
        .trim()
        .required("Reseach And Development Arabic Banner Title is Required"),
      section1_title_ar: yup
        .string()
        .trim()
        .required("Reseach And Development Arabic Section 1 Title is Required"),
      section1_short_description_1_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 1 Short Description 1 is Required"
        ),
      section1_short_description_2_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 1 Short Description 1 is Required"
        ),
      section2_title_ar: yup
        .string()
        .trim()
        .required("Reseach And Development Arabic Section 2 Title is Required"),
      section2_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 2 Description is Required"
        ),
      section3_title_ar: yup
        .string()
        .trim()
        .required("Reseach And Development Arabic Section 3 Title is Required"),
      section3_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 3 Description is Required"
        ),
      section4_left_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 4 Left Title is Required"
        ),
      section4_left_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 4 Left Description is Required"
        ),
      section4_right_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 4 Right Title is Required"
        ),
      section4_right_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 4 Left Description is Required"
        ),
      section5_title_ar: yup
        .string()
        .trim()
        .required("Reseach And Development Arabic Section 5 Title is Required"),
      section5_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 5 Description is Required"
        ),
      section6_title_ar: yup
        .string()
        .trim()
        .required("Reseach And Development Arabic Section 6 Title is Required"),
      section6_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 6 Description is Required"
        ),
      section7_title_ar: yup
        .string()
        .trim()
        .required("Reseach And Development Arabic Section 7 Title is Required"),
      section7_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 7 Description is Required"
        ),
      section8_main_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 8 Main Title is Required"
        ),
      section8_left_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 8 Left Title is Required"
        ),
      section8_left_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 8 Left Description is Required"
        ),
      section8_right_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 8 Right Title is Required"
        ),
      section8_right_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 8 Right Description is Required"
        ),
      section9_title_ar: yup
        .string()
        .trim()
        .required("Reseach And Development Arabic Section 9 Title is Required"),
      section9_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 9 Description is Required"
        ),
      section10_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 10 Title is Required"
        ),
      section10_short_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 10 Short Description is Required"
        ),
      section10_right_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 10 Right Description is Required"
        ),
      section11_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 11 Title is Required"
        ),
      section12_main_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 12 Main Title is Required"
        ),
      section12_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 12 Title is Required"
        ),
      section12_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 12 Description is Required"
        ),
      section13_main_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 13 Main Title is Required"
        ),
      section13_main_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 13 Main Description is Required"
        ),
      section13_right_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 13 Ridght Description is Required"
        ),
      section14_title_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 14 Title is Required"
        ),
      section14_description_ar: yup
        .string()
        .trim()
        .required(
          "Reseach And Development Arabic Section 14 Description is Required"
        ),
    });

    const initialData = {
      banner_title: "",
      section1_title: "",
      section1_short_description_1: "",
      section1_short_description_2: "",
      section2_title: "",
      section2_description: "",
      section3_title: "",
      section3_description: "",
      section4_left_title: "",
      section4_left_description: "",
      section4_right_title: "",
      section4_right_description: "",
      section5_title: "",
      section5_description: "",
      section6_title: "",
      section6_description: "",
      section7_title: "",
      section7_description: "",
      section8_main_title: "",
      section8_left_title: "",
      section8_left_description: "",
      section8_right_title: "",
      section8_right_description: "",
      section9_title: "",
      section9_description: "",
      section10_title: "",
      section10_short_description: "",
      section10_right_description: "",
      section11_title: "",
      section12_main_title: "",
      section12_title: "",
      section12_description: "",
      section13_main_title: "",
      section13_main_description: "",
      section13_right_description: "",
      section14_title: "",
      section14_description: "",
      banner_title_ar: "",
      section1_title_ar: "",
      section1_short_description_1_ar: "",
      section1_short_description_2_ar: "",
      section2_title_ar: "",
      section2_description_ar: "",
      section3_title_ar: "",
      section3_description_ar: "",
      section4_left_title_ar: "",
      section4_left_description_ar: "",
      section4_right_title_ar: "",
      section4_right_description_ar: "",
      section5_title_ar: "",
      section5_description_ar: "",
      section6_title_ar: "",
      section6_description_ar: "",
      section7_title_ar: "",
      section7_description_ar: "",
      section8_main_title_ar: "",
      section8_left_title_ar: "",
      section8_left_description_ar: "",
      section8_right_title_ar: "",
      section8_right_description_ar: "",
      section9_title_ar: "",
      section9_description_ar: "",
      section10_title_ar: "",
      section10_short_description_ar: "",
      section10_right_description_ar: "",
      section11_title_ar: "",
      section12_main_title_ar: "",
      section12_title_ar: "",
      section12_description_ar: "",
      section13_main_title_ar: "",
      section13_main_description_ar: "",
      section13_right_description_ar: "",
      section14_title_ar: "",
      section14_description_ar: "",
    };

    return {
      imageApi: imageApi,
      banner_image: null,
      banner_imageData: null,
      section2_image: null,
      section2_imageData: null,
      section3_image: null,
      section3_imageData: null,
      section4_right_image: null,
      section4_right_imageData: null,
      section4_left_image: null,
      section4_left_imageData: null,
      section5_image: null,
      section5_imageData: null,
      section7_image: null,
      section7_imageData: null,
      section8_right_image: null,
      section8_right_imageData: null,
      section8_left_image: null,
      section8_left_image1: null,
      section8_left_imageData: null,
      section8_left_image1Data: null,
      section9_image: null,
      section9_imageData: null,
      section10_image: null,
      section10_imageData: null,
      section11_image: null,
      section11_imageData: null,
      section12_image: null,
      section12_imageData: null,
      section13_left_image: null,
      section13_left_imageData: null,
      section14_image: null,
      section14_imageData: null,
      schema,
      initialData,
      setFieldValue,
    };
  },
  computed: {
    ...mapGetters("researchDevelopment", [
      "researchDevelopemntObj",
      "isLoading",
    ]),
  },
  mounted() {
    this.fetchDetail();
    let initData = this.researchDevelopemntObj
      ? _.clone(this.researchDevelopemntObj)
      : {};
    this.initialData = this.researchDevelopemntObj
      ? {
          ...initData,
        }
      : this.initialData;
  },
  methods: {
    fetchDetail() {
      try {
        this.$store.dispatch(
          "researchDevelopment/fetchResearchDevelopmentDetail",
          1
        );
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      const obj = {
        ...values,
        banner_image: this.banner_image,
        section2_image: this.section2_image,
        section3_image: this.section3_image,
        section4_right_image: this.section4_right_image,
        section4_left_image: this.section4_left_image,
        section5_image: this.section5_image,
        section7_image: this.section7_image,
        section8_right_image: this.section8_right_image,
        section8_left_image: this.section8_left_image,
        section8_left_image1: this.section8_left_image1,
        section9_image: this.section9_image,
        section10_image: this.section10_image,
        section11_image: this.section11_image,
        section12_image: this.section12_image,
        section13_left_image: this.section13_left_image,
        section14_image: this.section14_image,
      };

      let formData = new FormData();

      formData.append("banner_title", obj.banner_title);
      formData.append("section1_title", obj.section1_title);
      formData.append(
        "section1_short_description_1",
        obj.section1_short_description_1
      );
      formData.append(
        "section1_short_description_2",
        obj.section1_short_description_2
      );
      formData.append("section2_title", obj.section2_title);
      formData.append("section2_description", obj.section2_description);
      formData.append("section3_title", obj.section3_title);
      formData.append("section3_description", obj.section3_description);
      formData.append("section4_left_title", obj.section4_left_title);
      formData.append(
        "section4_left_description",
        obj.section4_left_description
      );
      formData.append("section4_right_title", obj.section4_right_title);
      formData.append(
        "section4_right_description",
        obj.section4_right_description
      );
      formData.append("section5_title", obj.section5_title);
      formData.append("section5_description", obj.section5_description);
      formData.append("section6_title", obj.section6_title);
      formData.append("section6_description", obj.section6_description);
      formData.append("section7_title", obj.section7_title);
      formData.append("section7_description", obj.section7_description);
      formData.append("section8_main_title", obj.section8_main_title);
      formData.append("section8_left_title", obj.section8_left_title);
      formData.append(
        "section8_left_description",
        obj.section8_left_description
      );
      formData.append("section8_right_title", obj.section8_right_title);
      formData.append(
        "section8_right_description",
        obj.section8_right_description
      );
      formData.append("section9_title", obj.section9_title);
      formData.append("section9_description", obj.section9_description);
      formData.append("section10_title", obj.section10_title);
      formData.append(
        "section10_short_description",
        obj.section10_short_description
      );
      formData.append(
        "section10_right_description",
        obj.section10_right_description
      );
      formData.append("section11_title", obj.section11_title);
      formData.append("section12_main_title", obj.section12_main_title);
      formData.append("section12_title", obj.section12_title);
      formData.append("section12_description", obj.section12_description);
      formData.append("section13_main_title", obj.section13_main_title);
      formData.append(
        "section13_main_description",
        obj.section13_main_description
      );
      formData.append(
        "section13_right_description",
        obj.section13_right_description
      );
      formData.append("section14_title", obj.section14_title);
      formData.append("section14_description", obj.section14_description);

      formData.append("banner_title_ar", obj.banner_title_ar);
      formData.append("section1_title_ar", obj.section1_title_ar);
      formData.append(
        "section1_short_description_1_ar",
        obj.section1_short_description_1_ar
      );
      formData.append(
        "section1_short_description_2_ar",
        obj.section1_short_description_2_ar
      );
      formData.append("section2_title_ar", obj.section2_title_ar);
      formData.append("section2_description_ar", obj.section2_description_ar);
      formData.append("section3_title_ar", obj.section3_title_ar);
      formData.append("section3_description_ar", obj.section3_description_ar);
      formData.append("section4_left_title_ar", obj.section4_left_title_ar);
      formData.append(
        "section4_left_description_ar",
        obj.section4_left_description_ar
      );
      formData.append("section4_right_title_ar", obj.section4_right_title_ar);
      formData.append(
        "section4_right_description_ar",
        obj.section4_right_description_ar
      );
      formData.append("section5_title_ar", obj.section5_title_ar);
      formData.append("section5_description_ar", obj.section5_description_ar);
      formData.append("section6_title_ar", obj.section6_title_ar);
      formData.append("section6_description_ar", obj.section6_description_ar);
      formData.append("section7_title_ar", obj.section7_title_ar);
      formData.append("section7_description_ar", obj.section7_description_ar);
      formData.append("section8_main_title_ar", obj.section8_main_title_ar);
      formData.append("section8_left_title_ar", obj.section8_left_title_ar);
      formData.append(
        "section8_left_description_ar",
        obj.section8_left_description_ar
      );
      formData.append("section8_right_title_ar", obj.section8_right_title_ar);
      formData.append(
        "section8_right_description_ar",
        obj.section8_right_description_ar
      );
      formData.append("section9_title_ar", obj.section9_title_ar);
      formData.append("section9_description_ar", obj.section9_description_ar);
      formData.append("section10_title_ar", obj.section10_title_ar);
      formData.append(
        "section10_short_description_ar",
        obj.section10_short_description_ar
      );
      formData.append(
        "section10_right_description_ar",
        obj.section10_right_description_ar
      );
      formData.append("section11_title_ar", obj.section11_title_ar);
      formData.append("section12_main_title_ar", obj.section12_main_title_ar);
      formData.append("section12_title_ar", obj.section12_title_ar);
      formData.append("section12_description_ar", obj.section12_description_ar);
      formData.append("section13_main_title_ar", obj.section13_main_title_ar);
      formData.append(
        "section13_main_description_ar",
        obj.section13_main_description_ar
      );
      formData.append(
        "section13_right_description_ar",
        obj.section13_right_description_ar
      );
      formData.append("section14_title_ar", obj.section14_title_ar);
      formData.append("section14_description_ar", obj.section14_description_ar);

      formData.append("banner_image", obj.banner_image);
      formData.append("section2_image", obj.section2_image);
      formData.append("section3_image", obj.section3_image);
      formData.append("section4_right_image", obj.section4_right_image);
      formData.append("section4_left_image", obj.section4_left_image);
      formData.append("section5_image", obj.section5_image);
      formData.append("section7_image", obj.section7_image);
      formData.append("section8_right_image", obj.section8_right_image);
      formData.append("section8_left_image", obj.section8_left_image);
      formData.append("section8_left_image1", obj.section8_left_image1);
      formData.append("section9_image", obj.section9_image);
      formData.append("section10_image", obj.section10_image);
      formData.append("section11_image", obj.section11_image);
      formData.append("section12_image", obj.section12_image);
      formData.append("section13_left_image", obj.section13_left_image);
      formData.append("section14_image", obj.section14_image);

      this.$store.dispatch("researchDevelopment/put", formData);

      this.$swal({
        position: "center",
        icon: "success",
        title: "Research & Development Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });

      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },
    onFileChange(event, title) {
      const file = event.target.files[0];
      if (title == "banner_image") {
        this.banner_image = event.target.files[0];
        console.log("BANNENR IMAGE", this.banner_image);
      } else if (title == "section2_image") {
        this.section2_image = event.target.files[0];
      } else if (title == "section3_image") {
        this.section3_image = event.target.files[0];
      } else if (title == "section4_right_image") {
        this.section4_right_image = event.target.files[0];
      } else if (title == "section4_left_image") {
        this.section4_left_image = event.target.files[0];
      } else if (title == "section5_image") {
        this.section5_image = event.target.files[0];
      } else if (title == "section7_image") {
        this.section7_image = event.target.files[0];
      } else if (title == "section8_right_image") {
        this.section8_right_image = event.target.files[0];
      } else if (title == "section8_left_image") {
        this.section8_left_image = event.target.files[0];
      } else if (title == "section8_left_image1") {
        this.section8_left_image1 = event.target.files[0];
      } else if (title == "section9_image") {
        this.section9_image = event.target.files[0];
      } else if (title == "section10_image") {
        this.section10_image = event.target.files[0];
      } else if (title == "section11_image") {
        this.section11_image = event.target.files[0];
      } else if (title == "section12_image") {
        this.section12_image = event.target.files[0];
      } else if (title == "section13_left_image") {
        this.section13_left_image = event.target.files[0];
      } else if (title == "section14_image") {
        this.section14_image = event.target.files[0];
      }

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = e => {
          if (title == "banner_image") {
            this.banner_imageData = e.target.result;
          } else if (title == "section2_image") {
            this.section2_imageData = e.target.result;
          } else if (title == "section3_image") {
            this.section3_imageData = e.target.result;
          } else if (title == "section4_right_image") {
            this.section4_right_imageData = e.target.result;
          } else if (title == "section4_left_image") {
            this.section4_left_imageData = e.target.result;
          } else if (title == "section5_image") {
            this.section5_imageData = e.target.result;
          } else if (title == "section7_image") {
            this.section7_imageData = e.target.result;
          } else if (title == "section8_right_image") {
            this.section8_right_imageData = e.target.result;
          } else if (title == "section8_left_image1") {
            this.section8_left_image1Data = e.target.result;
          } else if (title == "section8_left_image") {
            this.section8_left_imageData = e.target.result;
          } else if (title == "section9_image") {
            this.section9_imageData = e.target.result;
          } else if (title == "section10_image") {
            this.section10_imageData = e.target.result;
          } else if (title == "section11_image") {
            this.section11_imageData = e.target.result;
          } else if (title == "section12_image") {
            this.section12_imageData = e.target.result;
          } else if (title == "section13_left_image") {
            this.section13_left_imageData = e.target.result;
          } else if (title == "section14_image") {
            this.section14_imageData = e.target.result;
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
