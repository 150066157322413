<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit Data Protection CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onSubmit"
          class="form"
          :initial-values="initialData"
          :validation-schema="schema"
          v-slot="{ errors }"
          v-if="dataProtectionObj"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Title</label>
              <Field
                name="title"
                type="text"
                class="form-control"
                v-model="dataProtectionObj.title"
                :class="{ 'is-invalid': errors.title }"
              />
              <div class="invalid-feedback">{{ errors.title }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Title</label>
              <Field
                name="title_ar"
                type="text"
                class="form-control"
                v-model="dataProtectionObj.title_ar"
                :class="{ 'is-invalid': errors.title_ar }"
              />
              <div class="invalid-feedback">{{ errors.title_ar }}</div>
            </div>
          </div>
          <div class="form-group my-2">
            <label for="description">Description</label>
            <quill-editor
              v-model:content="dataProtectionObj.description"
              class="mb-3"
              id="rich-text"
              rows="20"
              ref="quill"
              @change="onChange($event)"
              contentType="html"
            >
            </quill-editor>
          </div>
          <div class="form-group my-2">
            <label for="description_ar">Arabic Description</label>
            <quill-editor
              v-model:content="dataProtectionObj.description_ar"
              class="mb-3"
              id="rich-text-ar"
              rows="20"
              ref="quill"
              @change="onChangeAR($event)"
            >
            </quill-editor>
          </div>
          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import _ from "lodash";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "Data Protection",
  components: {
    Form,
    Field,
    quillEditor,
  },

  data() {
    const { setFieldValue } = useForm();
    const schema = yup.object().shape({
      title: yup.string().trim().required("Title is Required"),
      title_ar: yup.string().trim().required("Arabic Title is Required"),
    });

    const initialData = {
      title: "",
      title_ar: "",
    };

    return {
      description: "",
      description_ar: "",
      schema,
      initialData,
      setFieldValue,
    };
  },
  computed: {
    ...mapGetters("dataProtection", ["dataProtectionObj", "isLoading"]),
  },
  async mounted() {
    await this.fetchDetail();
    let initData = this.dataProtectionObj
      ? _.clone(this.dataProtectionObj)
      : {};
    this.initialData = this.dataProtectionObj
      ? {
          ...initData,
        }
      : this.initialData;

    this.description = this.dataProtectionObj.description;
    this.description_ar = this.dataProtectionObj.description_ar;
  },
  methods: {
    onChange(event) {
      this.description = event.html;
    },
    onChangeAR(event) {
      this.description_ar = event.html;
    },
    async fetchDetail() {
      try {
        await this.$store.dispatch(
          "dataProtection/fetchDataProtectionDetail",
          1
        );
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      const obj = {
        ...values,
        description: this.description,
        description_ar: this.description_ar,
      };
      this.$store.dispatch("dataProtection/put", obj);
      this.$swal({
        position: "center",
        icon: "success",
        title: "Data Protection Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });
      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },
  },
};
</script>
