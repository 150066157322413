import API from "../../api/api";
import { authHeader, authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  visions: [],
  vision: null,
  loading: false,
});

const mutations = {
  getVisions(state, payload) {
    state.visions = payload;
  },
  getVisionsDetail(state, payload) {
    state.vision = payload;
  },
  addVision(state, payload) {
    state.visions.push(payload);
  },
  editVision(state, payload) {
    const index = state.visions.findIndex(m => m.id === payload.id);
    if (index) {
      state.vision = payload;
    }
  },
  deleteVision(state, payload) {
    const filterArry = state.visions.filter(vision => vision.id != payload);
    state.visions = filterArry;
  },
  setDetailInput: (state, e) => {
    let vision = state.vision;
    vision[e.target.name] = e.target.value;
    state.vision = vision;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchVisions({ commit }) {
    await API.get("vision")
      .then(res => {
        commit("getVisions", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async fetchVisionDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`vision/${payload}`)
      .then(res => {
        commit("getVisionsDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("vision", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addVision", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    const data = payload.data;
    let visionData;
    await API.put(`vision/${payload.id}`, data, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editVision", payload);
          visionData = res.status
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return visionData;
  },
  async delete({ commit }, payload) {
    await API.delete(`vision/${payload}`, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("deleteVision", payload);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  vision: state => state.vision,
  visions: state => state.visions,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
