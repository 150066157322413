<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Model Colors Edit</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
          enctype="multipart/form-data"
          v-if="modelColor"
        >
          <div class="form-group">
            <label for="exampleInputEmail1">Model</label>
            <Field
              name="model"
              as="select"
              class="form-control"
              v-model="modelColor.modelId"
              :class="{ 'is-invalid': errors.model }"
            >
              <option value="" disabled>Select value</option>
              <option
                v-for="(model, index) in models"
                :key="index"
                :value="model.id"
              >
                {{ model.name }}
              </option>
            </Field>

            <div class="invalid-feedback">{{ errors.model }}</div>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Model Color</label>
            <Field
              name="color"
              v-model="modelColor.color"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.color }"
            />
            <div class="invalid-feedback">{{ errors.color }}</div>
          </div>

          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.fileInput.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
              @change="onFileChange"
              accept="image/*"
              style="display: none"
            />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length">{{ errors[0] }}</div>
          </Field>
          <div v-if="modelColor && !imageData">
            <h3>Preview:</h3>
            <img
              :src="imageApi + modelColor.image"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>
          <div v-if="imageData">
            <h3>Preview:</h3>
            <img
              :src="imageData"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>
          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
export default {
  name: "Model Colors Edit",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      imageApi: imageApi,
      fileMain: null,
      imageData: null,
      schema: yup.object().shape({
        color: yup.string().trim().required("Model Color is required"),
        model: yup.string().trim().required("Select Model is required"),
      }),
    };
  },
  computed: {
    ...mapGetters("models", ["models"]),
    ...mapGetters("modelColors", ["modelColor", "isLoading"]),
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.id) {
      this.$store.dispatch("models/fetchModels", null, {
        root: true,
      });
      this.fetchDetail();
    }
  },
  mounted() {},
  methods: {
    updateInput() {
      this.$store.dispatch("modelColors/updateInput");
    },
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image: this.fileMain,
      };

      console.log("CVALUES", values);

      let formData = new FormData();
      formData.append("image", this.$refs.fileInput.files[0]);
      formData.append("color", obj.color);
      formData.append("modelId", obj.model);

      this.$store
        .dispatch("modelColors/put", { id: this.id, data: formData })
        .then(res => {
          if (res == 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Model Color has been Updated",
              showConfirmButton: false,
              timer: 2000,
            });
            actions.resetForm();
            this.$router.push("/model-colors");
          }
        })
        .catch(error => {
          // Handle any errors
          console.error("Error updating model:", error);
          this.$swal(
            "Error!",
            "There was an issue updating the model.",
            "error"
          );
        });
    },
    handleCancel() {
      this.$router.push("/model-colors");
    },

    onFileChange(event) {
      const file = event.target.files[0];

      this.fileMain = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    fetchDetail() {
      try {
        this.$store.dispatch("modelColors/fetchModelColorsDetail", this.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
