import API from "../../api/api";
import { authHeader, authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  models: [],
  model: null,
  loading: false,
});

const mutations = {
  getModels(state, payload) {
    state.models = payload;
  },
  getModelDetail(state, payload) {
    state.model = payload;
  },
  addModel(state, payload) {
    state.models.push(payload);
  },
  editModel(state, payload) {
    const index = state.models.findIndex(m => m.id === payload.id);
    if (index) {
      state.model = payload;
    }
  },
  deleteModel(state, payload) {
    const filterArry = state.models.filter(model => model.id != payload);
    state.models = filterArry;
  },
  setDetailInput: (state, e) => {
    let model = state.model;
    model[e.target.name] = e.target.value;
    state.model = model;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchModels({ commit }) {
    await API.get("model")
      .then(res => {
        commit("getModels", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async fetchModelDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`model/${payload}`)
      .then(res => {
        commit("getModelDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    let modelData
    await API.post("model", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addModel", res.data.data);
        modelData = res.status;
       
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return modelData;
  },
  async put({ commit }, payload) {
    let modelData
    const data = payload.data;

    await API.put(`model/${payload.id}`, data, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editModel", payload);
          modelData = res.status;
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return modelData;

  },
  async delete({ commit }, payload) {
    await API.delete(`model/${payload}`, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("deleteModel", payload);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  model: state => state.model,
  models: state => state.models,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
