<!-- <template>
  <div class="table-responsive p-3">
    <table class="table align-items-center mb-0 text-center">
      <thead v-if="headerList" >
        <tr>
          <th v-for="(header, index) in headerList" :key="index" scope="col">
            {{ header.name }}
          </th>
        </tr>
      </thead>
      <tbody v-if="dataList == 0" >
        <tr>
          <td :colspan="headerList.length">
            <h5>No Record Found</h5>
          </td>
        </tr>
      </tbody>
      <tbody v-else >
        <tr v-for="(data, i) in dataList" :key="i">
          <td v-for="(header, index) in headerList" :key="index">
            <slot :name="header.id" :row="data" v-if="header.id == 'id'">
              {{ i + 1 }}
            </slot>
            <slot :name="header.id" :row="data" v-else>
              <div>
                {{ get(data, header.id, null) }}
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Data Table",
  data() {
    return {
      get: _.get,
    };
  },
  props: {
    headerList: {
      type: Array, // array of { name, isSortable, id }
      required: false,
      default: null,
    },
    dataList: {
      type: Array,
      required: false,
      default: null,
    },
  },
};
</script> -->

<!-- <template>
  <div class="table-responsive p-3">
    <table class="table align-items-center mb-0 text-center">
      <thead v-if="headerList" >
        <tr>
          <th v-for="(header, index) in headerList" :key="index" scope="col">
            {{ header.name }}
          </th>
        </tr>
      </thead>
      <tbody v-if="dataList == 0" >
        <tr>
          <td :colspan="headerList.length">
            <h5>No Record Found</h5>
          </td>
        </tr>
      </tbody>
      <tbody v-else >
        <tr v-for="(data, i) in dataList" :key="i">
          <td v-for="(header, index) in headerList" :key="index">
            <slot :name="header.id" :row="data" v-if="header.id == 'id'">
              {{ i + 1 }}
            </slot>
            <slot :name="header.id" :row="data" v-else>
              <div>
                {{ get(data, header.id, null) }}
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Data Table",
  data() {
    return {
      get: _.get,
    };
  },
  props: {
    headerList: {
      type: Array, // array of { name, isSortable, id }
      required: false,
      default: null,
    },
    dataList: {
      type: Array,
      required: false,
      default: null,
    },
  },
};
</script> -->

<!-- ----------------------------------------------------- -->
<template>
  <div>
    <!-- Search input -->
    <div class="d-flex justify-content-end p-3">
      <input
        type="text"
        v-model="searchTerm"
        @input="filterData"
        placeholder="Search..."
        class="form-control search-input"
      />
    </div>

    <div class="table-responsive p-3">
      <table class="table align-items-center mb-0 text-center">
        <thead v-if="headerList">
          <tr>
            <th
              v-for="(header, index) in headerList"
              :key="index"
              scope="col"
              @click="sortData(header.id)"
              :class="{ sortable: header.isSortable }"
            >
              {{ header.name }}
              <span v-if="header.isSortable">
                <i v-if="sortKey === header.id && sortOrder === 'asc'" class="fa fa-arrow-up"></i>
                <i v-if="sortKey === header.id && sortOrder === 'desc'" class="fa fa-arrow-down"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody v-if="filteredDataList.length === 0">
          <tr>
            <td :colspan="headerList.length">
              <h5>No Record Found</h5>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(data, i) in paginatedDataList" :key="i">
            <td v-for="(header, index) in headerList" :key="index">
              <slot :name="header.id" :row="data" v-if="header.id === 'id'">
                {{ i + 1 + (currentPage - 1) * pageSize }}
              </slot>
              <slot :name="header.id" :row="data" v-else>
                <div>
                  {{ get(data, header.id, null) }}
                </div>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination controls -->
    <div class="d-flex justify-content-end p-3">
      <button class="btn btn-success mx-2" @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span class="align-self-center">Page {{ currentPage }} of {{ totalPages }}</span>
      <button class="btn btn-success mx-2" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "DataTable",
  data() {
    return {
      get: _.get,
      searchTerm: "",
      sortKey: null,
      sortOrder: "asc",
      currentPage: 1,
      pageSize: 10,
      filteredDataList: [],
    };
  },
  props: {
    headerList: {
      type: Array, // array of { name, isSortable, id }
      required: false,
      default: null,
    },
    dataList: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    paginatedDataList() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredDataList.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredDataList.length / this.pageSize);
    },
  },
  watch: {
    dataList: {
      handler() {
        this.filterData();
      },
      deep: true,
    },
  },
  methods: {
    flattenObject(obj, prefix = '') {
      return Object.keys(obj).reduce((acc, key) => {
        const prefixedKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          Object.assign(acc, this.flattenObject(obj[key], prefixedKey));
        } else {
          acc[prefixedKey] = obj[key];
        }
        return acc;
      }, {});
    },
    filterData() {
      if (this.searchTerm) {
        const term = this.searchTerm.toLowerCase();
        this.filteredDataList = this.dataList.filter(item => {
          const flattenedItem = this.flattenObject(item);
          return Object.values(flattenedItem).some(value =>
            String(value).toLowerCase().includes(term)
          );
        });
      } else {
        this.filteredDataList = this.dataList;
      }
      this.sortData(this.sortKey); // Apply sorting after filtering
      this.currentPage = 1; // Reset to first page after filtering
    },
    sortData(key) {
      if (!key) return;
      this.sortOrder = this.sortKey === key && this.sortOrder === "asc" ? "desc" : "asc";
      this.sortKey = key;
      this.filteredDataList.sort((a, b) => {
        const aValue = _.get(a, key);
        const bValue = _.get(b, key);
        if (aValue === bValue) return 0;
        if (this.sortOrder === "asc") {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
  mounted() {
    this.filterData();
  },
};
</script>

<style scoped>
.sortable {
  cursor: pointer;
}

.search-input {
  max-width: 300px;
}

.table-responsive {
  margin-bottom: 20px;
}

.d-flex {
  align-items: center;
}

.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.fa {
  margin-left: 5px;
}
</style>
