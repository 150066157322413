<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Multimedia Add</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form as="form" @submit="onFormSubmit" class="form" :validation-schema="schema" v-slot="{ errors, setErrors }"
          enctype="multipart/form-data">
          <div class="form-group">
            <label for="exampleInputEmail1">Model</label>
            <Field name="model" as="select" class="form-control" :class="{ 'is-invalid': errors.model }">
              <option value="" disabled>Select value</option>
              <option v-for="(model, index) in models" :key="index" :value="model.id">
                {{ model.name }}
              </option>
            </Field>

            <div class="invalid-feedback">{{ errors.model }}</div>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Media Type</label>
            <Field name="mediaType" as="select" class="form-control" :class="{ 'is-invalid': errors.mediaType }">
              <option value="" disabled>Select value</option>
              <option value="image">Image</option>
              <option value="video">Video</option>
            </Field>
            <div class="invalid-feedback">{{ errors.mediaType }}</div>
          </div>

          <div class="form-group">
            <button @click.prevent="() => this.$refs.fileInput.click()" class="btn btn-outline-danger">
              Image / Video Upload
            </button>
            <input type="file" ref="fileInput" @change="onFileChange($event, setErrors)" accept="image/*,video/*" style="display: none" />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length" class="img-invalid">{{ errors[0] }}</div>
          </Field>
          <div v-if="mediaData">
            <h3>Preview:</h3>
            <div v-if="mediaType === 'image'">
              <img :src="mediaData" alt="Image Preview" height="200" width="230" />
            </div>
            <div v-else-if="mediaType === 'video'">
              <video :src="mediaData" controls height="200" width="230"></video>
            </div>
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button type="button" @click="handleCancel" class="btn btn-outline-success my-2 cancel-btn">
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";

export default {
  name: "Multimedia Add",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      fileMain: null,
      mediaData: null,
      mediaType: null,
      schema: yup.object().shape({
        mediaType: yup
          .string()
          .trim()
          .required("Select Media Type is required"),
        model: yup.string().trim().required("Select Model is required"),
      }),
    };
  },
  computed: {
    ...mapGetters("models", ["models"]),
  },
  mounted() {
    this.$store.dispatch("models/fetchModels", null, {
      root: true,
    });
  },
  methods: {
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image: this.fileMain,
      };

      let formData = new FormData();
      if (!this.fileMain) {
        actions.setFieldError("image", "Image / Video is required");
        return;
      }
      formData.append("media", this.fileMain);
      formData.append("typeOfMedia", obj.mediaType);
      formData.append("modelId", obj.model);
      this.$store.dispatch("multimedia/post", formData)
        .then(res => {
          if (res == 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Media has been Added",
              showConfirmButton: false,
              timer: 2000,
            });
            actions.resetForm();
            this.$router.push("/multimedia");
          }
        })
        .catch(error => {
          console.error("Error adding model:", error);
          this.$swal(
            "Error!",
            "There was an issue adding the model.",
            "error"
          );
        });
    },
    handleCancel() {
      this.$router.push("/multimedia");
    },
    onFileChange(event, setErrors) {
      const file = event.target.files[0];
      if (!file) {
        // If no file was selected, simply return without setting any errors
        return;
      }
      this.fileMain = file;
      if (file.type.startsWith("image/") || file.type.startsWith("video/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.mediaData = e.target.result;
        };
        reader.readAsDataURL(file);
        this.mediaType = file.type.startsWith("image/") ? "image" : "video";
        setErrors({ image: null }); // Clear the image error
      } else {
        alert("Please select a valid image/video file.");
      }
    },
  },
};
</script>
