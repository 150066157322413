import API from "../../api/api";
import { authHeader, authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  modelColors: [],
  modelColor: null,
  loading: false,
});

const mutations = {
  getModelColors(state, payload) {
    state.modelColors = payload;
  },
  getModelColorDetail(state, payload) {
    state.modelColor = payload;
  },
  addModelColor(state, payload) {
    state.modelColors.push(payload);
  },
  editModelColor(state, payload) {
    const index = state.modelColors.findIndex(m => m.id === payload.id);
    if (index) {
      state.modelColor = payload;
    }
  },
  deleteModelColor(state, payload) {
    const filterArry = state.modelColors.filter(
      modelColor => modelColor.id != payload
    );
    state.modelColors = filterArry;
  },

  setDetailInput: (state, e) => {
    let modelColor = state.modelColor;
    modelColor[e.target.name] = e.target.value;
    state.modelColor = modelColor;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchModelColors({ commit }) {
    await API.get("model-with-colors")
      .then(res => {
        commit("getModelColors", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async fetchModelColorsDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`model-with-colors/${payload}`)
      .then(res => {
        commit("getModelColorDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    let modelColorData;
    await API.post("model-with-colors", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addModelColor", res.data.data);
        modelColorData = res.status
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return modelColorData
  },
  async put({ commit }, payload) {
    let modelColorData;
    const data = payload.data;
    await API.put(`model-with-colors/${payload.id}`, data, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editModelColor", payload);
          modelColorData = res.status
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return modelColorData
  },
  async delete({ commit }, payload) {
    await API.delete(`model-with-colors/${payload}`, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("deleteModelColor", payload);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },

  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  modelColor: state => state.modelColor,
  modelColors: state => state.modelColors,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
