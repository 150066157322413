<template>
  <div class="card my-4 mx-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-content-center"
    >
      <h6>Contact Us Inquiries</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <data-table
        :data-list="$store.state.contactus.contacts"
        :headerList="headerList"
      >
        <!-- <template v-slot:action="slotProps">
          <router-link
            :to="{ path: `/history/edit/${slotProps.row.id}` }"
            class="btn btn-outline-dark px-2 py-1"
          >
            <i className="fa fa-pen"></i>
          </router-link>
        </template> -->
      </data-table>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable.vue";
// import { RouterLink } from "vue-router";
const headerList = [
  { name: "Sr.no", id: "id" },
  { name: "First Name", id: "firstName" },
  { name: "Last Name", id: "lastName" },
  { name: "Email", id: "email" },
  { name: "Media Name", id: "mediaName" },
  { name: "Media Introduction", id: "mediaIntroduction" },
];

export default {
  name: "Contact Us Inquiries",
  components: {
    DataTable,
    // RouterLink,
  },
  data() {
    return {
      headerList: headerList,
    };
  },
  created() {
    this.$store.dispatch("contactus/fetchContacts", null, {
      root: true,
    });
  },
  mounted() {},
};
</script>
