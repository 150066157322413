import API from "../../api/api";
import { authHeader, authHeaderWithImage } from "../../helpers/authHeader";

const state = () => ({
  services: [],
  service: null,
  loading: false,
});

const mutations = {
  getFeatureServices(state, payload) {
    state.services = payload;
  },
  getFeatureServiceDetail(state, payload) {
    state.service = payload;
  },
  addFeatureService(state, payload) {
    state.services.push(payload);
  },
  editFeatureService(state, payload) {
    const index = state.services.findIndex(m => m.id === payload.id);
    if (index) {
      state.service = payload;
    }
  },
  deleteFeatureService(state, payload) {
    const filterFeatureServices = state.services.filter(
      service => service.id != payload
    );
    state.services = filterFeatureServices;
  },
  setDetailInput: (state, e) => {
    let service = state.service;
    service[e.target.name] = e.target.value;
    state.service = service;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchFeatureServices({ commit }) {
    await API.get("feature-service")
      .then(res => {
        commit("getFeatureServices", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async fetchFeatureServiceDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`feature-service/${payload}`)
      .then(res => {
        commit("getFeatureServiceDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("feature-service", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addFeatureService", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    const data = payload.data;
    let featureServiceData;
    await API.put(`feature-service/${payload.id}`, data, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editFeatureService", payload);
          featureServiceData = res.status; 
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
      return featureServiceData;
  },

  async delete({ commit }, payload) {
    await API.delete(`feature-service/${payload}`, { headers: authHeader() })
      .then(res => {
        if (res) {
          commit("deleteFeatureService", payload);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  service: state => state.service,
  services: state => state.services,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
