<template>
  <div class="d-flex justify-content-center align-items-center">
    404 | Page Not Found
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations("common", ["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>
