import API from "../../api/api";

const state = () => ({
    testdrive: [],
    loading: false,
});

const mutations = {
    getfetchtestDrives(state, payload) {
        state.testdrive = payload;
    },
    isLoading(state, payload) {
        state.loading = payload;
    },
};

const actions = {
    async fetchtestDrive({ commit }) {
        await API.get("test-drive")
            .then(res => {
                commit("getfetchtestDrives", res.data.data);
            })
            .catch(err => {
                console.log("ERROR", err);
            });
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
