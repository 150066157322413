<template>
  <div class="card mb-4 mx-4">
    <div class="card-header pb-0">
      <h6>HOME PAGE</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
          enctype="multipart/form-data"
        >
          <div class="form-group">
            <label for="exampleInputEmail1">Email address</label>
            <Field
              name="email"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.email }"
            />
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>
          <div class="form-group">
            <button @click.prevent="() => this.$refs.fileInput.click()">
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
              @change="onFileChange"
              accept="image/*"
              style="display: none"
            />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length">{{ errors[0] }}</div>
          </Field>
          <div v-if="imageData">
            <h3>Preview:</h3>
            <img
              :src="imageData"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>

          <button class="btn btn-light" @click="add">Add Fields</button>
          <div
            class="form-group"
            v-for="(a, counter) in addMore"
            v-bind:key="counter"
          >
            <label for="duration">{{ counter + 1 }}. Add More:</label>
            <Field
              :name="`image ${counter + 1}`"
              class="form-control"
              type="text"
              v-model="a.image"
              required
            />
            <label for="duration">Year of expiration:</label>
            <Field
              :name="`description ${counter + 1}`"
              class="form-control"
              type="text"
              v-model="a.description"
              required
            />
            <span @click="deleteOne(counter)">x</span>
          </div>
          <div>
            <text-editor />
          </div>

          <button type="submit" class="btn btn-danger">Submit</button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import TextEditor from "../components/TextEditor.vue";
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
// import _ from "lodash";

export default {
  name: "HOME PAGE CMS",
  components: {
    Form,
    Field,
    TextEditor,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      fileMain: null,
      imageData: null,
      schema: yup.object().shape({
        email: yup
          .string()
          .required("Email is required")
          .email("Email is invalid"),
      }),
      addMore: [
        {
          image: "",
          description: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    add() {
      this.addMore.push({
        image: "",
        description: "",
      });
    },
    deleteOne(counter) {
      this.addMore.splice(counter, 1);
    },
    onFormSubmit(values) {
      // const obj = {
      //   ...values,
      //   image: this.fileMain,
      // };

      // const emptyFormData = new FormData();
      // const formData = _.reduce(
      //   obj,
      //   (result, value, key) => {
      //     if (key !== "image") {
      //       result.append(key, value);
      //     } else result.append(key, JSON.stringify(value));
      //     return result;
      //   },
      //   emptyFormData
      // );

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + " - " + pair[1]);
      // }
      console.log("FORM DATA");
      console.log("FORM DATA", values);
    },
    onFileChange(event) {
      const file = event.target.files[0];

      this.fileMain = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
