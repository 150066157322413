<template>
  <div class="card my-4 mx-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-content-center"
    >
      <h6>Join Us Inquiries</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <data-table
        :data-list="$store.state.joinUs.joinus"
        :headerList="headerList"
      >
        <template v-slot:pdf="slotProps">
          <a
            :href="imageApi + slotProps.row.pdf"
            target="_blank"
            class="btn btn-outline-dark px-2 py-1"
          >
            {{ slotProps.row.pdf }}
          </a>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import imageApi from "../../api/imageapi";
import DataTable from "../../components/DataTable.vue";

// import { RouterLink } from "vue-router";
const headerList = [
  { name: "Sr.no", id: "id" },
  { name: "Name", id: "firstName" },
  { name: "Email", id: "email" },
  { name: "Country Code", id: "countryCode" },
  { name: "Phone Number", id: "phoneNumber" },
  { name: "Job Title", id: "jobTitle" },
  { name: "Gender", id: "gender" },
  { name: "CV", id: "pdf" },
];

export default {
  name: "Join Us Inquiries",
  components: {
    DataTable,
    // RouterLink,
  },
  data() {
    return {
      headerList: headerList,
      imageApi,
    };
  },
  created() {
    this.$store.dispatch("joinUs/fetchJoinUs", null, {
      root: true,
    });
  },
  mounted() {},
};
</script>
