import API from "../../api/api";
import { authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  conceptCarObj: null,
  loading: false,
});

const mutations = {
  getConceptCarDetail(state, payload) {
    state.conceptCarObj = payload;
  },
  addConceptCar(state, payload) {
    state.conceptCarObj = payload;
  },
  editConceptCar(state, payload) {
    // const index = state.models.findIndex((m) => m.id === payload.id);
    // if (index) {
    state.conceptCarObj = payload;
    // }
  },
  setDetailInput: (state, e) => {
    let conceptCarObj = state.conceptCarObj;
    conceptCarObj[e.target.name] = e.target.value;
    state.conceptCarObj = conceptCarObj;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchConceptCarDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`concept-car/${payload}`)
      .then(res => {
        commit("getConceptCarDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("concept-car", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addConceptCar", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    await API.put(`concept-car/1`, payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editConceptCar", res.data.data);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  conceptCarObj: state => state.conceptCarObj,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
