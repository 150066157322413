import API from "../../api/api";
import { authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  capitalBeautyObj: null,
  loading: false,
});

const mutations = {
  getCapitalBeautyDetail(state, payload) {
    state.capitalBeautyObj = payload;
  },
  addCapitalBeauty(state, payload) {
    state.capitalBeautyObj = payload;
  },
  editCapitalBeauty(state, payload) {
    state.capitalBeautyObj = payload;
  },
  setDetailInput: (state, e) => {
    let capitalBeautyObj = state.capitalBeautyObj;
    capitalBeautyObj[e.target.name] = e.target.value;
    state.capitalBeautyObj = capitalBeautyObj;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchCapitalBeautyDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`capital-beauty/${payload}`)
      .then(res => {
        commit("getCapitalBeautyDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("capital-beauty", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addCapitalBeauty", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    await API.put(`capital-beauty/1`, payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editCapitalBeauty", res.data.data);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  capitalBeautyObj: state => state.capitalBeautyObj,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
