<template>
  <div>
    <sidenav
      :custom_class="this.$store.state.common.mcolor"
      :class="[
        this.$store.state.common.isTransparent,
        this.$store.state.common.isRTL ? 'fixed-end' : 'fixed-start',
      ]"
      v-if="this.$store.state.common.showSidenav"
    />
    <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
      :style="this.$store.state.common.isRTL ? 'overflow-x: hidden' : ''"
    >
      <!-- nav -->
      <navbar
        :class="[navClasses]"
        :textWhite="
          this.$store.state.common.isAbsolute ? 'text-white opacity-8' : ''
        "
        :minNav="navbarMinimize"
        v-if="this.$store.state.common.showNavbar"
      />
      <router-view />
      <app-footer v-show="this.$store.state.common.showFooter" />
      <!-- <configurator
      :toggle="toggleConfigurator"
      :class="[
        this.$store.state.common.showConfig ? 'show' : '',
        this.$store.state.common.hideConfigButton ? 'd-none' : '',
      ]"
    /> -->
    </main>
  </div>
</template>
<script>
import Sidenav from "./examples/Sidenav";
// import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    Sidenav,
    // Configurator,
    Navbar,
    AppFooter,
  },
  methods: {
    ...mapMutations("common", ["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.common.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state.common
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.common.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.common.isTransparent = "bg-transparent";
  },
};
</script>
