import API from "../../api/api";
import { authHeaderWithImage } from "../../helpers/authHeader";
const state = () => ({
  homeObj: null,
  loading: false,
});

const mutations = {
  getHomeDetail(state, payload) {
    state.homeObj = payload;
  },
  addHome(state, payload) {
    state.homeObj = payload;
  },
  editHome(state, payload) {
    // const index = state.models.findIndex((m) => m.id === payload.id);
    // if (index) {
    state.homeObj = payload;
    // }
  },
  setDetailInput: (state, e) => {
    let homeObj = state.homeObj;
    homeObj[e.target.name] = e.target.value;
    state.homeObj = homeObj;
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async fetchHomeDetail({ commit }, payload) {
    commit("isLoading", true);
    await API.get(`homepage/${payload}`)
      .then(res => {
        commit("getHomeDetail", res.data.data);
        commit("isLoading", false);
      })
      .catch(err => {
        console.log("ERROR", err);
        commit("isLoading", false);
      });
  },
  async post({ commit }, payload) {
    await API.post("homepage", payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        commit("addHome", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async put({ commit }, payload) {
    await API.put(`homepage/1`, payload, {
      headers: authHeaderWithImage(),
    })
      .then(res => {
        if (res) {
          commit("editHome", res.data.data);
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  homeObj: state => state.homeObj,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
