<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>History Edit</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
          enctype="multipart/form-data"
          v-if="history"
        >
          <div class="form-group">
            <label for="exampleInputEmail1">History Year</label>
            <Field
              name="year"
              type="text"
              class="form-control"
              v-model="history.year"
              :class="{ 'is-invalid': errors.year }"
            />
            <div class="invalid-feedback">{{ errors.year }}</div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">History Description</label>
              <Field
                name="description"
                type="text"
                class="form-control"
                v-model="history.description"
                :class="{ 'is-invalid': errors.description }"
              />
              <div class="invalid-feedback">{{ errors.description }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic History Description</label>
              <Field
                name="description_ar"
                type="text"
                class="form-control"
                v-model="history.description_ar"
                :class="{ 'is-invalid': errors.description_ar }"
              />
              <div class="invalid-feedback">{{ errors.description_ar }}</div>
            </div>
          </div>
          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.fileInput.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
              @change="onFileChange"
              accept="image/*"
              style="display: none"
            />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length">{{ errors[0] }}</div>
          </Field>
          <div v-if="history && !imageData">
            <h3>Preview:</h3>
            <img
              :src="imageApi + history.image"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>
          <div v-if="imageData">
            <h3>Preview:</h3>
            <img
              :src="imageData"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
export default {
  name: "History Edit ",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      imageApi: imageApi,
      fileMain: null,
      imageData: null,
      schema: yup.object().shape({
        year: yup.string().trim().required("Year is required"),
        description: yup.string().trim().required("Description is required"),
        description_ar: yup
          .string()
          .trim()
          .required("Arabic Description is required"),
      }),
    };
  },
  computed: {
    ...mapGetters("history", ["history", "isLoading"]),
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.id) {
      this.fetchDetail();
    }
  },
  mounted() {},
  methods: {
    updateInput() {
      this.$store.dispatch("history/updateInput");
    },
    onFileChange(event) {
      const file = event.target.files[0];

      this.fileMain = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image: this.fileMain,
      };
      const id = this.id;

      let formData = new FormData();
      formData.append("image", this.$refs.fileInput.files[0]);
      formData.append("year", obj.year);
      formData.append("description", obj.description);
      formData.append("description_ar", obj.description_ar);
      this.$store
        .dispatch("history/put", { id, data: formData })
        .then(res => {
          if (res == 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "History has been Updated",
              showConfirmButton: false,
              timer: 2000,
            });
            actions.resetForm();
            this.$router.push("/history");
          }
        })
        .catch(error => {
          // Handle any errors
          console.error("Error updating model:", error);
          this.$swal(
            "Error!",
            "There was an issue updating the model.",
            "error"
          );
        });
    },
    handleCancel() {
      this.$router.push("/history");
    },
    fetchDetail() {
      try {
        this.$store.dispatch("history/fetchHistoryDetail", this.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
