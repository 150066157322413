d
<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Model Edit</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
          enctype="multipart/form-data"
          v-if="model"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Model Name</label>
              <Field
                name="modelName"
                type="text"
                class="form-control"
                v-model="model.name"
                :class="{ 'is-invalid': errors.modelName }"
              />
              <div class="invalid-feedback">{{ errors.modelName }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Model Name</label>
              <Field
                name="modelName_ar"
                type="text"
                class="form-control"
                v-model="model.name_ar"
                :class="{ 'is-invalid': errors.modelName_ar }"
              />
              <div class="invalid-feedback">{{ errors.modelName_ar }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Model Description</label>
              <Field
                name="description"
                type="text"
                class="form-control"
                v-model="model.description"
                :class="{ 'is-invalid': errors.description }"
              />
              <div class="invalid-feedback">{{ errors.description }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Model Description</label>
              <Field
                name="description_ar"
                type="text"
                class="form-control"
                v-model="model.description_ar"
                :class="{ 'is-invalid': errors.description_ar }"
              />
              <div class="invalid-feedback">{{ errors.description_ar }}</div>
            </div>
          </div>
          <div class="form-group" v-if="model">
            <label for="exampleInputEmail1">Model Category</label>
            <Field
              name="modelCategory"
              as="select"
              class="form-control"
              v-model="model.modelCategory.id"
              :class="{ 'is-invalid': errors.modelCategory }"
            >
              <option value="" disabled>Select value</option>
              <option
                v-for="(category, index) in modelsCategories"
                :key="index"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </Field>

            <div class="invalid-feedback">{{ errors.modelCategory }}</div>
          </div>

          <div class="form-group row">
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Power</label>
              <Field
                name="power"
                type="text"
                class="form-control"
                v-model="model.power"
                :class="{ 'is-invalid': errors.power }"
              />
              <div class="invalid-feedback">{{ errors.power }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Torque</label>
              <Field
                name="torque"
                type="text"
                class="form-control"
                v-model="model.torque"
                :class="{ 'is-invalid': errors.torque }"
              />
              <div class="invalid-feedback">{{ errors.torque }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Wheelbase</label>
              <Field
                name="wheelbase"
                type="text"
                class="form-control"
                v-model="model.wheelbase"
                :class="{ 'is-invalid': errors.wheelbase }"
              />
              <div class="invalid-feedback">{{ errors.wheelbase }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model VR Link</label>
              <Field
                name="vr_link"
                type="text"
                class="form-control"
                v-model="model.vr_link"
                :class="{ 'is-invalid': errors.vr_link }"
              />
              <div class="invalid-feedback">{{ errors.vr_link }}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-6">
              <button
                @click.prevent="() => this.$refs.image1.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image1"
                @change="onFileChange"
                accept="image/*"
                style="display: none"
              />
              <Field name="image" v-slot="{ errors }">
                <div v-if="errors.length">{{ errors[0] }}</div>
              </Field>
              <div v-if="model && !image1Data">
                <h3>Preview:</h3>
                <img
                  :src="imageApi + model.image1"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="image1Data">
                <h3>Preview:</h3>
                <img
                  :src="image1Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
            <div class="col-md-6">
              <button
                @click.prevent="() => this.$refs.image1_ar.click()"
                class="btn btn-outline-danger"
              >
                Image Upload Arabic
              </button>
              <input
                type="file"
                ref="image1_ar"
                @change="event => onFileChange(event, 'image1_ar')"
                accept="image/*"
                style="display: none"
              />
              <Field name="image" v-slot="{ errors }">
                <div v-if="errors.length">{{ errors[0] }}</div>
              </Field>
              <div v-if="model && !image1Data_ar">
                <h3>Preview Arabic:</h3>
                <img
                  :src="imageApi + model.image1_ar"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="image1Data_ar">
                <h3>Preview Arabic:</h3>
                <img
                  :src="image1Data_ar"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Section 2</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Section 2 Title </label>
                <Field
                  name="image2_title"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image2_title }"
                  v-model="model.image2_title"
                />
                <div class="invalid-feedback">{{ errors.image2_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Section 2 Title</label>
                <Field
                  name="image2_title_ar"
                  type="text"
                  class="form-control"
                  v-model="model.image2_title_ar"
                  :class="{ 'is-invalid': errors.image2_title_ar }"
                />
                <div class="invalid-feedback">{{ errors.image2_title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="exampleInputEmail1">Section 2 Description</label>
                <Field
                  name="image2_description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="model.image2_description"
                  :class="{ 'is-invalid': errors.image2_description }"
                />
                <div class="invalid-feedback">
                  {{ errors.image2_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="exampleInputEmail1"
                  >Arabic Section 2 Description</label
                >
                <Field
                  name="image2_description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="model.image2_description_ar"
                  :class="{ 'is-invalid': errors.image2_description_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.image2_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  @click.prevent="() => this.$refs.image2.click()"
                  class="btn btn-outline-danger"
                >
                  Image Upload
                </button>
                <input
                  type="file"
                  ref="image2"
                  @change="event => onFileChange(event, 'image2')"
                  accept="image/*"
                  style="display: none"
                />

                <Field name="image" v-slot="{ errors }">
                  <div v-if="errors.length">{{ errors[0] }}</div>
                </Field>
                <div v-if="model && !image2Data">
                  <h3>Preview:</h3>
                  <img
                    :src="imageApi + model.image2"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="image2Data">
                  <h3>Preview:</h3>
                  <img
                    :src="image2Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  @click.prevent="() => this.$refs.image2_ar.click()"
                  class="btn btn-outline-danger"
                >
                  Image Upload Arabic
                </button>
                <input
                  type="file"
                  ref="image2_ar"
                  @change="event => onFileChange(event, 'image2_ar')"
                  accept="image/*"
                  style="display: none"
                />

                <Field name="image" v-slot="{ errors }">
                  <div v-if="errors.length">{{ errors[0] }}</div>
                </Field>
                <div v-if="model && !image2Data_ar">
                  <h3>Preview Arabic:</h3>
                  <img
                    :src="imageApi + model.image2_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="image2Data_ar">
                  <h3>Preview Arabic:</h3>
                  <img
                    :src="image2Data_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4>Section 3</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Section 3 Title </label>
                <Field
                  name="image3_title"
                  type="text"
                  class="form-control"
                  v-model="model.image3_title"
                  :class="{ 'is-invalid': errors.image3_title }"
                />
                <div class="invalid-feedback">{{ errors.image3_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Section 3 Title</label>
                <Field
                  name="image3_title_ar"
                  type="text"
                  class="form-control"
                  v-model="model.image3_title_ar"
                  :class="{ 'is-invalid': errors.image3_title_ar }"
                />
                <div class="invalid-feedback">{{ errors.image3_title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="exampleInputEmail1">Section 3 Description</label>
                <Field
                  name="image3_description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="model.image3_description"
                  :class="{ 'is-invalid': errors.image3_description }"
                />
                <div class="invalid-feedback">
                  {{ errors.image3_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="exampleInputEmail1"
                  >Arabic Section 3 Description</label
                >
                <Field
                  name="image3_description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  :class="{ 'is-invalid': errors.image3_description_ar }"
                  v-model="model.image3_description_ar"
                />
                <div class="invalid-feedback">
                  {{ errors.image3_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  @click.prevent="() => this.$refs.image3.click()"
                  class="btn btn-outline-danger"
                >
                  Image Upload
                </button>
                <input
                  type="file"
                  ref="image3"
                  @change="event => onFileChange(event, 'image3')"
                  accept="image/*"
                  style="display: none"
                />
                <Field name="image" v-slot="{ errors }">
                  <div v-if="errors.length">{{ errors[0] }}</div>
                </Field>
                <div v-if="model && !image3Data">
                  <h3>Preview:</h3>
                  <img
                    :src="imageApi + model.image3"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="image3Data">
                  <h3>Preview:</h3>
                  <img
                    :src="image3Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  @click.prevent="() => this.$refs.image3_ar.click()"
                  class="btn btn-outline-danger"
                >
                  Image Upload Arabic
                </button>
                <input
                  type="file"
                  ref="image3_ar"
                  @change="event => onFileChange(event, 'image3_ar')"
                  accept="image/*"
                  style="display: none"
                />
                <Field name="image" v-slot="{ errors }">
                  <div v-if="errors.length">{{ errors[0] }}</div>
                </Field>
                <div v-if="model && !image3Data_ar">
                  <h3>Preview Arabic:</h3>
                  <img
                    :src="imageApi + model.image3_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="image3Data_ar">
                  <h3>Preview Arabic:</h3>
                  <img
                    :src="image3Data_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4>Section 4</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Section 4 Title </label>
                <Field
                  name="image4_title"
                  type="text"
                  class="form-control"
                  v-model="model.image4_title"
                  :class="{ 'is-invalid': errors.image4_title }"
                />
                <div class="invalid-feedback">{{ errors.image4_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Section 4 Title</label>
                <Field
                  name="image4_title_ar"
                  type="text"
                  class="form-control"
                  v-model="model.image4_title_ar"
                  :class="{ 'is-invalid': errors.image4_title_ar }"
                />
                <div class="invalid-feedback">{{ errors.image4_title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="exampleInputEmail1">Section 4 Description</label>
                <Field
                  name="image4_description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="model.image4_description"
                  :class="{ 'is-invalid': errors.image4_description }"
                />
                <div class="invalid-feedback">
                  {{ errors.image4_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="exampleInputEmail1"
                  >Arabic Section 4 Description</label
                >
                <Field
                  name="image4_description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="model.image4_description_ar"
                  :class="{ 'is-invalid': errors.image4_description_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.image4_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  @click.prevent="() => this.$refs.image4.click()"
                  class="btn btn-outline-danger"
                >
                  Image Upload
                </button>
                <input
                  type="file"
                  ref="image4"
                  @change="event => onFileChange(event, 'image4')"
                  accept="image/*"
                  style="display: none"
                />
                <Field name="image" v-slot="{ errors }">
                  <div v-if="errors.length">{{ errors[0] }}</div>
                </Field>
                <div v-if="model && !image4Data">
                  <h3>Preview:</h3>
                  <img
                    :src="imageApi + model.image4"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="image4Data">
                  <h3>Preview:</h3>
                  <img
                    :src="image4Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  @click.prevent="() => this.$refs.image4_ar.click()"
                  class="btn btn-outline-danger"
                >
                  Image Upload Arabic
                </button>
                <input
                  type="file"
                  ref="image4_ar"
                  @change="event => onFileChange(event, 'image4_ar')"
                  accept="image/*"
                  style="display: none"
                />
                <Field name="image" v-slot="{ errors }">
                  <div v-if="errors.length">{{ errors[0] }}</div>
                </Field>
                <div v-if="model && !image4Data_ar">
                  <h3>Preview:</h3>
                  <img
                    :src="imageApi + model.image4_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="image4Data_ar">
                  <h3>Preview:</h3>
                  <img
                    :src="image4Data_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h4>Section 5</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Section 5 Title </label>
                <Field
                  name="image5_title"
                  type="text"
                  class="form-control"
                  v-model="model.image5_title"
                  :class="{ 'is-invalid': errors.image5_title }"
                />
                <div class="invalid-feedback">{{ errors.image5_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Section 5 Title</label>
                <Field
                  name="image5_title_ar"
                  type="text"
                  class="form-control"
                  v-model="model.image5_title_ar"
                  :class="{ 'is-invalid': errors.image5_title_ar }"
                />
                <div class="invalid-feedback">{{ errors.image5_title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="exampleInputEmail1">Section 5 Description</label>
                <Field
                  name="image5_description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="model.image5_description"
                  :class="{ 'is-invalid': errors.image5_description }"
                />
                <div class="invalid-feedback">
                  {{ errors.image5_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="exampleInputEmail1"
                  >Arabic Section 5 Description</label
                >
                <Field
                  name="image5_description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="model.image5_description_ar"
                  :class="{ 'is-invalid': errors.image5_description_ar }"
                />
                <div class="invalid-feedback">
                  {{ errors.image5_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <button
                  @click.prevent="() => this.$refs.image5.click()"
                  class="btn btn-outline-danger"
                >
                  Image Upload
                </button>
                <input
                  type="file"
                  ref="image5"
                  @change="event => onFileChange(event, 'image5')"
                  accept="image/*"
                  style="display: none"
                />
                <Field name="image" v-slot="{ errors }">
                  <div v-if="errors.length">{{ errors[0] }}</div>
                </Field>
                <div v-if="model && !image5Data">
                  <h3>Preview:</h3>
                  <img
                    :src="imageApi + model.image5"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="image5Data">
                  <h3>Preview:</h3>
                  <img
                    :src="image5Data"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <button
                  @click.prevent="() => this.$refs.image5_ar.click()"
                  class="btn btn-outline-danger"
                >
                  Image Upload Arabic
                </button>
                <input
                  type="file"
                  ref="image5_ar"
                  @change="event => onFileChange(event, 'image5_ar')"
                  accept="image/*"
                  style="display: none"
                />
                <Field name="image" v-slot="{ errors }">
                  <div v-if="errors.length">{{ errors[0] }}</div>
                </Field>
                <div v-if="model && !image5Data_ar">
                  <h3>Preview Arabic:</h3>
                  <img
                    :src="imageApi + model.image5_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
                <div v-if="image5Data_ar">
                  <h3>Preview Arabic:</h3>
                  <img
                    :src="image5Data_ar"
                    alt="Image Preview"
                    height="200"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group my-3" v-if="model">
            <h3>Add More Sections</h3>
            <!-- <p>{{ model?.moreSections }}</p> -->
            <!-- <FieldArray name="moreSections" v-slot="{ fields, push, remove }"> -->
            <div
              v-for="(entry, idx) in moreSections"
              :key="entry.key"
              class="card shadow my-2"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-5">
                    <label for="exampleInputEmail1"
                      >Section {{ idx + 1 }} Title</label
                    >

                    <Field
                      :name="`moreSections[${idx}].title`"
                      type="text"
                      class="form-control"
                      v-model="entry.title"
                    />
                  </div>
                  <div class="col-md-5">
                    <label for="exampleInputEmail1"
                      >Section {{ idx + 1 }} Title Arabic</label
                    >

                    <Field
                      :name="`moreSections[${idx}].title_ar`"
                      type="text"
                      class="form-control"
                      v-model="entry.title_ar"
                    />
                  </div>
                  <div class="col-md-5">
                    <label for="exampleInputEmail1"
                      >Section {{ idx + 1 }} Description</label
                    >

                    <Field
                      :name="`moreSections[${idx}].description`"
                      type="text"
                      class="form-control"
                      v-model="entry.description"
                    />
                  </div>
                  <div class="col-md-5">
                    <label for="exampleInputEmail1"
                      >Section {{ idx + 1 }} Description Arabic</label
                    >

                    <Field
                      :name="`moreSections[${idx}].description_ar`"
                      type="text"
                      class="form-control"
                      v-model="entry.description_ar"
                    />
                  </div>
                  <div class="col-md-5">
                    <label for="exampleInputEmail1"
                      >Section {{ idx + 1 }} Image</label
                    >

                    <Field
                      :name="`moreSections[${idx}].image`"
                      type="file"
                      class="form-control"
                      v-model="entry.image"
                      @change="onFileChangeSectionImage($event, idx)"
                    />
                  </div>
                  <div class="col-md-5">
                    <label for="exampleInputEmail1"
                      >Section {{ idx + 1 }} Image Arabic</label
                    >

                    <Field
                      :name="`moreSections[${idx}].image_ar`"
                      type="file"
                      class="form-control"
                      v-model="entry.image_ar"
                      @change="onFileChangeSectionImageAr($event, idx)"
                    />
                  </div>
                  <div class="col-md-3 my-3">
                    <button
                      type="button"
                      class="btn btn-warning"
                      @click="removeItem(idx)"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-info my-2" @click="addMore()">
              Add
            </button>
            <!-- </FieldArray> -->
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
import { useDropzone } from "vue3-dropzone";
export default {
  name: "Model Edit ",
  components: {
    Form,
    Field,
  },
  setup() {
    let files = [];
    const saveFiles = afiles => {
      // const formData = new FormData(); // pass data as a form
      for (var x = 0; x < afiles.length; x++) {
        // formData.append("images[]", files[x]);
        files.push(afiles[x]);
      }
    };

    function onDrop(acceptFiles) {
      saveFiles(acceptFiles); // saveFiles as callback
      console.log("file", acceptFiles);
    }

    const { getRootProps, getInputProps, isDragActive, ...rest } = useDropzone({
      onDrop,
    });
    const { setFieldValue } = useForm();
    return {
      setFieldValue,
      getRootProps,
      getInputProps,
      isDragActive,
      rest,
      files,
    };
  },
  data() {
    return {
      moreSections: [],
      moreSectionImages: [],
      imageApi: imageApi,
      image1: null,
      image1Data: null,
      image2: null,
      image2Data: null,
      image3: null,
      image3Data: null,
      image4: null,
      image4Data: null,
      image5: null,
      image5Data: null,
      image1_ar: null,
      image1Data_ar: null,
      image2_ar: null,
      image2Data_ar: null,
      image3_ar: null,
      image3Data_ar: null,
      image4_ar: null,
      image4Data_ar: null,
      image5_ar: null,
      image5Data_ar: null,
      schema: yup.object().shape({
        modelName: yup.string().trim().required("Model Name is required"),
        power: yup.string().trim().required("Model Power is required"),
        torque: yup.string().trim().required("Model Torque is required"),
        wheelbase: yup.string().trim().required("Model Wheelbase is required"),
        vr_link: yup.string().trim().required("Model VR Link is required"),
        description: yup.string().trim().required("Description is required"),
        modelName_ar: yup
          .string()
          .trim()
          .required("Arabic Model Name is required"),
        description_ar: yup
          .string()
          .trim()
          .required("Arabic Description is required"),
        modelCategory: yup
          .string()
          .required("Model Category is required")
          .min(1)
          .max(15),
      }),
    };
  },
  computed: {
    ...mapGetters("models", ["model", "isLoading"]),
    ...mapGetters("modelsCategory", ["modelsCategories"]),
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    if (this.id) {
      this.$store.dispatch("modelsCategory/fetchModelsCategories", null, {
        root: true,
      });
      this.fetchDetail();
    }
  },
  mounted() {
    if (this.model && this.model?.moreSections) {
      this.moreSections = this.model?.moreSections;

      this.model?.moreSections.forEach((i, m) => {
        console.log("mmmmm", m);
        this.moreSectionImages.push({ image: i.image, image_ar: i.image_ar });
      });
    }
  },
  methods: {
    addMore() {
      this.moreSections.push({
        title: "",
        description: "",
        title_ar: "",
        description_ar: "",
        image: "",
        image_ar: "",
      });
      this.onItemAdd();
    },
    removeItem(index) {
      this.moreSections = this.moreSections.filter((i, m) => {
        console.log(m);
        return m != index;
      });
      this.moreSectionImages.filter((i, m) => {
        console.log(m);
        return m != index;
      });
    },
    onItemAdd() {
      this.moreSectionImages.push({ image: null, image_ar: null });
    },
    updateInput() {
      this.$store.dispatch("models/updateInput");
    },
    onFileChangeSectionImageAr(event, index) {
      const file = event.target.files[0];

      console.log(this.moreSectionImages);

      this.moreSectionImages[index].image_ar = file;
    },
    onFileChangeSectionImage(event, index) {
      const file = event.target.files[0];

      this.moreSectionImages[index].image = file;
    },
    onFileChange(event, title) {
      const file = event.target.files[0];
      if (title == "image1") {
        this.image1 = event.target.files[0];
      } else if (title == "image2") {
        this.image2 = event.target.files[0];
      } else if (title == "image3") {
        this.image3 = event.target.files[0];
      } else if (title == "image4") {
        this.image4 = event.target.files[0];
      } else if (title == "image5") {
        this.image5 = event.target.files[0];
      } else if (title == "image1_ar") {
        this.image1_ar = event.target.files[0];
      } else if (title == "image2_ar") {
        this.image2_ar = event.target.files[0];
      } else if (title == "image3_ar") {
        this.image3_ar = event.target.files[0];
      } else if (title == "image4_ar") {
        this.image4_ar = event.target.files[0];
      } else if (title == "image5_ar") {
        this.image5_ar = event.target.files[0];
      }
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          if (title == "image1") {
            this.image1Data = e.target.result;
          } else if (title == "image2") {
            this.image2Data = e.target.result;
          } else if (title == "image3") {
            this.image3Data = e.target.result;
          } else if (title == "image4") {
            this.image4Data = e.target.result;
          } else if (title == "image5") {
            this.image5Data = e.target.result;
          } else if (title == "image1_ar") {
            this.image1Data_ar = e.target.result;
          } else if (title == "image2_ar") {
            this.image2Data_ar = e.target.result;
          } else if (title == "image3_ar") {
            this.image3Data_ar = e.target.result;
          } else if (title == "image4_ar") {
            this.image4Data_ar = e.target.result;
          } else if (title == "image5_ar") {
            this.image5Data_ar = e.target.result;
          }
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image1: this.fileMain,
      };
      const id = this.id;

      let formData = new FormData();

      formData.append("image1", this.$refs.image1.files[0]);
      formData.append("image2", this.$refs.image2.files[0]);
      formData.append("image3", this.$refs.image3.files[0]);
      formData.append("image4", this.$refs.image4.files[0]);
      formData.append("image5", this.$refs.image5.files[0]);

      formData.append("image1_ar", this.$refs.image1_ar.files[0]);
      formData.append("image2_ar", this.$refs.image2_ar.files[0]);
      formData.append("image3_ar", this.$refs.image3_ar.files[0]);
      formData.append("image4_ar", this.$refs.image4_ar.files[0]);
      formData.append("image5_ar", this.$refs.image5_ar.files[0]);

      if (values.moreSections && this.moreSectionImages) {
        for (let f = 0; f < values.moreSections.length; f++) {
          let value = values.moreSections[f];
          Object.keys(value).reduce((acc, key) => {
            if (key == "image") {
              const data = !this.moreSectionImages[f]
                ? values.moreSections[f].image
                : this.moreSectionImages[f].image;

              formData.append(`moreSections[${f}].${key}`, data);

              if (this.moreSectionImages[f].image) {
                formData.append(
                  `moreSections[${f}].${key}`,
                  this.moreSectionImages[f].image.name
                );
              }
            } else if (key == "image_ar") {
              const data = !this.moreSectionImages[f]
                ? values.moreSections[f].image_ar
                : this.moreSectionImages[f].image_ar;

              formData.append(`moreSections[${f}].${key}`, data);

              if (this.moreSectionImages[f].image) {
                formData.append(
                  `moreSections[${f}].${key}`,
                  this.moreSectionImages[f].image_ar.name
                );
              }
            } else {
              formData.append(`moreSections[${f}].${key}`, value[key]);
            }
          }, {});
        }
      }
      formData.append("name", obj.modelName);
      formData.append("power", obj.power);
      formData.append("torque", obj.torque);
      formData.append("wheelbase", obj.wheelbase);
      formData.append("vr_link", obj.vr_link);
      formData.append("description", obj.description);
      formData.append("name_ar", obj.modelName_ar);
      formData.append("description_ar", obj.description_ar);
      formData.append("categoryId", obj.modelCategory);
      formData.append("image2_description", obj.image2_description);
      formData.append("image2_description_ar", obj.image2_description_ar);
      formData.append("image2_title", obj.image2_title);
      formData.append("image2_title_ar", obj.image2_title_ar);
      formData.append("image3_description", obj.image3_description);
      formData.append("image3_description_ar", obj.image3_description_ar);
      formData.append("image3_title", obj.image3_title);
      formData.append("image3_title_ar", obj.image3_title_ar);
      formData.append("image4_description", obj.image4_description);
      formData.append("image4_description_ar", obj.image4_description_ar);
      formData.append("image4_title", obj.image4_title);
      formData.append("image4_title_ar", obj.image4_title_ar);
      formData.append("image5_description", obj.image5_description);
      formData.append("image5_description_ar", obj.image5_description_ar);
      formData.append("image5_title", obj.image5_title);
      formData.append("image5_title_ar", obj.image5_title_ar);
      this.$store
        .dispatch("models/put", { id, data: formData })
        .then(res => {
          if (res == 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Model has been Updated",
              showConfirmButton: false,
              timer: 2000,
            });
            actions.resetForm();
            this.$router.push("/models");
          }
        })
        .catch(error => {
          // Handle any errors
          console.error("Error updating model:", error);
          this.$swal(
            "Error!",
            "There was an issue updating the model.",
            "error"
          );
        });
    },
    handleCancel() {
      this.$router.push("/models");
    },
    fetchDetail() {
      try {
        this.$store.dispatch("models/fetchModelDetail", this.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
