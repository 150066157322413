<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit Off Road CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          class="form"
          @submit="onSubmit"
          :initial-values="initialData"
          :validation-schema="schema"
          enctype="multipart/form-data"
          v-slot="{ errors }"
          v-if="offRoad"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="">Off Road Banner Title</label>
              <Field
                name="bannerTitle"
                type="text"
                class="form-control"
                v-model="offRoad.bannerTitle"
                :class="{ 'is-invalid': errors.bannerTitle }"
              />

              <div class="invalid-feedback">{{ errors.bannerTitle }}</div>
            </div>
            <div class="col-md-6">
              <label for="">Off Road Arabic Banner Title</label>
              <Field
                name="bannerTitle_ar"
                type="text"
                class="form-control"
                v-model="offRoad.bannerTitle_ar"
                :class="{ 'is-invalid': errors.bannerTitle_ar }"
              />

              <div class="invalid-feedback">{{ errors.bannerTitle_ar }}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-6">
              <label for="title">Off Road Banner Description</label>
              <Field
                name="bannerDescription"
                type="text"
                as="textarea"
                class="form-control"
                v-model="offRoad.bannerDescription"
                :class="{ 'is-invalid': errors.bannerDescription }"
              />

              <div class="invalid-feedback">{{ errors.bannerDescription }}</div>
            </div>
            <div class="col-md-6">
              <label for="">Off Road Arabic Banner Description</label>
              <Field
                name="bannerDescription_ar"
                type="text"
                as="textarea"
                class="form-control"
                v-model="offRoad.bannerDescription_ar"
                :class="{ 'is-invalid': errors.bannerDescription_ar }"
              />

              <div class="invalid-feedback">
                {{ errors.bannerDescription_ar }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.bannerImageRef.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="bannerImageRef"
              @change="onFileChangeBannerImage"
              accept="image/*"
              style="display: none"
            />
            <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field>
            <div v-if="bannerImageData">
              <h5>Preview Off Road Banner Image:</h5>
              <img
                :src="bannerImageData"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
            <div v-if="offRoad && !bannerImageData">
              <h5>Preview Banner Image:</h5>
              <img
                :src="imageApi + offRoad.bannerImage"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
          </div>
          <div class="my-4">
            <h4>Off Road Section 1</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Off Road Section Title</label>
                <Field
                  name="image1Title"
                  type="text"
                  class="form-control"
                  v-model="offRoad.image1Title"
                  :class="{ 'is-invalid': errors.image1Title }"
                />

                <div class="invalid-feedback">{{ errors.image1Title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Off Road Arabic Section Title</label>
                <Field
                  name="image1Title_ar"
                  type="text"
                  class="form-control"
                  v-model="offRoad.image1Title_ar"
                  :class="{ 'is-invalid': errors.image1Title_ar }"
                />

                <div class="invalid-feedback">{{ errors.image1Title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Off Road Section Main Description</label>
                <Field
                  name="image1MainDescription"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="offRoad.image1MainDescription"
                  :class="{ 'is-invalid': errors.image1MainDescription }"
                />

                <div class="invalid-feedback">
                  {{ errors.image1MainDescription }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Off Road Arabic Section Main Description</label>
                <Field
                  name="image1MainDescription_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="offRoad.image1MainDescription_ar"
                  :class="{ 'is-invalid': errors.image1MainDescription_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.image1MainDescription_ar }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Off Road Section Description</label>
                <Field
                  name="image1Description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="offRoad.image1Description"
                  :class="{ 'is-invalid': errors.image1Description }"
                />

                <div class="invalid-feedback">
                  {{ errors.image1Description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Off Road Arabic Section Description</label>
                <Field
                  name="image1Description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="offRoad.image1Description_ar"
                  :class="{ 'is-invalid': errors.image1Description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.image1Description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image1Ref.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image1Ref"
                @change="onFileChangeImage1"
                accept="image/*"
                style="display: none"
              />
              <div v-if="image1Data">
                <h5>Preview Image:</h5>
                <img
                  :src="image1Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="offRoad && !image1Data">
                <h5>Preview Image:</h5>
                <img
                  :src="imageApi + offRoad.image1"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div class="my-4">
            <h4>Off Road Section 2</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Off Road Section Title</label>
                <Field
                  name="image2Title"
                  type="text"
                  class="form-control"
                  v-model="offRoad.image2Title"
                  :class="{ 'is-invalid': errors.image2Title }"
                />

                <div class="invalid-feedback">{{ errors.image2Title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Off Road Arabic Section Title</label>
                <Field
                  name="image2Title_ar"
                  type="text"
                  class="form-control"
                  v-model="offRoad.image2Title_ar"
                  :class="{ 'is-invalid': errors.image2Title_ar }"
                />

                <div class="invalid-feedback">{{ errors.image2Title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Off Road Section Description</label>
                <Field
                  name="image2Description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="offRoad.image2Description"
                  :class="{ 'is-invalid': errors.image2Description }"
                />

                <div class="invalid-feedback">
                  {{ errors.image2Description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Off Road Arabic Section Description</label>
                <Field
                  name="image2Description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="offRoad.image2Description_ar"
                  :class="{ 'is-invalid': errors.image2Description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.image2Description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image2Ref.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image2Ref"
                @change="onFileChangeImage2"
                accept="image/*"
                style="display: none"
              />
              <div v-if="image2Data">
                <h5>Preview Image:</h5>
                <img
                  :src="image2Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="offRoad && !image2Data">
                <h5>Preview Image:</h5>
                <img
                  :src="imageApi + offRoad.image2"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div class="my-4">
            <h4>Off Road Section 3</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Off Road Section 3 Title</label>
                <Field
                  name="image3Title"
                  type="text"
                  class="form-control"
                  v-model="offRoad.image3Title"
                  :class="{ 'is-invalid': errors.image3Title }"
                />

                <div class="invalid-feedback">{{ errors.image3Title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Off Road Arabic Section 3 Title</label>
                <Field
                  name="image3Title_ar"
                  type="text"
                  class="form-control"
                  v-model="offRoad.image3Title_ar"
                  :class="{ 'is-invalid': errors.image3Title_ar }"
                />

                <div class="invalid-feedback">{{ errors.image3Title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Off Road Section 3 Description</label>
                <Field
                  name="image3Description"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="offRoad.image3Description"
                  :class="{ 'is-invalid': errors.image3Description }"
                />

                <div class="invalid-feedback">
                  {{ errors.image3Description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Off Road Section 3 Description</label>
                <Field
                  name="image3Description_ar"
                  type="text"
                  as="textarea"
                  class="form-control"
                  v-model="offRoad.image3Description_ar"
                  :class="{ 'is-invalid': errors.image3Description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.image3Description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image3Ref.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image3Ref"
                @change="onFileChangeImage3"
                accept="image/*"
                style="display: none"
              />
              <div v-if="image3Data">
                <h5>Preview Image:</h5>
                <img
                  :src="image3Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="offRoad && !image3Data">
                <h5>Preview Image:</h5>
                <img
                  :src="imageApi + offRoad.image3"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-success">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
import _ from "lodash";
export default {
  name: "Off Road",
  components: {
    Field,
    Form,
  },
  data: () => {
    const { setFieldValue } = useForm();
    const schema = yup.object().shape({
      bannerTitle: yup.string().required("Off Road Banner Title is Required"),
      bannerDescription: yup
        .string()
        .trim()
        .required("Off Road Banner Description is Required"),
      image1Title: yup.string().required("Off Road Image Title is Required"),
      image1Description: yup
        .string()
        .trim()
        .required("Off Road Image Description is Required"),
      image1MainDescription: yup
        .string()
        .trim()
        .required("Off Road Description is Required"),
      image2Title: yup.string().required("Off Road Image Title is Required"),
      image2Description: yup
        .string()
        .trim()
        .required("Off Road Image Description is Required"),
      image3Title: yup.string().required("Off Road Image Title is Required"),
      image3Description: yup
        .string()
        .trim()
        .required("Off Road Image Description is Required"),

      bannerTitle_ar: yup
        .string()
        .trim()
        .required("Off Road Arabic Banner Title is Required"),
      bannerDescription_ar: yup
        .string()
        .trim()
        .required("Off Road Banner Arabic Description is Required"),
      image1Title_ar: yup
        .string()
        .trim()
        .required("Off Road Arabic Image Title is Required"),
      image1Description_ar: yup
        .string()
        .trim()
        .required("Off Road Arabic Image Description is Required"),
      image1MainDescription_ar: yup
        .string()
        .trim()
        .required("Off Road Arabic Description is Required"),
      image2Title_ar: yup
        .string()
        .trim()
        .required("Off Road Arabic Image Title is Required"),
      image2Description_ar: yup
        .string()
        .trim()
        .required("Off Road Arabic Image Description is Required"),
      image3Title_ar: yup
        .string()
        .trim()
        .required("Off Road Arabic Image Title is Required"),
      image3Description_ar: yup
        .string()
        .trim()
        .required("Off Road Arabic Image Description is Required"),
    });

    const initialData = {
      bannerTitle: "",
      bannerDescription: "",
      image1Title: "",
      image1Description: "",
      image1MainDescription: "",
      image2Title: "",
      image2Description: "",
      image3Title: null,
      image3Description: "",
      bannerTitle_ar: "",
      bannerDescription_ar: "",
      image1Title_ar: "",
      image1Description_ar: "",
      image1MainDescription_ar: "",
      image2Title_ar: "",
      image2Description_ar: "",
      image3Title_ar: null,
      image3Description_ar: "",
    };

    return {
      imageApi: imageApi,
      bannerImage: null,
      image1: null,
      image2: null,
      image3: null,
      bannerImageData: null,
      image1Data: null,
      image2Data: null,
      image3Data: null,
      schema,
      initialData,
      setFieldValue,
    };
  },
  computed: {
    ...mapGetters("offRoad", ["offRoad", "isLoading"]),
  },
  mounted() {
    this.fetchDetail();
    let initData = this.offRoad ? _.clone(this.offRoad) : {};

    this.initialData = this.offRoad
      ? {
          ...initData,
        }
      : this.initialData;
  },
  watch: {},
  methods: {
    fetchDetail() {
      try {
        this.$store.dispatch("offRoad/fetchOffRoadDetail", 1);
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      const obj = {
        ...values,
        bannerImage: this.bannerImage,
        image1: this.image1,
        image2: this.image2,
        image3: this.image3,
      };

      let formData = new FormData();

      formData.append("bannerTitle", obj.bannerTitle);
      formData.append("bannerDescription", obj.bannerDescription);
      formData.append("image1Title", obj.image1Title);
      formData.append("image1Description", obj.image1Description);
      formData.append("image1MainDescription", obj.image1MainDescription);
      formData.append("image2Title", obj.image2Title);
      formData.append("image2Description", obj.image2Description);
      formData.append("image3Title", obj.image3Title);
      formData.append("image3Description", obj.image3Description);

      formData.append("bannerTitle_ar", obj.bannerTitle_ar);
      formData.append("bannerDescription_ar", obj.bannerDescription_ar);
      formData.append("image1Title_ar", obj.image1Title_ar);
      formData.append("image1Description_ar", obj.image1Description_ar);
      formData.append("image1MainDescription_ar", obj.image1MainDescription_ar);
      formData.append("image2Title_ar", obj.image2Title_ar);
      formData.append("image2Description_ar", obj.image2Description_ar);
      formData.append("image3Title_ar", obj.image3Title_ar);
      formData.append("image3Description_ar", obj.image3Description_ar);

      formData.append("bannerImage", obj.bannerImage);
      formData.append("image1", obj.image1);
      formData.append("image2", obj.image2);
      formData.append("image3", obj.image3);

      this.$store.dispatch("offRoad/put", formData);
      this.$swal({
        position: "center",
        icon: "success",
        title: "Off-Road Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });

      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },

    onFileChangeBannerImage(event) {
      const file = event.target.files[0];

      this.bannerImage = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.bannerImageData = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    onFileChangeImage1(event) {
      const file = event.target.files[0];
      this.image1 = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.image1Data = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    onFileChangeImage2(event) {
      const file = event.target.files[0];

      this.image2 = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.image2Data = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
    onFileChangeImage3(event) {
      const file = event.target.files[0];

      this.image3 = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.image3Data = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
