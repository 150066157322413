<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Vision Add</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors,setErrors }"
          enctype="multipart/form-data"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Title</label>
              <Field
                name="title"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.title }"
              />
              <div class="invalid-feedback">{{ errors.title }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Title</label>
              <Field
                name="title_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.title_ar }"
              />
              <div class="invalid-feedback">{{ errors.title_ar }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Sub Title</label>
              <Field
                name="subTitle"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.subTitle }"
              />
              <div class="invalid-feedback">{{ errors.subTitle }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Sub Title</label>
              <Field
                name="subTitle_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.subTitle_ar }"
              />
              <div class="invalid-feedback">{{ errors.subTitle_ar }}</div>
            </div>
          </div>

          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.fileInput.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="fileInput"
               @change="onFileChange($event, setErrors)"
              accept="image/*"
              style="display: none"
            />
          </div>
          <Field name="image" v-slot="{ errors }">
            <div v-if="errors.length" class="img-invalid">{{ errors[0] }}</div>
          </Field>
          <div v-if="imageData">
            <h3>Preview:</h3>
            <img
              :src="imageData"
              alt="Image Preview"
              height="200"
              width="230"
            />
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";

export default {
  name: "News Releases Add",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      description: "",
      fileMain: null,
      imageData: null,
      schema: yup.object().shape({
        title: yup.string().trim().required("Title is required"),
        subTitle: yup.string().trim().required("Sub Title is required"),
        title_ar: yup.string().trim().required("Arabic Title is required"),
        subTitle_ar: yup
          .string()
          .trim()
          .required("Arabic Sub Title is required"),
      }),
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    onFormSubmit(values, actions) {
      const obj = {
        ...values,
        image: this.fileMain,
      };

      let formData = new FormData();
      if (!this.fileMain) {
        actions.setFieldError("image", "Image is required");
        return;
      }
      formData.append("image", this.fileMain);
      formData.append("title", obj.title);
      formData.append("subTitle", obj.subTitle);
      formData.append("title_ar", obj.title_ar);
      formData.append("subTitle_ar", obj.subTitle_ar);

      this.$store.dispatch("vision/post", formData);
      this.$swal({
        position: "center",
        icon: "success",
        title: "Vision has been Added",
        showConfirmButton: false,
        timer: 2000,
      });

      actions.resetForm();
      this.$router.push("/vision");
    },
    handleCancel() {
      this.$router.push("/vision");
    },
    onFileChange(event,setErrors) {
      const file = event.target.files[0];
      if (!file) {
        // If no file was selected, simply return without setting any errors
        return;
      }
      this.fileMain = file
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imageData = e.target.result;
        };

        reader.readAsDataURL(file);
        this.setFieldValue("image", file);
        setErrors({ image: null }); // Clear the image error
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
