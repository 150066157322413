<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit Capital Beauty CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          class="form"
          @submit="onSubmit"
          :initial-values="initialData"
          :validation-schema="schema"
          enctype="multipart/form-data"
          v-if="capitalBeautyObj"
          v-slot="{ errors }"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="">Capital Beauty Banner Title</label>
              <Field
                name="bannerTitle"
                type="text"
                class="form-control"
                v-model="capitalBeautyObj.bannerTitle"
                :class="{ 'is-invalid': errors.bannerTitle }"
              />
            </div>
            <div class="col-md-6">
              <label for="">Arabic Capital Beauty Banner Title</label>
              <Field
                name="bannerTitle_ar"
                type="text"
                class="form-control"
                v-model="capitalBeautyObj.bannerTitle_ar"
                :class="{ 'is-invalid': errors.bannerTitle_ar }"
              />
            </div>

            <div class="invalid-feedback">{{ errors.bannerTitle_ar }}</div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="">Capital Beauty Banner Sub Title</label>
              <Field
                name="bannerSubTitle"
                type="text"
                class="form-control"
                v-model="capitalBeautyObj.bannerSubTitle"
                :class="{ 'is-invalid': errors.bannerSubTitle }"
              />

              <div class="invalid-feedback">{{ errors.bannerSubTitle }}</div>
            </div>
            <div class="col-md-6">
              <label for="">Arabic Capital Beauty Banner Sub Title</label>
              <Field
                name="bannerSubTitle_ar"
                type="text"
                class="form-control"
                v-model="capitalBeautyObj.bannerSubTitle_ar"
                :class="{ 'is-invalid': errors.bannerSubTitle_ar }"
              />

              <div class="invalid-feedback">{{ errors.bannerSubTitle_ar }}</div>
            </div>
          </div>
          <div class="form-group">
            <button
              @click.prevent="() => this.$refs.bannerImageRef.click()"
              class="btn btn-outline-danger"
            >
              Image Upload
            </button>
            <input
              type="file"
              ref="bannerImageRef"
              @change="event => onFileChangeBannerImage(event, 'bannerImage')"
              accept="image/*"
              style="display: none"
            />
            <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field>
            <div v-if="bannerImageData">
              <h6>Preview Off Road Banner Image:</h6>
              <img
                :src="bannerImageData"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
            <div v-if="capitalBeautyObj && !bannerImageData">
              <h6>Preview Banner Image:</h6>
              <img
                :src="imageApi + capitalBeautyObj.bannerImage"
                alt="Image Preview"
                height="200"
                width="230"
              />
            </div>
          </div>

          <div>
            <h4>Capital Beauty Section 1</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Capital Beauty Section Title</label>
                <Field
                  name="image1Description"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image1Description"
                  :class="{ 'is-invalid': errors.image1Description }"
                />

                <div class="invalid-feedback">
                  {{ errors.image1Description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Capital Beauty Section Title</label>
                <Field
                  name="image1Description_ar"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image1Description_ar"
                  :class="{ 'is-invalid': errors.image1Description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.image1Description_ar }}
                </div>
              </div>
            </div>

            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image1Ref.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image1Ref"
                @change="event => onFileChangeBannerImage(event, 'image1')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="image1Data">
                <h6>Preview Image:</h6>
                <img
                  :src="image1Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="capitalBeautyObj && !image1Data">
                <h6>Preview Image:</h6>
                <img
                  :src="imageApi + capitalBeautyObj.image1"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Capital Beauty Section 2</h4>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <button
                    @click.prevent="() => this.$refs.gdImage1Ref.click()"
                    class="btn btn-outline-danger"
                  >
                    Image Upload
                  </button>
                  <input
                    type="file"
                    ref="gdImage1Ref"
                    @change="
                      event => onFileChangeBannerImage(event, 'gdImage1')"
                    accept="image/*"
                    style="display: none"
                  />
                  <div v-if="gdImage1Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="gdImage1Data"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                  <div v-if="capitalBeautyObj && !gdImage1Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="imageApi + capitalBeautyObj.gdImage1"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <button
                    @click.prevent="() => this.$refs.gdImage2Ref.click()"
                    class="btn btn-outline-danger"
                  >
                    Image Upload
                  </button>
                  <input
                    type="file"
                    ref="gdImage2Ref"
                    @change="
                      event => onFileChangeBannerImage(event, 'gdImage2')"
                    accept="image/*"
                    style="display: none"
                  />
                  <div v-if="gdImage2Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="gdImage2Data"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                  <div v-if="capitalBeautyObj && !gdImage2Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="imageApi + capitalBeautyObj.gdImage2"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <button
                    @click.prevent="() => this.$refs.gdImage3Ref.click()"
                    class="btn btn-outline-danger"
                  >
                    Image Upload
                  </button>
                  <input
                    type="file"
                    ref="gdImage3Ref"
                    @change="
                      event => onFileChangeBannerImage(event, 'gdImage3')"
                    accept="image/*"
                    style="display: none"
                  />
                  <div v-if="gdImage3Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="gdImage3Data"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                  <div v-if="capitalBeautyObj && !gdImage3Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="imageApi + capitalBeautyObj.gdImage3"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <button
                    @click.prevent="() => this.$refs.gdImage4Ref.click()"
                    class="btn btn-outline-danger"
                  >
                    Image Upload
                  </button>
                  <input
                    type="file"
                    ref="gdImage4Ref"
                    @change="event => onFileChangeBannerImage(event, 'gdImage4')"
                    accept="image/*"
                    style="display: none"
                  />
                  <div v-if="gdImage4Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="gdImage4Data"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                  <div v-if="capitalBeautyObj && !gdImage4Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="imageApi + capitalBeautyObj.gdImage4"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <button
                    @click.prevent="() => this.$refs.gdImage5Ref.click()"
                    class="btn btn-outline-danger"
                  >
                    Image Upload
                  </button>
                  <input
                    type="file"
                    ref="gdImage5Ref"
                    @change="event => onFileChangeBannerImage(event, 'gdImage5')"
                    accept="image/*"
                    style="display: none"
                  />
                  <div v-if="gdImage5Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="gdImage5Data"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                  <div v-if="capitalBeautyObj && !gdImage5Data">
                    <h6>Preview Image:</h6>
                    <img
                      :src="imageApi + capitalBeautyObj.gdImage5"
                      alt="Image Preview"
                      height="150"
                      width="120"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4>Capital Beauty Section 3</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Capital Beauty Section 3 Title</label>
                <Field
                  name="image2Title"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image2Title"
                  :class="{ 'is-invalid': errors.image2Title }"
                />

                <div class="invalid-feedback">{{ errors.image2Title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Capital Beauty Section 3 Title</label>
                <Field
                  name="image2Title_ar"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image2Title_ar"
                  :class="{ 'is-invalid': errors.image2Title_ar }"
                />

                <div class="invalid-feedback">{{ errors.image2Title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Capital Beauty Section 3 Description</label>
                <Field
                  as="textarea"
                  name="image2Description"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image2Description"
                  :class="{ 'is-invalid': errors.image2Description }"
                />

                <div class="invalid-feedback">
                  {{ errors.image2Description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Arabic Capital Beauty Section 3 Description</label
                >
                <Field
                  as="textarea"
                  name="image2Description_ar"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image2Description_ar"
                  :class="{ 'is-invalid': errors.image2Description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.image2Description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image2Ref.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image2Ref"
                @change="event => onFileChangeBannerImage(event, 'image2')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="image2Data">
                <h6>Preview Image:</h6>
                <img
                  :src="image2Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="capitalBeautyObj && !image2Data">
                <h6>Preview Image:</h6>
                <img
                  :src="imageApi + capitalBeautyObj.image2"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Capital Beauty Section 4</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Capital Beauty Section 4 Title</label>
                <Field
                  name="image3Title"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image3Title"
                  :class="{ 'is-invalid': errors.image3Title }"
                />

                <div class="invalid-feedback">{{ errors.image3Title }}</div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Capital Beauty Section 4 Title</label>
                <Field
                  name="image3Title_ar"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image3Title_ar"
                  :class="{ 'is-invalid': errors.image3Title_ar }"
                />

                <div class="invalid-feedback">{{ errors.image3Title_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="">Capital Beauty Section 4 Description</label>
                <Field
                  as="textarea"
                  name="image3Description"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image3Description"
                  :class="{ 'is-invalid': errors.image3Description }"
                />

                <div class="invalid-feedback">
                  {{ errors.image3Description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for=""
                  >Arabic Capital Beauty Section 4 Description</label
                >
                <Field
                  as="textarea"
                  name="image3Description_ar"
                  type="text"
                  class="form-control"
                  v-model="capitalBeautyObj.image3Description_ar"
                  :class="{ 'is-invalid': errors.image3Description_ar }"
                />

                <div class="invalid-feedback">
                  {{ errors.image3Description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image3Ref.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image3Ref"
                @change="event => onFileChangeBannerImage(event, 'image3')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="image2Data">
                <h6>Preview Image:</h6>
                <img
                  :src="image3Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="capitalBeautyObj && !image3Data">
                <h6>Preview Image:</h6>
                <img
                  :src="imageApi + capitalBeautyObj.image3"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <div>
            <h4>Capital Beauty Section 4</h4>
            <div class="form-group">
              <button
                @click.prevent="() => this.$refs.image4Ref.click()"
                class="btn btn-outline-danger"
              >
                Image Upload
              </button>
              <input
                type="file"
                ref="image4Ref"
                @change="event => onFileChangeBannerImage(event, 'image4')"
                accept="image/*"
                style="display: none"
              />
              <div v-if="image4Data">
                <h6>Preview Image:</h6>
                <img
                  :src="image4Data"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
              <div v-if="capitalBeautyObj && !image4Data">
                <h6>Preview Image:</h6>
                <img
                  :src="imageApi + capitalBeautyObj.image4"
                  alt="Image Preview"
                  height="200"
                  width="230"
                />
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-success">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
import _ from "lodash";

export default {
  name: "Capital Beauty",
  components: {
    Field,
    Form,
  },
  data: () => {
    const { setFieldValue } = useForm();
    const schema = yup.object().shape({
      bannerTitle: yup
        .string()
        .trim()
        .required("Capital Beauty Banner Title is Required"),
      bannerSubTitle: yup
        .string()
        .trim()
        .required("Capital Beauty Banner Sub Title is Required"),
      image1Description: yup
        .string()
        .trim()
        .required("Capital Beauty Image Description is Required"),
      image2Title: yup
        .string()
        .trim()
        .required("Capital Beauty Image Title is Required"),
      image2Description: yup
        .string()
        .trim()
        .required("Capital Beauty Image Description is Required"),
      image3Title: yup
        .string()
        .trim()
        .required("Capital Beauty Image Title is Required"),
      image3Description: yup
        .string()
        .trim()
        .required("Capital Beauty Image Description is Required"),
      bannerTitle_ar: yup
        .string()
        .trim()
        .required("Arabic Capital Beauty Banner Title is Required"),
      bannerSubTitle_ar: yup
        .string()
        .trim()
        .required("Arabic Capital Beauty Banner Sub Title is Required"),
      image1Description_ar: yup
        .string()
        .trim()
        .required("Arabic Capital Beauty Image Description is Required"),
      image2Title_ar: yup
        .string()
        .trim()
        .required("Arabic Capital Beauty Image Title is Required"),
      image2Description_ar: yup
        .string()
        .trim()
        .required("Arabic Capital Beauty Image Description is Required"),
      image3Title_ar: yup
        .string()
        .trim()
        .required("Arabic Capital Beauty Image Title is Required"),
      image3Description_ar: yup
        .string()
        .trim()
        .required("Arabic Capital Beauty Image Description is Required"),
    });

    const initialData = {
      bannerTitle: "",
      bannerSubTitle: "",
      image1Description: "",
      image2Title: "",
      image2Description: "",
      image3Title: null,
      image3Description: "",
      bannerTitle_ar: "",
      bannerSubTitle_ar: "",
      image1Description_ar: "",
      image2Title_ar: "",
      image2Description_ar: "",
      image3Title_ar: null,
      image3Description_ar: "",
    };

    return {
      imageApi: imageApi,
      bannerImage: null,
      image1: null,
      image2: null,
      image3: null,
      image4: null,
      gdImage1: null,
      gdImage2: null,
      gdImage3: null,
      gdImage4: null,
      gdImage5: null,
      bannerImageData: null,
      image1Data: null,
      image2Data: null,
      image3Data: null,
      image4Data: null,
      gdImage1Data: null,
      gdImage2Data: null,
      gdImage3Data: null,
      gdImage4Data: null,
      gdImage5Data: null,
      schema,
      initialData,
      setFieldValue,
    };
  },
  computed: {
    ...mapGetters("capitalBeauty", ["capitalBeautyObj", "isLoading"]),
  },
  mounted() {
    this.fetchDetail();
    let initData = this.capitalBeautyObj ? _.clone(this.capitalBeautyObj) : {};
    this.initialData = this.capitalBeautyObj
      ? {
          ...initData,
        }
      : this.initialData;
  },
  watch: {},
  methods: {
    fetchDetail() {
      try {
        this.$store.dispatch("capitalBeauty/fetchCapitalBeautyDetail", 1);
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      const obj = {
        ...values,
        bannerImage: this.bannerImage,
        image1: this.image1,
        image2: this.image2,
        image3: this.image3,
        image4: this.image4,
        gdImage1: this.gdImage1,
        gdImage2: this.gdImage2,
        gdImage3: this.gdImage3,
        gdImage4: this.gdImage4,
        gdImage5: this.gdImage5,
      };

      let formData = new FormData();

      formData.append("bannerTitle", obj.bannerTitle);
      formData.append("bannerSubTitle", obj.bannerSubTitle);
      formData.append("image1Description", obj.image1Description);
      formData.append("image2Title", obj.image2Title);
      formData.append("image2Description", obj.image2Description);
      formData.append("image3Title", obj.image3Title);
      formData.append("image3Description", obj.image3Description);

      formData.append("bannerTitle_ar", obj.bannerTitle_ar);
      formData.append("bannerSubTitle_ar", obj.bannerSubTitle_ar);
      formData.append("image1Description_ar", obj.image1Description_ar);
      formData.append("image2Title_ar", obj.image2Title_ar);
      formData.append("image2Description_ar", obj.image2Description_ar);
      formData.append("image3Title_ar", obj.image3Title_ar);
      formData.append("image3Description_ar", obj.image3Description_ar);

      formData.append("bannerImage", obj.bannerImage);
      formData.append("image1", obj.image1);
      formData.append("image2", obj.image2);
      formData.append("image3", obj.image3);
      formData.append("image4", obj.image4);
      formData.append("gdImage1", obj.gdImage1);
      formData.append("gdImage2", obj.gdImage2);
      formData.append("gdImage3", obj.gdImage3);
      formData.append("gdImage4", obj.gdImage4);
      formData.append("gdImage5", obj.gdImage5);

      this.$store.dispatch("capitalBeauty/put", formData);
      this.$swal({
        position: "center",
        icon: "success",
        title: "Capital Beauty Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });

      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },
    onFileChangeBannerImage(event, title) {
      const file = event.target.files[0];
      //   this.bannerImage = event.target.files[0];
      if (title == "bannerImage") {
        this.bannerImage = event.target.files[0];
      } else if (title == "image1") {
        this.image1 = event.target.files[0];
      } else if (title == "image2") {
        this.image2 = event.target.files[0];
      } else if (title == "image3") {
        this.image3 = event.target.files[0];
      } else if (title == "image4") {
        this.image4 = event.target.files[0];
      } else if (title == "gdImage1") {
        this.gdImage1 = event.target.files[0];
      } else if (title == "gdImage2") {
        this.gdImage2 = event.target.files[0];
      } else if (title == "gdImage3") {
        this.gdImage3 = event.target.files[0];
      } else if (title == "gdImage4") {
        this.gdImage4 = event.target.files[0];
      } else if (title == "gdImage5") {
        this.gdImage5 = event.target.files[0];
      }

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          //   this.bannerImageData = e.target.result;
          if (title == "bannerImage") {
            this.bannerImageData = e.target.result;
          } else if (title == "image1") {
            this.image1Data = e.target.result;
          } else if (title == "image2") {
            this.image2Data = e.target.result;
          } else if (title == "image3") {
            this.image3Data = e.target.result;
          } else if (title == "image4") {
            this.image4Data = e.target.result;
          } else if (title == "gdImage1") {
            this.gdImage1Data = e.target.result;
          } else if (title == "gdImage2") {
            this.gdImage2Data = e.target.result;
          } else if (title == "gdImage3") {
            this.gdImage3Data = e.target.result;
          } else if (title == "gdImage4") {
            this.gdImage4Data = e.target.result;
          } else if (title == "gdImage5") {
            this.gdImage5Data = e.target.result;
          }
        };

        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
